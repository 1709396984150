import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import axiosInterceptor from "../../utils/axiosInterceptor";
import sendVerificationCode from "../../utils/sendEmailVerification";
import Spinner from "react-bootstrap/Spinner";
import ResumeUpload from "./ResumeUpload";

interface SignUpFields {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
}

interface SignUpErrors {
    firstName?: boolean;
    lastName?: boolean;
    email?: boolean;
    password?: boolean;
    confirmPassword?: boolean;
    agreeTerms?: boolean;
}

const BLANK_SIGNUP: SignUpFields = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
};

function SignUp(): JSX.Element {
    const navigate = useNavigate();
    const [signup, setSignup] = useState<SignUpFields>(BLANK_SIGNUP);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errors, setErrors] = useState<SignUpErrors>({});
    const [agreeTerms, setAgreeTerms] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [resumeFile, setResumeFile] = useState<File | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        const isAuthenticated = !!localStorage.getItem("access_token");
        if (isAuthenticated) {
            navigate("/");
        }
    }, [navigate]);

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        setSignup({ ...signup, [e.target.name]: e.target.value });
    }

    const handleResumeUpload = (file: File | null) => {
        setResumeFile(file);
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setErrorMessage("");
        setIsSubmitting(true);

        const newErrors: SignUpErrors = {
            firstName: !signup.firstName,
            lastName: !signup.lastName,
            email: !signup.email,
            password: !signup.password,
            confirmPassword:
                !signup.confirmPassword ||
                signup.password !== signup.confirmPassword,
            agreeTerms: !agreeTerms,
        };

        setErrors(newErrors);

        if (Object.values(newErrors).some(Boolean)) {
            setErrorMessage("Please fill in all required fields correctly.");
            setIsSubmitting(false);
            return;
        }

        if (!resumeFile) {
            setErrorMessage("Please upload a resume.");
            setIsSubmitting(false);
            return;
        }

        try {
            const userRegistrationData = {
                firstname: signup.firstName,
                lastname: signup.lastName,
                email: signup.email,
                password: signup.password,
                password_verify: signup.confirmPassword,
            };

            await axiosInterceptor.post("/basic_register/", userRegistrationData);

            const loginData = {
                email: signup.email,
                password: signup.password,
            };
            const loginResponse = await axiosInterceptor.post("/login/", loginData);

            const {
                refresh,
                access,
                user_id,
                is_email_verified,
                is_verified_by_algolink,
            } = loginResponse.data;

            localStorage.setItem("access_token", access);
            localStorage.setItem("refresh_token", refresh);
            localStorage.setItem("user_id", user_id);
            localStorage.setItem("is_email_verified", is_email_verified);
            localStorage.setItem("is_algolink_admin", "false");
            localStorage.setItem("is_verified_by_algolink", is_verified_by_algolink);
            localStorage.setItem(
                "user_name",
                `${signup.firstName} ${signup.lastName}`
            );

            const formData = new FormData();
            formData.append("file", resumeFile);
            formData.append("root_directory", "resumes");

            await axiosInterceptor.post(`/file_upload/${user_id}/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            await sendVerificationCode(signup.email);
            navigate("/account/verify");
        } catch (error: any) {
            console.error("Error:", error);
            setCurrentStep(1);

            if (error.response && error.response.data) {
                const errorData = error.response.data;
                let localErrorMessage = "";

                if (Array.isArray(errorData) && errorData.length > 1 && typeof errorData[1] === "object") {
                    const errorObj = errorData[1];
                    for (const [field, messages] of Object.entries(errorObj)) {
                        if (Array.isArray(messages)) {
                            localErrorMessage += `${capitalizeFirstLetter(field)}: ${messages.join(", ")}\n`;
                        }
                    }
                } else if (typeof errorData === "string") {
                    localErrorMessage = errorData;
                } else {
                    localErrorMessage = "An unexpected error occurred during registration.";
                }
                setErrorMessage(localErrorMessage.trim());
            } else {
                setErrorMessage("An error occurred during registration.");
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const capitalizeFirstLetter = (str: string): string => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
        <div className="min-h-screen flex items-center justify-center">
            <Form onSubmit={handleSubmit} className="w-[370px] max-w-md mx-auto">
                {currentStep === 1 ? (
                    <div className="w-full flex flex-col gap-y-2">
                        <div className="p-4 flex flex-col gap-y-2">
                            <h2 className="text-3xl font-medium text-left mb-3.5 text-white">
                                Sign Up
                            </h2>
                            <FloatingLabel label="First Name">
                                <Form.Control
                                    name="firstName"
                                    type="text"
                                    placeholder=" "
                                    value={signup.firstName}
                                    onChange={handleChange}
                                    isInvalid={!!errors.firstName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    First name is required.
                                </Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel label="Last Name">
                                <Form.Control
                                    name="lastName"
                                    type="text"
                                    placeholder=" "
                                    value={signup.lastName}
                                    onChange={handleChange}
                                    isInvalid={!!errors.lastName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Last name is required.
                                </Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel label="Email">
                                <Form.Control
                                    name="email"
                                    type="email"
                                    placeholder=" "
                                    value={signup.email}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    A valid email is required.
                                </Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel label="Password">
                                <Form.Control
                                    name="password"
                                    type="password"
                                    placeholder=" "
                                    value={signup.password}
                                    onChange={handleChange}
                                    isInvalid={!!errors.password}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Password is required.
                                </Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel label="Confirm Password">
                                <Form.Control
                                    name="confirmPassword"
                                    type="password"
                                    placeholder=" "
                                    value={signup.confirmPassword}
                                    onChange={handleChange}
                                    isInvalid={!!errors.confirmPassword}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {signup.password !== signup.confirmPassword
                                        ? "Passwords do not match."
                                        : "Please confirm your password."}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                            <Form.Check
                                type="checkbox"
                                label={
                                    <div className="text-sm !font-normal mt-0.5 text-white">
                                        I agree to AlgoLink's{" "}
                                        <Link
                                            className="text-sm text-[#0059EC] no-underline hover:text-[#0059EC] hover:underline"
                                            target="_blank"
                                            to="/terms-of-use"
                                        >
                                            terms
                                        </Link>{" "}
                                        and{" "}
                                        <Link
                                            className="text-sm text-[#0059EC] no-underline hover:text-[#0059EC] hover:underline"
                                            target="_blank"
                                            to="/privacy-policy"
                                        >
                                            privacy policy
                                        </Link>
                                        .
                                    </div>
                                }
                                checked={agreeTerms}
                                onChange={(e) => setAgreeTerms(e.target.checked)}
                                isInvalid={!!errors.agreeTerms}
                                feedback="You must agree before submitting."
                                className="mt-2"
                            />
                            {errorMessage && (
                                <small className="text-danger white-space-pre-wrap">
                                    {errorMessage}
                                </small>
                            )}
                            <Button
                                className="bg-[#0059EC] text-white focus:bg-[#0059EC] hover:bg-[#0059EC] border-[#0059EC] hover:border-[#0059EC] font-medium text-sm px-4 py-2 rounded-lg shadow-sm mt-2.5"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setCurrentStep(2);
                                }}
                                disabled={
                                    !agreeTerms ||
                                    Object.values(errors).some((error) => error)
                                }
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="w-[370px] md:w-[570px]">
                        <div className="p-4 flex flex-col gap-y-2">
                            <ResumeUpload
                                resumeFile={resumeFile}
                                onFileUpload={handleResumeUpload}
                            />
                            {errorMessage && (
                                <small className="text-danger white-space-pre-wrap">
                                    {errorMessage}
                                </small>
                            )}
                            <div className="flex justify-between mt-4">
                                <Button
                                    variant="ghost"
                                    className="bg-transparent hover:bg-transparent text-[#0059EC] hover:text-[#0059EC] font-medium text-sm px-4 py-2 rounded-lg"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setCurrentStep(1);
                                    }}
                                >
                                    Back
                                </Button>
                                <Button
                                    className="bg-[#0059EC] text-white focus:bg-[#0059EC] hover:bg-[#0059EC] border-[#0059EC] hover:border-[#0059EC] font-medium text-sm px-4 py-2 rounded-lg shadow-sm"
                                    type="submit"
                                    disabled={
                                        Object.values(errors).some((error) => error) ||
                                        !resumeFile ||
                                        isSubmitting
                                    }
                                >
                                    {isSubmitting ? (
                                        <>
                                            <span className="mr-2">Signing Up</span>
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </>
                                    ) : (
                                        "Finish Signing Up"
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </Form>
        </div>
    );
}

export default SignUp;
// import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
// import FloatingLabel from "react-bootstrap/FloatingLabel";
// import axiosInterceptor from "../../utils/axiosInterceptor";
// import sendVerificationCode from "../../utils/sendEmailVerification";
// import Spinner from "react-bootstrap/Spinner";
// import ResumeUpload from "./ResumeUpload";

// interface SignUpFields {
//     firstName: string;
//     lastName: string;
//     email: string;
//     password: string;
//     confirmPassword: string;
// }

// interface SignUpErrors {
//     firstName?: boolean;
//     lastName?: boolean;
//     email?: boolean;
//     password?: boolean;
//     confirmPassword?: boolean;
//     agreeTerms?: boolean;
// }

// const BLANK_SIGNUP: SignUpFields = {
//     firstName: "",
//     lastName: "",
//     email: "",
//     password: "",
//     confirmPassword: "",
// };

// function SignUp(): JSX.Element {
//     const navigate = useNavigate();
//     const [signup, setSignup] = useState<SignUpFields>(BLANK_SIGNUP);
//     const [errorMessage, setErrorMessage] = useState<string>("");
//     const [errors, setErrors] = useState<SignUpErrors>({});
//     const [agreeTerms, setAgreeTerms] = useState<boolean>(false);
//     const [currentStep, setCurrentStep] = useState<number>(1);
//     const [resumeFile, setResumeFile] = useState<File | null>(null);
//     const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

//     useEffect(() => {
//         const isAuthenticated = !!localStorage.getItem("access_token");
//         if (isAuthenticated) {
//             navigate("/");
//         }
//     }, [navigate]);

//     function handleChange(e: ChangeEvent<HTMLInputElement>) {
//         setSignup({ ...signup, [e.target.name]: e.target.value });
//     }

//     const handleResumeUpload = (file: File | null) => {
//         setResumeFile(file);
//     };

//     const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
//         event.preventDefault();
//         setErrorMessage("");
//         setIsSubmitting(true);

//         const newErrors: SignUpErrors = {
//             firstName: !signup.firstName,
//             lastName: !signup.lastName,
//             email: !signup.email,
//             password: !signup.password,
//             confirmPassword:
//                 !signup.confirmPassword ||
//                 signup.password !== signup.confirmPassword,
//             agreeTerms: !agreeTerms,
//         };

//         setErrors(newErrors);

//         if (Object.values(newErrors).some(Boolean)) {
//             setErrorMessage("Please fill in all required fields correctly.");
//             setIsSubmitting(false);
//             return;
//         }

//         if (!resumeFile) {
//             setErrorMessage("Please upload a resume.");
//             setIsSubmitting(false);
//             return;
//         }

//         try {
//             const userRegistrationData = {
//                 firstname: signup.firstName,
//                 lastname: signup.lastName,
//                 email: signup.email,
//                 password: signup.password,
//                 password_verify: signup.confirmPassword,
//             };

//             await axiosInterceptor.post("/basic_register/", userRegistrationData);

//             const loginData = {
//                 email: signup.email,
//                 password: signup.password,
//             };
//             const loginResponse = await axiosInterceptor.post("/login/", loginData);

//             const {
//                 refresh,
//                 access,
//                 user_id,
//                 is_email_verified,
//                 is_verified_by_algolink,
//             } = loginResponse.data;

//             localStorage.setItem("access_token", access);
//             localStorage.setItem("refresh_token", refresh);
//             localStorage.setItem("user_id", user_id);
//             localStorage.setItem("is_email_verified", is_email_verified);
//             localStorage.setItem("is_algolink_admin", "false");
//             localStorage.setItem("is_verified_by_algolink", is_verified_by_algolink);
//             localStorage.setItem(
//                 "user_name",
//                 `${signup.firstName} ${signup.lastName}`
//             );

//             const formData = new FormData();
//             formData.append("file", resumeFile);
//             formData.append("root_directory", "resumes");

//             await axiosInterceptor.post(`/file_upload/${user_id}/`, formData, {
//                 headers: {
//                     "Content-Type": "multipart/form-data",
//                 },
//             });

//             await sendVerificationCode(signup.email);
//             navigate("/account/verify");
//         } catch (error: any) {
//             console.error("Error:", error);
//             setCurrentStep(1);

//             if (error.response && error.response.data) {
//                 const errorData = error.response.data;
//                 let localErrorMessage = "";

//                 if (
//                     Array.isArray(errorData) &&
//                     errorData.length > 1 &&
//                     typeof errorData[1] === "object"
//                 ) {
//                     const errorObj = errorData[1];
//                     for (const [field, messages] of Object.entries(errorObj)) {
//                         if (Array.isArray(messages)) {
//                             localErrorMessage += `${capitalizeFirstLetter(
//                                 field
//                             )}: ${messages.join(", ")}\n`;
//                         }
//                     }
//                 } else if (typeof errorData === "string") {
//                     localErrorMessage = errorData;
//                 } else {
//                     localErrorMessage =
//                         "An unexpected error occurred during registration.";
//                 }
//                 setErrorMessage(localErrorMessage.trim());
//             } else {
//                 setErrorMessage("An error occurred during registration.");
//             }
//         } finally {
//             setIsSubmitting(false);
//         }
//     };

//     const capitalizeFirstLetter = (str: string): string => {
//         return str.charAt(0).toUpperCase() + str.slice(1);
//     };

//     return (
//         <Form
//             onSubmit={handleSubmit}
//             className="max-w-lg mx-auto flex flex-col h-full justify-center -mt-8"
//         >
//             {currentStep === 1 ? (
//                 <div className="w-[370px] flex flex-col gap-y-2">
//                     <div className="p-4 flex flex-col gap-y-2">
//                         <h2 className="text-3xl font-medium text-left mb-3.5 text-white">
//                             Sign Up
//                         </h2>
//                         <FloatingLabel label="First Name">
//                             <Form.Control
//                                 name="firstName"
//                                 type="text"
//                                 placeholder=" "
//                                 value={signup.firstName}
//                                 onChange={handleChange}
//                                 isInvalid={!!errors.firstName}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                                 First name is required.
//                             </Form.Control.Feedback>
//                         </FloatingLabel>
//                         <FloatingLabel label="Last Name">
//                             <Form.Control
//                                 name="lastName"
//                                 type="text"
//                                 placeholder=" "
//                                 value={signup.lastName}
//                                 onChange={handleChange}
//                                 isInvalid={!!errors.lastName}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                                 Last name is required.
//                             </Form.Control.Feedback>
//                         </FloatingLabel>
//                         <FloatingLabel label="Email">
//                             <Form.Control
//                                 name="email"
//                                 type="email"
//                                 placeholder=" "
//                                 value={signup.email}
//                                 onChange={handleChange}
//                                 isInvalid={!!errors.email}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                                 A valid email is required.
//                             </Form.Control.Feedback>
//                         </FloatingLabel>
//                         <FloatingLabel label="Password">
//                             <Form.Control
//                                 name="password"
//                                 type="password"
//                                 placeholder=" "
//                                 value={signup.password}
//                                 onChange={handleChange}
//                                 isInvalid={!!errors.password}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                                 Password is required.
//                             </Form.Control.Feedback>
//                         </FloatingLabel>
//                         <FloatingLabel label="Confirm Password">
//                             <Form.Control
//                                 name="confirmPassword"
//                                 type="password"
//                                 placeholder=" "
//                                 value={signup.confirmPassword}
//                                 onChange={handleChange}
//                                 isInvalid={!!errors.confirmPassword}
//                             />
//                             <Form.Control.Feedback type="invalid">
//                                 {signup.password !== signup.confirmPassword
//                                     ? "Passwords do not match."
//                                     : "Please confirm your password."}
//                             </Form.Control.Feedback>
//                         </FloatingLabel>
//                         <Form.Check
//                             type="checkbox"
//                             label={
//                                 <div className="text-sm !font-normal mt-0.5 text-white">
//                                     I agree to AlgoLink's{" "}
//                                     <Link
//                                         className="text-sm text-[#0059EC] no-underline hover:text-[#0059EC] hover:underline"
//                                         target="_blank"
//                                         to="/terms-of-use"
//                                     >
//                                         terms
//                                     </Link>{" "}
//                                     and{" "}
//                                     <Link
//                                         className="text-sm text-[#0059EC] no-underline hover:text-[#0059EC] hover:underline"
//                                         target="_blank"
//                                         to="/privacy-policy"
//                                     >
//                                         privacy policy
//                                     </Link>
//                                     .
//                                 </div>
//                             }
//                             checked={agreeTerms}
//                             onChange={(e) => setAgreeTerms(e.target.checked)}
//                             isInvalid={!!errors.agreeTerms}
//                             feedback="You must agree before submitting."
//                             className="mt-2"
//                         />
//                         {errorMessage && (
//                             <small className="text-danger white-space-pre-wrap">
//                                 {errorMessage}
//                             </small>
//                         )}
//                         <Button
//                             className="bg-[#0059EC] text-white focus:bg-[#0059EC] hover:bg-[#0059EC] border-[#0059EC] hover:border-[#0059EC] font-medium text-sm px-4 py-2 rounded-lg shadow-sm mt-2.5"
//                             onClick={(e) => {
//                                 e.preventDefault();
//                                 setCurrentStep(2);
//                             }}
//                             disabled={
//                                 !agreeTerms ||
//                                 Object.values(errors).some((error) => error)
//                             }
//                         >
//                             Next
//                         </Button>
//                     </div>
//                 </div>
//             ) : (
//                 <div className="w-[370px] md:w-[570px]">
//                     <div className="p-4 flex flex-col gap-y-2">
//                         <ResumeUpload
//                             resumeFile={resumeFile}
//                             onFileUpload={handleResumeUpload}
//                         />
//                         {errorMessage && (
//                             <small className="text-danger white-space-pre-wrap">
//                                 {errorMessage}
//                             </small>
//                         )}
//                         <div className="flex justify-between mt-4">
//                             <Button
//                                 variant="ghost"
//                                 className="bg-transparent hover:bg-transparent text-[#0059EC] hover:text-[#0059EC] font-medium text-sm px-4 py-2 rounded-lg"
//                                 onClick={(e) => {
//                                     e.preventDefault();
//                                     setCurrentStep(1);
//                                 }}
//                             >
//                                 Back
//                             </Button>
//                             <Button
//                                 className="bg-[#0059EC] text-white focus:bg-[#0059EC] hover:bg-[#0059EC] border-[#0059EC] hover:border-[#0059EC] font-medium text-sm px-4 py-2 rounded-lg shadow-sm"
//                                 type="submit"
//                                 disabled={
//                                     Object.values(errors).some((error) => error) ||
//                                     !resumeFile ||
//                                     isSubmitting
//                                 }
//                             >
//                                 {isSubmitting ? (
//                                     <>
//                                         <span className="mr-2">Signing Up</span>
//                                         <Spinner
//                                             as="span"
//                                             animation="border"
//                                             size="sm"
//                                             role="status"
//                                             aria-hidden="true"
//                                         />
//                                     </>
//                                 ) : (
//                                     "Finish Signing Up"
//                                 )}
//                             </Button>
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </Form>
//     );
// }

// export default SignUp;

import { FC } from "react";
import { ButtonProps } from "../types/interfaces";

/**
 * Buttons are elements that call a provided callback when clicked.
 * Button components are available for the Positive, Primary, Negative, and Default styles
 */

/**
 * PrimaryButton component
 * @component
 * @param {Object} props - The props for the PrimaryButton component
 * @param {ReactElement<SVGElement>} [props.icon] - Optional SVG icon element to display in the button
 * @param {string} props.message - Text to display on the button
 * @param {MouseEventHandler<HTMLButtonElement>} [props.handleClick] - Optional click event handler for the button
 * @returns {ReactElement} A primary styled button element
 */
export const PrimaryButton: FC<ButtonProps> = ({
  icon,
  message,
  handleClick,
}) => {
  return (
    <button
      className="rounded-sm bg-accent-primary hover:bg-accent-primary-dimmer flex flex-row gap-2 items-center justify-center h-8 p-3 transition-colors w-min 
        focus-visible:outline focus-visible:outline-2 focus-visible:outline-accent-primary-dimmer focus-visible:outline-offset-2"
      onClick={handleClick}
    >
      {icon}
      <p className="text-background text-nowrap">{message}</p>
    </button>
  );
};

/**
 * DefaultButton component
 * @component
 * @param {Object} props - The props for the DefaultButton component
 * @param {ReactElement<SVGElement>} [props.icon] - Optional SVG icon element to display in the button
 * @param {string} props.message - Text to display on the button
 * @param {MouseEventHandler<HTMLButtonElement>} [props.handleClick] - Optional click event handler for the button
 * @returns {ReactElement} A default styled button element
 */
export const DefaultButton: FC<ButtonProps> = ({
  icon,
  message,
  handleClick,
}) => {
  return (
    <button
      className="rounded-sm bg-background hover:bg-background-dimmer flex flex-row gap-2 items-center justify-center h-8 p-3 border-[1px] border-background-dimmest border-solid transition-colors w-min 
        focus-visible:outline focus-visible:outline-2 focus-visible:outline-foreground focus-visible:outline-offset-2"
      onClick={handleClick}
    >
      {icon}
      <p className="text-foreground text-nowrap">{message}</p>
    </button>
  );
};

/**
 * PositiveButton component
 * @component
 * @param {Object} props - The props for the PositiveButton component
 * @param {ReactElement<SVGElement>} [props.icon] - Optional SVG icon element to display in the button
 * @param {string} props.message - Text to display on the button
 * @param {MouseEventHandler<HTMLButtonElement>} [props.handleClick] - Optional click event handler for the button
 * @returns {ReactElement} A positive styled button element
 */
export const PositiveButton: FC<ButtonProps> = ({
  icon,
  message,
  handleClick,
}) => {
  return (
    <button
      className="rounded-sm bg-accent-positive hover:bg-accent-positive-dimmer flex flex-row gap-2 items-center justify-center h-8 p-3 transition-colors w-min 
        focus-visible:outline focus-visible:outline-2 focus-visible:outline-accent-positive-dimmer focus-visible:outline-offset-2"
      onClick={handleClick}
    >
      {icon}
      <p className="text-background text-nowrap">{message}</p>
    </button>
  );
};

/**
 * NegativeButton component
 * @component
 * @param {Object} props - The props for the NegativeButton component
 * @param {ReactElement<SVGElement>} [props.icon] - Optional SVG icon element to display in the button
 * @param {string} props.message - Text to display on the button
 * @param {MouseEventHandler<HTMLButtonElement>} [props.handleClick] - Optional click event handler for the button
 * @returns {ReactElement} A negative styled button element
 */
export const NegativeButton: FC<ButtonProps> = ({
  icon,
  message,
  handleClick,
}) => {
  return (
    <button
      className="rounded-sm bg-accent-negative hover:bg-accent-negative-dimmer flex flex-row gap-2 items-center justify-center h-8 p-3 transition-colors w-min
        focus-visible:outline focus-visible:outline-2 focus-visible:outline-accent-negative-dimmer focus-visible:outline-offset-2"
      onClick={handleClick}
    >
      {icon}
      <p className="text-background text-nowrap">{message}</p>
    </button>
  );
};

import React from "react";
import { Link } from "react-router-dom";

const NotFound: React.FC = () => {
    return (
        <div className="bg-[#0E172A] flex items-center justify-center w-full h-screen">
            <div className="text-center">
                <h1 className="text-white display-1 fw-bold">404</h1>
                <p className="text-white fs-3">
                    <span className="text-danger">Oops!</span> Page not found.
                </p>
                <p className="text-white lead">
                    The page you're looking for doesn't exist.
                </p>
                <Link to="/" className="btn btn-primary">
                    Go Home
                </Link>
            </div>
        </div>
    );
};

export default NotFound;
import React, { useRef, useState, useEffect } from "react";
import Avatar from "boring-avatars";
import { Pencil, User, Mail, Link, ExternalLink } from "lucide-react";
import { Button, Modal } from "react-bootstrap";
import axiosInterceptor from "../../utils/axiosInterceptor";
import EditProfile from "./EditProfile";
import { PersonalInfo } from "./interfaces";

interface ProfileHeaderProps {
    name: string;
    phone: string;
    email: string;
    address: string;
    birthday: string;
    visa: string;
    website: string;
    linkedin: string;
    github: string;
    countries: string[];
    user_data: PersonalInfo;
    userId: string;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
    name, phone, email, address, birthday, visa,
    website, linkedin, github, countries, user_data, userId
}) => {
    const [showEdit, setShowEdit] = useState(false);
    const [profileImage, setProfileImage] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const fetchProfileImage = async () => {
            try {
                const response = await axiosInterceptor.get(
                    `serve_private_doc/user_career_data/user_id=${userId}/images/`
                );
                if (response.data?.private_docs?.length) {
                    const [presignedUrl] = response.data.private_docs[0];
                    setProfileImage(presignedUrl);
                }
            } catch (err) {
                console.error("Error fetching profile image:", err);
            }
        };
        fetchProfileImage();
    }, [userId]);

    const handleImageUploadClick = () => fileInputRef.current?.click();

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;
        if (!["image/jpeg", "image/png"].includes(file.type)) {
            alert("Please select a JPG or PNG image.");
            return;
        }
        
        const formData = new FormData();
        formData.append("file", file);

        try {
            const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1] || "";
            await axiosInterceptor.post(`images/upload/${userId}/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "X-CSRFToken": csrfToken,
                },
            });
            const response = await axiosInterceptor.get(
                `serve_private_doc/user_career_data/user_id=${userId}/images/`
            );
            if (response.data?.private_docs?.length) {
                const [presignedUrl] = response.data.private_docs[0];
                setProfileImage(presignedUrl);
            }
        } catch (error) {
            console.error("Error uploading profile image:", error);
            alert("Failed to upload profile image.");
        }
    };

    return (
        <div className="bg-white rounded-2xl border border-gray-200 overflow-hidden">
            {/* Header */}
            <div className="px-4 py-4">
                <div className="flex items-center justify-between gap-3">
                    <div className="flex items-center gap-4 flex-grow">
                        {/* Profile Image */}
                        <div className="relative">
                            <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-100">
                                {profileImage ? (
                                    <img
                                        src={profileImage}
                                        alt="Profile"
                                        className="w-full h-full object-cover"
                                    />
                                ) : (
                                    <Avatar
                                        name={userId}
                                        variant="beam"
                                        size={64}
                                        colors={["#99CBFF", "#EDEDED"]}
                                    />
                                )}
                            </div>
                            <button
                                onClick={handleImageUploadClick}
                                className="absolute -bottom-1 -right-1 bg-white rounded-full p-1 shadow-md hover:bg-gray-50 border border-gray-200"
                            >
                                <Pencil className="w-3 h-3 text-gray-600" />
                            </button>
                        </div>
                        
                        <div>
                            <h2 className="text-xl font-semibold text-gray-900">{name}</h2>
                        </div>
                    </div>
                    <button
                        onClick={() => setShowEdit(true)}
                        className="w-8 h-8 flex items-center justify-center text-gray-400 hover:text-gray-600 rounded-full"
                    >
                        <Pencil className="w-5 h-5" />
                    </button>
                </div>
            </div>

            {/* Main Content */}
            <div className="px-4 py-0">
                <div className="grid grid-cols-3 gap-x-6 gap-y-4">
                    {/* Personal Info */}
                    <div className="flex items-start gap-3">
                        <User className="w-6 h-6 text-blue-600 flex-shrink-0" />
                        <div>
                            <h4 className="text-lg font-semibold text-gray-900">Personal Info</h4>
                            <div className="mt-3 space-y-3">
                                <div>
                                    <p className="text-sm font-medium text-gray-500">Address</p>
                                    <p className="text-base font-medium text-gray-900 mt-1">{address || "Not specified"}</p>
                                </div>
                                <div>
                                    <p className="text-sm font-medium text-gray-500">Date of Birth</p>
                                    <p className="text-base font-medium text-gray-900 mt-1">{birthday || "Not specified"}</p>
                                </div>
                                <div>
                                    <p className="text-sm font-medium text-gray-500">Visa Status</p>
                                    <p className="text-base font-medium text-gray-900 mt-1">{visa || "Not specified"}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Contact Info */}
                    <div className="flex items-start gap-3">
                        <Mail className="w-6 h-6 text-blue-600 flex-shrink-0" />
                        <div>
                            <h4 className="text-lg font-semibold text-gray-900">Contact</h4>
                            <div className="mt-3 space-y-3">
                                <div>
                                    <p className="text-sm font-medium text-gray-500">Phone</p>
                                    <p className="text-base font-medium text-gray-900 mt-1">{phone || "Not specified"}</p>
                                </div>
                                <div>
                                    <p className="text-sm font-medium text-gray-500">Email</p>
                                    <p className="text-base font-medium text-gray-900 mt-1">{email}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Links */}
                    <div className="flex items-start gap-3">
                        <Link className="w-6 h-6 text-blue-600 flex-shrink-0" />
                        <div>
                            <h4 className="text-lg font-semibold text-gray-900">Links</h4>
                            <div className="mt-3 space-y-3">
                                <div>
                                    <p className="text-sm font-medium text-gray-500">Website</p>
                                    {website ? (
                                        <a href={website} target="_blank" rel="noopener noreferrer" 
                                           className="text-base font-medium text-blue-600 hover:text-blue-700 flex items-center gap-1 mt-1">
                                            {website.replace(/^https?:\/\//, '')}
                                            <ExternalLink className="w-4 h-4" />
                                        </a>
                                    ) : (
                                        <p className="text-base font-medium text-gray-400 mt-1">Not specified</p>
                                    )}
                                </div>
                                <div>
                                    <p className="text-sm font-medium text-gray-500">LinkedIn</p>
                                    {linkedin ? (
                                        <a href={linkedin} target="_blank" rel="noopener noreferrer"
                                           className="text-base font-medium text-blue-600 hover:text-blue-700 flex items-center gap-1 mt-1">
                                            View Profile
                                            <ExternalLink className="w-4 h-4" />
                                        </a>
                                    ) : (
                                        <p className="text-base font-medium text-gray-400 mt-1">Not specified</p>
                                    )}
                                </div>
                                <div>
                                    <p className="text-sm font-medium text-gray-500">GitHub</p>
                                    {github ? (
                                        <a href={github} target="_blank" rel="noopener noreferrer"
                                           className="text-base font-medium text-blue-600 hover:text-blue-700 flex items-center gap-1 mt-1 mb-4">
                                            View GitHub
                                            <ExternalLink className="w-4 h-4" />
                                        </a>
                                    ) : (
                                        <p className="text-base font-medium text-gray-400 mt-1">Not specified</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Edit Profile Modal */}
            <Modal show={showEdit} onHide={() => setShowEdit(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditProfile existingInfo={user_data} />
                </Modal.Body>
            </Modal>

            {/* Hidden file input */}
            <input
                type="file"
                ref={fileInputRef}
                className="hidden"
                accept=".jpg,.jpeg,.png"
                onChange={handleFileChange}
            />
        </div>
    );
};

export default ProfileHeader;
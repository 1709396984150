import React, { useState, useEffect } from "react";
import axiosInterceptor from "../../utils/axiosInterceptor";
import { ChecklistCardProps, ChecklistItem } from "./interfaces";

const ChecklistCard: React.FC<ChecklistCardProps> = ({ userId }) => {
  const [items, setItems] = useState<ChecklistItem[]>([
    { id: 1, text: "Complete Profile Information", completed: false },
    { id: 2, text: "Upload Resume", completed: false },
    { id: 3, text: "Add Education History", completed: false },
    { id: 4, text: "Add Work Experience", completed: false },
    { id: 5, text: "Complete Job Preferences Survey", completed: false },
    { id: 6, text: "Complete Personality Survey", completed: false },
    { id: 7, text: "Add Profile Picture", completed: false },
    { id: 8, text: "Link Social Profiles", completed: false },
  ]);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleItem = async (id: number) => {
    try {
      const updatedItems = items.map((item) =>
        item.id === id ? { ...item, completed: !item.completed } : item
      );
      setItems(updatedItems);

      // Save to backend
      await axiosInterceptor.post(`/update_checklist/${userId}/`, {
        checklist: updatedItems,
      });
    } catch (error) {
      console.error("Error updating checklist:", error);
    }
  };

  useEffect(() => {
    const fetchChecklist = async () => {
      try {
        const response = await axiosInterceptor.get(
          `/get_checklist/${userId}/`
        );
        if (response.data?.checklist) {
          setItems(response.data.checklist);
        }
      } catch (error) {
        console.error("Error fetching checklist:", error);
      }
    };

    fetchChecklist();
  }, [userId]);

  return (
    <div className="flex flex-col w-full gap-y-3 p-6 rounded-lg shadow-card bg-white">
      <div className="flex items-center justify-between mb-2">
        <div>
          <h4 className="text-black text-lg font-medium">Profile Checklist</h4>
          <p className="text-sm text-gray-500 mt-1">
            {items.filter((item) => item.completed).length} of {items.length}{" "}
            items complete
          </p>
        </div>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="p-2 hover:bg-gray-100 rounded-lg transition-colors"
          aria-label={isExpanded ? "Collapse checklist" : "Expand checklist"}
        >
          <svg
            className={`w-6 h-6 text-gray-500 transform transition-transform ${
              isExpanded ? "rotate-180" : ""
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
      </div>
      <div
        className={`space-y-3 overflow-hidden transition-all duration-300 ${
          isExpanded ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        {items.map((item) => (
          <div
            key={item.id}
            className="flex items-center p-3 hover:bg-gray-50 rounded-lg border border-gray-200 cursor-pointer"
            onClick={() => toggleItem(item.id)}
          >
            <div className="flex items-center justify-center w-6 h-6 border-2 border-gray-300 rounded-lg mr-3 transition-colors">
              {item.completed && (
                <svg
                  className="w-4 h-4 text-blue-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </div>
            <span
              className={`flex-1 ${
                item.completed ? "text-gray-500 line-through" : "text-gray-900"
              }`}
            >
              {item.text}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChecklistCard;

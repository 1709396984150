import React from 'react';
import { ReactComponent as WavePattern } from '../../assets/img/landing/vector.svg';
import Amazon from "../../assets/img/companies/amazon.png";
import Citadel from "../../assets/img/companies/citadel.png";
import Goldman from "../../assets/img/companies/goldman.png";
import JaneStreet from "../../assets/img/companies/jane-street.png";
import JPM from "../../assets/img/companies/jpm.png";
import Microsoft from "../../assets/img/companies/microsoft.png";
import Point72 from "../../assets/img/companies/point72.png";
import Sig from "../../assets/img/companies/sig.png";

const HeroSection: React.FC = () => {
  return (
    <div className="bg-[#0F172A] relative overflow-hidden min-h-screen">
      {/* Wave Pattern Background */}
      <div className="absolute inset-0 w-full h-full">
        <div className="absolute top-40 right-0 w-[100%] h-[100%] transform -translate-x-0 -translate-y-0">
          <WavePattern className="w-full h-full transform scale-1 rotate-0" />
        </div>
      </div>
      
      {/* Hero Content */}
      <div className="max-w-7xl mx-auto px-4 pt-64">
        <div className="max-w-2xl">
          <h1 className="text-6xl font-bold mb-12">
            <span className="text-blue-500">Find Employees</span>{" "}
            <br></br>
            <span className="text-white">to Refer You</span>
          </h1>
          <p className="text-gray-300 text-lg mb-6 max-w-xl">
          We provide 24/7 recommendations of ideal recruiter and referrer matches who are specifically looking for people to refer. We offer comprehensive profiles, tests, and courses so you have a fair opportunity to express yourself
          </p>
        </div>

        {/* Company Logos Section */}
        <div className="mt-80">
          <main className="relative flex flex-col justify-center overflow-hidden mt-8">
            <div className="w-full max-w-5xl mx-auto">
              <div className="text-center">
                <div
                  x-data="{}"
                  x-init="$nextTick(() => {
                    let ul = $refs.logos;
                    ul.insertAdjacentHTML('afterend', ul.outerHTML);
                    ul.nextSibling.setAttribute('aria-hidden', 'true');
                  })"
                  className="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]"
                >
                  <ul
                    x-ref="logos"
                    className="flex items-center justify-center lg:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll -mx-2"
                  >
                    <li className="py-4 h-20 flex items-center justify-center">
                      <img src={Citadel} alt="Citadel logo" className="max-h-full w-auto" />
                    </li>
                    <li className="py-4 h-20 flex items-center justify-center">
                      <img src={Amazon} alt="Amazon logo" className="max-h-full w-auto" />
                    </li>
                    <li className="py-4 h-20 flex items-center justify-center">
                      <img src={Goldman} alt="Goldman Sachs logo" className="max-h-full w-auto" />
                    </li>
                    <li className="py-4 h-20 flex items-center justify-center">
                      <img src={JaneStreet} alt="Jane Street logo" className="max-h-full w-auto" />
                    </li>
                    <li className="py-4 h-20 flex items-center justify-center">
                      <img src={JPM} alt="JPMorgan Chase logo" className="max-h-full w-auto" />
                    </li>
                    <li className="py-4 h-20 flex items-center justify-center">
                      <img src={Microsoft} alt="Microsoft logo" className="max-h-full w-auto" />
                    </li>
                    <li className="py-4 h-20 flex items-center justify-center">
                      <img src={Sig} alt="Susquehanna International Group logo" className="max-h-full w-auto" />
                    </li>
                    <li className="py-4 h-20 flex items-center justify-center">
                      <img src={Point72} alt="Point72 logo" className="max-h-full w-auto" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GripVertical } from "lucide-react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import Logo from "../../../assets/img/logos/all_black.png";
import axiosInterceptor from "../../../utils/axiosInterceptor";

// Core types for the survey
type QuestionType = 'multiSelect' | 'ranking';
type CategoryType = 'industries' | 'jobTitles' | 'locations' | 'companySize' | 'technologies' | 'salaryRanges' | 'jobLevels';

interface QuestionSection {
  title: string;
  subtitle?: string;
  options: readonly string[];
  type: QuestionType;
}

interface MultiSelectAnswers {
  [option: string]: boolean;
}

interface RankingAnswers {
  [option: string]: number;
}

type CategoryAnswers = MultiSelectAnswers | RankingAnswers;
type SurveyAnswers = Partial<Record<CategoryType, CategoryAnswers>>;

interface MessageState {
  type: 'success' | 'error' | 'warning';
  text: string;
}

const JobPreferencesSurvey: React.FC = () => {
  const [answers, setAnswers] = useState<SurveyAnswers>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [message, setMessage] = useState<MessageState | null>(null);
  const navigate = useNavigate();

  const questions: Record<CategoryType, QuestionSection> = {
    jobLevels: {
      title: "What job levels would you interview for?",
      subtitle: "Select all that apply",
      type: 'multiSelect',
      options: [
        "Entry (0-2 years, just starting)",
        "Standard (3-5 years, some experience)",
        "Senior (5+ years, managing team)",
        "Staff (10+ years, managing department)",
        "Principal (15+ years, managing departments)",
        "Distinguished (20+ years, managing organization)"
      ]
    },
    salaryRanges: {
      title: "What salary ranges would you consider?",
      subtitle: "Select all that apply",
      type: 'multiSelect',
      options: [
        "60,000-80,000",
        "80,000-100,000",
        "100,000-125,000",
        "125,000-150,000",
        "150,000-175,000",
        "175,000-200,000",
        "200,000-250,000",
        "250,000-300,000",
        "300,000-400,000",
        "400,000-500,000",
        "500,000+"
      ]
    },
    industries: {
      title: "Rank these industries by preference",
      subtitle: "Drag to reorder from most to least preferred",
      type: 'ranking',
      options: [
        "Proprietary Trading Firms",
        "Hedge Funds",
        "Big Tech",
        "Tech Startups",
        "BioTech & Healthcare",
        "Finance & Investment Banks",
        "Aerospace & Defense",
        "Entertainment & Gaming",
        "Automotive",
        "Energy",
        "Telecommunications",
        "Consulting",
        "E-Commerce",
        "Manufacturing",
        "Education",
        "Government & Public Sector"
      ]
    },
    jobTitles: {
      title: "Rank your interest in these job titles",
      subtitle: "Order from most to least preferred",
      type: 'ranking',
      options: [
        "Quantitative Researcher",
        "Quantitative Trader",
        "Quantitative Developer",
        "Research Scientist",
        "Machine Learning Engineer",
        "Data Scientist",
        "Backend Engineer",
        "Frontend Engineer",
        "Product Manager",
        "Data Analyst",
        "UX/UI Designer"
      ]
    },
    locations: {
      title: "Rate your interest in these locations",
      subtitle: "Order from most to least preferred",
      type: 'ranking',
      options: [
        "Remote",
        "Silicon Valley, CA",
        "San Diego, CA",
        "Los Angeles, CA",
        "New York City, NY",
        "Boston, MA",
        "Seattle, WA",
        "Austin, TX",
        "Washington D.C.",
        "Atlanta, GA",
        "Raleigh-Durham, NC",
        "Chicago, IL",
        "Pittsburgh, PA",
        "Minneapolis-St. Paul, MN",
        "Salt Lake City, UT"
      ]
    },
    companySize: {
      title: "Rate your interest in these company sizes",
      subtitle: "Order from most to least preferred",
      type: 'ranking',
      options: [
        "2-10 employees",
        "11-50 employees",
        "51-200 employees",
        "201-500 employees",
        "501-1000 employees",
        "1001-5000 employees",
        "5001-10000 employees",
        "10001-50000 employees",
        "50000+ employees"
      ]
    },
    technologies: {
      title: "Rate your interest in these technologies",
      subtitle: "Order from most to least preferred",
      type: 'ranking',
      options: [
        "Python",
        "R",
        "C++",
        "Java",
        "Javascript",
        "HTML & CSS",
        "Web Frameworks (Django, Flask)",
        "Distributed Compute (Spark, Ray, Dask)",
        "Cloud Services (AWS, Azure, GCP)",
        "ML Libraries (Scikit, PyTorch, TensorFlow)",
        "Scientific Compute Libraries (Numpy, Pandas/Polars)",
        "Databases (SQL, Cassandra, Kdb)",
        "Data Lakes (Delta Lake, Apache Hudi)"
      ]
    }
  };

  // Question definitions remain the same...

  const handleMultiSelectChange = (category: CategoryType, option: string): void => {
    setAnswers(prev => ({
      ...prev,
      [category]: {
        ...(prev[category] ?? {}),
        [option]: !(prev[category]?.[option] ?? false)
      }
    }));
  };

  const handleSelectAll = (category: CategoryType, options: readonly string[]): void => {
    const allSelected = options.every(opt => answers[category]?.[opt]);
    const newValue = !allSelected;
    
    setAnswers(prev => ({
      ...prev,
      [category]: options.reduce((acc, opt) => ({
        ...acc,
        [opt]: newValue
      }), {})
    }));
  };

  const handleRankingChange = (category: CategoryType, result: DropResult): void => {
    if (!result.destination) return;

    const { source, destination } = result;
    const options = questions[category].options;
    
    const currentOrder = Object.entries(answers[category] as RankingAnswers || {})
      .sort(([, rankA], [, rankB]) => rankA - rankB)
      .map(([option]) => option);

    if (currentOrder.length === 0) {
      currentOrder.push(...options);
    }

    const [reorderedItem] = currentOrder.splice(source.index, 1);
    currentOrder.splice(destination.index, 0, reorderedItem);

    const newRanking = currentOrder.reduce((acc, option, index) => ({
      ...acc,
      [option]: index + 1
    }), {});

    setAnswers(prev => ({
      ...prev,
      [category]: newRanking
    }));
  };

  const handleSave = async (): Promise<void> => {
    setSaving(true);
    setMessage(null);

    const userId = localStorage.getItem("user_id");
    if (!userId) {
      setMessage({ type: "error", text: "User ID not found. Please log in again." });
      setSaving(false);
      return;
    }

    try {
      const response = await axiosInterceptor.patch(
        `/save_or_update_user_surveys/${userId}/`,
        { job_pref_survey: JSON.stringify(answers) }
      );
      
      if (response.status === 200) {
        navigate("/survey-success");
      }
    } catch (error: any) {
      if (error.response?.status === 404) {
        try {
          const postResponse = await axiosInterceptor.post(
            `/save_or_update_user_surveys/${userId}/`,
            { job_pref_survey: JSON.stringify(answers) }
          );
          if (postResponse.status === 201 || postResponse.status === 200) {
            navigate("/survey-success");
          }
        } catch (postError) {
          setMessage({ type: "error", text: "Failed to submit survey responses." });
        }
      } else {
        setMessage({ type: "error", text: "Failed to update survey responses." });
      }
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    const fetchPreviousData = async (): Promise<void> => {
      try {
        const userId = localStorage.getItem("user_id");
        if (!userId) throw new Error("User ID not found");

        const response = await axiosInterceptor.get(`/save_or_update_user_surveys/${userId}/`);
        if (response.data?.job_pref_survey) {
          let parsedData = JSON.parse(response.data.job_pref_survey);
          if (typeof parsedData === 'string') {
            parsedData = JSON.parse(parsedData);
          }
          setAnswers(parsedData);
        }
      } catch (error) {
        console.error("Error fetching survey data:", error);
        setMessage({ type: "error", text: "Failed to load previous survey data." });
      } finally {
        setLoading(false);
      }
    };

    fetchPreviousData();
  }, []);

  const MultiSelectQuestion: React.FC<{
    category: CategoryType;
    section: QuestionSection;
  }> = ({ category, section: { title, subtitle, options } }) => (
    <div className="p-6 mb-6">
      <div className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">{title}</h2>
        {subtitle && <p className="text-gray-600">{subtitle}</p>}
      </div>

      <div className="space-y-3">
        <label className="flex items-center p-3 hover:bg-gray-50 rounded cursor-pointer">
          <input
            type="checkbox"
            className="w-5 h-5 text-blue-500 rounded border-gray-300"
            onChange={() => handleSelectAll(category, options)}
            checked={options.every(opt => (answers[category] as MultiSelectAnswers)?.[opt])}
          />
          <span className="ml-3 text-gray-600">Select all that apply</span>
        </label>

        {options.map((option) => (
          <label
            key={option}
            className="flex items-center p-3 hover:bg-gray-50 rounded cursor-pointer border border-gray-200"
          >
            <input
              type="checkbox"
              className="w-5 h-5 text-blue-500 rounded border-gray-300"
              checked={(answers[category] as MultiSelectAnswers)?.[option] ?? false}
              onChange={() => handleMultiSelectChange(category, option)}
            />
            <span className="ml-3">{option}</span>
          </label>
        ))}
      </div>
    </div>
  );

  const RankingQuestion: React.FC<{
    category: CategoryType;
    section: QuestionSection;
  }> = ({ category, section: { title, subtitle, options } }) => {
    const currentOrder = Object.entries(answers[category] as RankingAnswers || {})
      .sort(([, rankA], [, rankB]) => rankA - rankB)
      .map(([option]) => option);

    const displayOrder = currentOrder.length === 0 ? options : currentOrder;

    return (
      <div className="p-6 mb-6">
        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-2">{title}</h2>
          {subtitle && <p className="text-gray-600">{subtitle}</p>}
        </div>

        <DragDropContext onDragEnd={(result) => handleRankingChange(category, result)}>
          <Droppable droppableId={category}>
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="space-y-2"
              >
                {displayOrder.map((option, index) => (
                  <Draggable key={option} draggableId={option} index={index}>
                    {(provided, snapshot) => {
                      const style = {
                        ...provided.draggableProps.style,
                      };

                      return (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={style}
                          className={`flex items-center p-4 bg-white border rounded-lg transition-shadow ${
                            snapshot.isDragging ? 'shadow-lg border-blue-500' : 'border-gray-200 hover:border-gray-300'
                          }`}
                        >
                          <div className="flex items-center w-full">
                            <span className="w-8 h-8 flex items-center justify-center bg-gray-100 rounded-full mr-4 text-gray-600 font-medium">
                              {index + 1}
                            </span>
                            <span className="flex-1">{option}</span>
                            <div
                              {...provided.dragHandleProps}
                              className="text-gray-400 hover:text-gray-600 cursor-grab active:cursor-grabbing p-1"
                            >
                              <GripVertical className="w-5 h-5" />
                            </div>
                          </div>
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl text-gray-600">Loading survey...</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white py-8">
      {message && (
        <div className={`fixed top-4 right-4 p-4 rounded-lg ${
          message.type === 'error' ? 'bg-red-100 text-red-700' :
          message.type === 'success' ? 'bg-green-100 text-green-700' :
          'bg-yellow-100 text-yellow-700'
        }`}>
          {message.text}
        </div>
      )}
      
      <div className="max-w-4xl mx-auto px-4">
        <div className="p-6 mb-6">
          <div className="flex items-center justify-start mb-2">
            <img src={Logo} alt="Logo" className="h-10" />
          </div>
          <h1 className="text-3xl font-bold mb-4">Job Preferences Survey</h1>
          <p className="text-gray-600 mb-2">
            Help us understand your job preferences by completing this survey. Your answers will help us match you with the most relevant opportunities.
          </p>
          <p className="text-gray-600 mb-4">
            <strong>Instructions:</strong>
          </p>
          <ul className="list-disc list-inside text-gray-600 space-y-1 mb-4">
            <li>For checkboxes, select all options that apply to you</li>
            <li>For ranking questions, drag and drop items to order them from most preferred (top) to least preferred (bottom)</li>
          </ul>
        </div>

        {(Object.entries(questions) as [CategoryType, QuestionSection][]).map(([category, section]) => (
          section.type === 'multiSelect' 
            ? <MultiSelectQuestion key={category} category={category} section={section} />
            : <RankingQuestion key={category} category={category} section={section} />
        ))}

        <div className="p-4 mt-6">
          <button
            onClick={handleSave}
            disabled={saving}
            className={`w-full bg-blue-500 text-white text-lg font-medium py-4 rounded-lg transition-opacity ${
              saving ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-600"
            }`}
          >
            {saving ? "Saving..." : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobPreferencesSurvey;

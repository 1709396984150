import { educationalLevels } from "./constants";
import { AttemptType } from "./interfaces";
import { testResults } from "./constants";
// ===================================
// Helper Functions
// ===================================
export const formatKeyToLabel = (key: string): string => {
  const labelMap: { [key: string]: string } = {
    computerFoundations: "Computer Foundations",
    mathFoundations: "Math Foundations",
    dataFoundations: "Data Foundations",
    softwareEngineer: "Software Engineer",
    networkEngineer: "Network Engineer",
    hardwareEngineer: "Hardware Engineer",
    electricalEngineer: "Electrical Engineer",
    aiResearch: "Artificial Intelligence Research",
    bioinfo: "Bioinformatics",
    quantTrader: "Quantitative Trader",
    quantResearcher: "Quantitative Researcher",
  };
  return labelMap[key] || key;
};

export const getEducationalLevel = (value: number): string => {
  return educationalLevels.reduce((prev, curr) => {
    return Math.abs(curr.value - value) < Math.abs(prev.value - value)
      ? curr
      : prev;
  }, educationalLevels[0]).label;
};

// Utility function to render textual results for background/behavioral tests
export const getResultContent = (
    testType: "background" | "behavioral",
    attempt: AttemptType,
    view: "overview" | "analysis"
  ): string => {
    return testResults[testType][attempt][view];
  };
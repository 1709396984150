import React from "react";
import { Navigate, useLocation } from "react-router-dom";

interface VerificationRequiredRouteProps {
  component: React.ComponentType; // Accepts any React component
  emailSuffices?: boolean;
  adminRequired?: boolean;
  // [key: string]: any; // Allows additional props
}

export function VerificationRequiredRoute({
  component: Component,
  emailSuffices = false,
  adminRequired = false,
  ...rest
}: VerificationRequiredRouteProps): JSX.Element {
  const isEmailVerified = localStorage.getItem("is_email_verified") === "true";
  const isVerifiedByAlgolink =
    localStorage.getItem("is_verified_by_algolink") === "true";
  const isAuthenticated = !!localStorage.getItem("access_token");
  const isAlgoLinkAdmin = localStorage.getItem("is_algolink_admin") === "true";
  const location = useLocation();

  if (
    isAuthenticated &&
    isEmailVerified &&
    !isVerifiedByAlgolink &&
    !emailSuffices
  ) {
    return <Navigate to="/account/submitted" state={{ from: location }} />;
  }

  if (
    isAuthenticated &&
    isEmailVerified &&
    isVerifiedByAlgolink &&
    location.pathname === "/account/submitted"
  ) {
    return <Navigate to="/" />;
  }

  if (
    isAuthenticated &&
    !isEmailVerified &&
    location.pathname !== "/profile" &&
    location.pathname !== "/account/verify" &&
    location.pathname !== "/account/signup" &&
    location.pathname !== "/account/login"
  ) {
    return <Navigate to="/account/verify" state={{ from: location }} />;
  }

  if (
    !isAuthenticated &&
    location.pathname !== "/account/verify" &&
    location.pathname !== "/account/signup" &&
    location.pathname !== "/account/login"
  ) {
    return <Navigate to="/account/signup" state={{ from: location }} />;
  }

  if (adminRequired && !isAlgoLinkAdmin) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return <Component {...rest} />;
}

// ===================================
// Educational Levels Configuration
// ===================================
export const educationalLevels = [
    { value: 0, label: "High School" },
    { value: 25, label: "Bachelors" },
    { value: 50, label: "Masters" },
    { value: 75, label: "PhD" },
    { value: 100, label: "World Class" },
  ];
  
  // ===================================
  // Colors Configuration
  // ===================================
export const colors: { [key: string]: string } = {
    computerFoundations: "#3B82F6",
    mathFoundations: "#10B981",
    dataFoundations: "#6366F1",
    softwareEngineer: "#F59E0B",
    networkEngineer: "#EC4899",
    hardwareEngineer: "#8B5CF6",
    electricalEngineer: "#14B8A6",
    aiResearch: "#EF4444",
    bioinfo: "#F97316",
    quantTrader: "#06B6D4",
    quantResearcher: "#A855F7",
  };
  
// ===================================
// Test Content / Results Data
// ===================================
//
// This object stores all the result data for each test (background, behavioral, technical)
// and each attempt (attempt1, attempt2).
//
// * For the background/behavioral tests, we only have textual results (overview/analysis).
// * For the technical test, we have both a chart data set (progressData) and the attempt summary.
export const testResults = {
    background: {
      attempt1: {
        overview: `Your background interview responses demonstrate a clear and well-articulated professional journey. You effectively highlighted your key achievements in software development, particularly your work on scalable systems and team leadership. Your educational background in Computer Science provides a strong foundation, though there's room to better connect your academic projects to real-world applications.`,
        analysis: `Your responses show strong technical depth and project ownership. Key strengths include your ability to articulate complex technical decisions and demonstrate growth through challenges. Consider providing more specific metrics and business impacts in your project discussions. Your explanation of career transitions was clear, though you could strengthen how you frame your future career goals.`,
      },
      attempt2: {
        overview: `Your second attempt at the background interview shows an improvement in framing your academic experiences to match industry needs. You provided clearer examples of real-world applications and connected those to your past roles more effectively.`,
        analysis: `Analysis indicates greater specificity in explaining technical decisions and their impact on business goals. You still have room to highlight your unique strengths with more metrics and data points, but your overall narrative is more coherent and compelling than your first attempt.`,
      },
    },
    behavioral: {
      attempt1: {
        overview: `Your behavioral interview responses reflect strong interpersonal skills and leadership potential. You demonstrated excellent communication abilities and showed good judgment in handling complex situations. Your examples effectively showcased your teamwork and ability to navigate challenging workplace dynamics.`,
        analysis: `Analysis shows particular strengths in Communication (92%), Leadership (88%), and Teamwork (90%). Areas for development include Client Focus (75%) and Working Under Pressure (78%). Your responses demonstrate high emotional intelligence and strong initiative, though there's room to improve in articulating conflict resolution strategies. Your examples of project leadership were particularly compelling.`,
      },
      attempt2: {
        overview: `On your second attempt, you continued to display strong interpersonal skills. You provided more structured examples of how you collaborate with cross-functional teams and how you adapt to new challenges.`,
        analysis: `Analysis highlights improved clarity in discussing conflict resolution and client focus, with slightly higher scores in these areas. Communication (94%) and Teamwork (92%) remain your top competencies. Keep refining your leadership narratives with more tangible outcomes and measurable successes.`,
      },
    },
    technical: {
      attempt1: {
        progressData: [
          {
            month: "May",
            computerFoundations: 85,
            mathFoundations: 70,
            dataFoundations: 75,
            softwareEngineer: 82,
            networkEngineer: 78,
            hardwareEngineer: 73,
            electricalEngineer: 68,
            aiResearch: 88,
            bioinfo: 72,
            quantTrader: 90,
            quantResearcher: 85,
          },
          {
            month: "June",
            computerFoundations: 87,
            mathFoundations: 72,
            dataFoundations: 77,
            softwareEngineer: 84,
            networkEngineer: 80,
            hardwareEngineer: 75,
            electricalEngineer: 70,
            aiResearch: 90,
            bioinfo: 74,
            quantTrader: 92,
            quantResearcher: 87,
          },
          {
            month: "July",
            computerFoundations: 89,
            mathFoundations: 75,
            dataFoundations: 79,
            softwareEngineer: 86,
            networkEngineer: 82,
            hardwareEngineer: 77,
            electricalEngineer: 72,
            aiResearch: 92,
            bioinfo: 76,
            quantTrader: 94,
            quantResearcher: 89,
          },
          {
            month: "Aug",
            computerFoundations: 91,
            mathFoundations: 77,
            dataFoundations: 81,
            softwareEngineer: 88,
            networkEngineer: 84,
            hardwareEngineer: 79,
            electricalEngineer: 74,
            aiResearch: 94,
            bioinfo: 78,
            quantTrader: 96,
            quantResearcher: 91,
          },
          {
            month: "Sept",
            computerFoundations: 93,
            mathFoundations: 79,
            dataFoundations: 83,
            softwareEngineer: 90,
            networkEngineer: 86,
            hardwareEngineer: 81,
            electricalEngineer: 76,
            aiResearch: 96,
            bioinfo: 80,
            quantTrader: 98,
            quantResearcher: 93,
          },
        ],
        attemptData: {
          score: 87,
          completion: "12/12 Questions",
          duration: 28,
        },
      },
      // Slightly varied data (added small noise to attempt1's data) for attempt2:
      attempt2: {
        progressData: [
          {
            month: "May",
            computerFoundations: 86,
            mathFoundations: 71,
            dataFoundations: 77,
            softwareEngineer: 84,
            networkEngineer: 78,
            hardwareEngineer: 75,
            electricalEngineer: 69,
            aiResearch: 89,
            bioinfo: 73,
            quantTrader: 91,
            quantResearcher: 86,
          },
          {
            month: "June",
            computerFoundations: 88,
            mathFoundations: 73,
            dataFoundations: 78,
            softwareEngineer: 85,
            networkEngineer: 81,
            hardwareEngineer: 76,
            electricalEngineer: 71,
            aiResearch: 91,
            bioinfo: 75,
            quantTrader: 93,
            quantResearcher: 88,
          },
          {
            month: "July",
            computerFoundations: 89,
            mathFoundations: 76,
            dataFoundations: 80,
            softwareEngineer: 87,
            networkEngineer: 83,
            hardwareEngineer: 78,
            electricalEngineer: 73,
            aiResearch: 93,
            bioinfo: 77,
            quantTrader: 95,
            quantResearcher: 89,
          },
          {
            month: "Aug",
            computerFoundations: 92,
            mathFoundations: 78,
            dataFoundations: 82,
            softwareEngineer: 88,
            networkEngineer: 85,
            hardwareEngineer: 80,
            electricalEngineer: 75,
            aiResearch: 95,
            bioinfo: 79,
            quantTrader: 97,
            quantResearcher: 92,
          },
          {
            month: "Sept",
            computerFoundations: 94,
            mathFoundations: 80,
            dataFoundations: 84,
            softwareEngineer: 91,
            networkEngineer: 86,
            hardwareEngineer: 82,
            electricalEngineer: 77,
            aiResearch: 97,
            bioinfo: 81,
            quantTrader: 99,
            quantResearcher: 94,
          },
        ],
        attemptData: {
          score: 90,
          completion: "12/12 Questions",
          duration: 26,
        },
      },
    },
  };
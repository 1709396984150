// src/routes/profile/Profile.tsx

import React, { useState, useEffect } from "react";
import axiosInterceptor from "../../utils/axiosInterceptor";
import ScreenSpinner from "../../components/ScreenSpinner";
import { transformAnswers, validateAnswers } from "./helpers";

// Sub‑components
import ProfileHeader from "./ProfileHeader";        // Merged personal info + avatar
import CareerProfile from "./CareerProfile";

// Types
import {
  PersonalityScores,
  PersonalInfo,
  Skill,
  EducationItem,
  ExperienceInfo,
  Course,
  ResumeItem,
  TranscriptItem,
} from "./interfaces";

interface FullUserData {
  u_data: PersonalInfo;
  ed_data: EducationItem[];
  exp_data: ExperienceInfo[];
  course_data: Course[];
  skill_data: Skill[];
  resume: ResumeItem[];
  transcript: TranscriptItem[];
}

const Profile: React.FC = () => {
  const userId = localStorage.getItem("user_id") || "";
  const [isLoading, setIsLoading] = useState(true);

  // Full data from the backend
  const [userData, setUserData] = useState<FullUserData | null>(null);

  // We also store the computed PersonalityScores
  const [personalityScores, setPersonalityScores] = useState<PersonalityScores>();

  useEffect(() => {
    if (!userId) {
      setIsLoading(false);
      return;
    }

    const fetchProfileData = async () => {
      try {
        // 1) Fetch everything from /getuser/:id
        const [allDataResponse, surveyResponse] = await Promise.all([
          axiosInterceptor.get<FullUserData>(`/getuser/${userId}/`),
          axiosInterceptor.get(`/save_or_update_user_surveys/${userId}/`),
        ]);

        const fullData = allDataResponse.data;
        setUserData(fullData);

        // 2) If there's a personality survey, parse & compute
        if (surveyResponse.data?.personality_survey) {
          try {
            // Some backends double-encode JSON
            const firstParse = JSON.parse(surveyResponse.data.personality_survey);
            const answersObj = JSON.parse(firstParse);

            const transformedAnswers = transformAnswers(answersObj);
            if (validateAnswers(transformedAnswers)) {
              const scoring = await axiosInterceptor.post("/personality-test/score/", {
                answers: transformedAnswers,
              });
              if (scoring.data?.success) {
                setPersonalityScores(scoring.data.scores);
              }
            }
          } catch (err) {
            console.error("Error parsing personality survey data:", err);
          }
        }
      } catch (err) {
        console.error("Error fetching user data:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfileData();
  }, [userId]);

  if (isLoading) return <ScreenSpinner />;
  if (!userData) return <div>No user data found.</div>;

  // 3) Build an “easy to display” object for the ProfileHeader
  const first = userData.u_data.firstname || "";
  const last = userData.u_data.lastname || "";
  const hasVisaVal = (() => {
    const val = userData.u_data.has_visa;
    if (typeof val === "boolean") return val ? "Yes" : "No";
    if (typeof val === "string") return val.toLowerCase() === "yes" ? "Yes" : "No";
    return "No";
  })();

  const headerProps = {
    name: `${first} ${last}`.trim(),
    phone: userData.u_data.phone_number || "",
    email: userData.u_data.email || "",
    address: [
      userData.u_data.street_addr,
      userData.u_data.city_addr,
      userData.u_data.state_addr,
      userData.u_data.zip_code,
      userData.u_data.country_addr,
    ]
      .filter(Boolean)
      .join(", "),
    birthday: userData.u_data.dob || "",
    visa: hasVisaVal,
    website: userData.u_data.website || "",
    linkedin: userData.u_data.linkedin || "",
    github: userData.u_data.github || "",
    countries:
      Array.isArray(userData.u_data.job_country) ? userData.u_data.job_country : [],
  };

  // 4) Render the layout
  return (
    <div className="p-4 space-y-4">
      {/* Profile Header at top */}
      <ProfileHeader
        {...headerProps}
        user_data={userData.u_data}
        userId={userId}
      />
       <CareerProfile />
    </div>
  );
};

export default Profile;
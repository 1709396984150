import { FC, useMemo } from "react";
import {
  Course,
  CourseCategoryCardProps,
  CoursePathsProps,
} from "../../types/interfaces";
import { getCourseLevels } from "./courseUtils";
import CourseCategoryCard from "./CourseCategoryCard";

const CoursePaths: FC<CoursePathsProps> = ({
  courses,
  isProgressDataLoaded,
  coursesProgress,
  selectedCourseTypes,
  handleNavigateToCourse,
  handleUpdateCourseProgress,
}) => {
  const [courseLevels] = useMemo(() => {
    return getCourseLevels(courses);
  }, [courses]);

  const sortedCourses = useMemo<Course[]>(() => {
    const sortedCourses = [...courses];
    sortedCourses.sort((courseA, courseB) => {
      const levelDifference =
        (courseLevels[courseA.courseNumber] ?? 0) -
        (courseLevels[courseB.courseNumber] ?? 0);
      if (levelDifference !== 0) return levelDifference;
      else {
        return courseA.courseNumber.localeCompare(courseB.courseNumber);
      }
    });
    return sortedCourses;
  }, [courseLevels, courses]);

  const courseCategoryCardProps: CourseCategoryCardProps[] = useMemo<
    CourseCategoryCardProps[]
  >(() => {
    const newCourseCategoryCardProps: CourseCategoryCardProps[] = Object.keys(
      selectedCourseTypes
    )
      .filter((category) => selectedCourseTypes[category])
      .map((category) => {
        const categoryCourses = sortedCourses.filter(
          (course) => course.courseType === category
        );
        const courseProgressMap: Record<string, number> = {};
        for (const courseProgress of coursesProgress) {
          if (
            categoryCourses.some(
              (course) => course.courseNumber === courseProgress.courseNumber
            )
          )
            courseProgressMap[courseProgress.courseNumber] =
              courseProgress.progress;
        }
        return {
          categoryName: category,
          courses: categoryCourses,
          isLoaded: isProgressDataLoaded,
          coursesProgress: courseProgressMap,
          handleNavigateToCourse: handleNavigateToCourse,
          handleUpdateCourseProgress: handleUpdateCourseProgress,
        };
      });
    return newCourseCategoryCardProps;
  }, [
    coursesProgress,
    handleNavigateToCourse,
    handleUpdateCourseProgress,
    isProgressDataLoaded,
    selectedCourseTypes,
    sortedCourses,
  ]);

  return (
    <div className="max-w-[1088px] flex flex-row items-center justify-center flex-wrap gap-x-12 gap-y-12 pb-12">
      {courseCategoryCardProps.map((courseCategoryCardProps, index) => (
        <CourseCategoryCard
          key={index}
          {...courseCategoryCardProps}
        ></CourseCategoryCard>
      ))}
    </div>
  );
};

export default CoursePaths;

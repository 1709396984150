// src/lib/skills.ts

import { Skill } from "../routes/profile/interfaces";

const possibleSkills: Skill[] = [
  { name: "3D Modeling" },
  { name: "3D Printing" },
  { name: "3D Rendering" },
  { name: "Agile" },
  { name: "Agile Methodologies" },
  { name: "Agile Project Management" },
  { name: "AI" },
  { name: "Amazon DynamoDB" },
  { name: "Amazon RDS" },
  { name: "Android Development" },
  { name: "Angular" },
  { name: "AngularJS" },
  { name: "Ansible" },
  { name: "Apache Kafka" },
  { name: "Appium" },
  { name: "Artificial Intelligence" },
  { name: "Asana" },
  { name: "ActiveRecord" },
  { name: "Backbone.js" },
  { name: "Babel" },
  { name: "Big Data Analytics" },
  { name: "Bitbucket" },
  { name: "Bitbucket Pipelines" },
  { name: "Blockchain" },
  { name: "Bugzilla" },
  { name: "Bower" },
  { name: "C" },
  { name: "C#" },
  { name: "C++" },
  { name: "CORS" },
  { name: "CVS" },
  { name: "Cassandra" },
  { name: "Chai" },
  { name: "Charles" },
  { name: "Chef" },
  { name: "Ci/CD" },
  { name: "CoffeeScript" },
  { name: "CodeIgniter" },
  { name: "Complex Analysis" },
  { name: "Computer Graphics" },
  { name: "Computer Vision" },
  { name: "Concurrency" },
  { name: "Confluence" },
  { name: "Containerization" },
  { name: "CouchDB" },
  { name: "Couchbase" },
  { name: "D3.js" },
  { name: "DB2" },
  { name: "DIP" },
  { name: "DSP" },
  { name: "Dart" },
  { name: "Data Analysis" },
  { name: "Data Engineering" },
  { name: "Data Mining" },
  { name: "Data Science" },
  { name: "Data Scrubbing" },
  { name: "Data Structures" },
  { name: "Database Management" },
  { name: "Deep Learning" },
  { name: "Design Patterns" },
  { name: "DevOps" },
  { name: "Distributed Systems" },
  { name: "Django" },
  { name: "Docker" },
  { name: "Express.js" },
  { name: "Embedded Systems" },
  { name: "Event-Driven Architecture" },
  { name: "Excel" },
  { name: "Flask" },
  { name: "Frontend Development" },
  { name: "Full Stack Development" },
  { name: "Functional Programming" },
  { name: "Gatsby" },
  { name: "Game Development" },
  { name: "Git" },
  { name: "GitLab" },
  { name: "GitHub" },
  { name: "GitKraken" },
  { name: "Golang" },
  { name: "Google Cloud Platform" },
  { name: "GraphQL" },
  { name: "Grafana" },
  { name: "HTML" },
  { name: "Hadoop" },
  { name: "Hibernate" },
  { name: "High Level Mathematics" },
  { name: "ICT" },
  { name: "IIS" },
  { name: "Illustration" },
  { name: "Illustrator" },
  { name: "Information Architecture" },
  { name: "Internet of Things" },
  { name: "IoT" },
  { name: "JIRA" },
  { name: "Jenkins" },
  { name: "Java" },
  { name: "Java Programming" },
  { name: "JavaScript" },
  { name: "Jest" },
  { name: "Jira" },
  { name: "JSON" },
  { name: "Jupyter Notebooks" },
  { name: "Kafka" },
  { name: "Kanban" },
  { name: "Keras" },
  { name: "Kotlin" },
  { name: "Knockout.js" },
  { name: "Linux System Administration" },
  { name: "Machine Learning" },
  { name: "MariaDB" },
  { name: "Matlab" },
  { name: "Microservices" },
  { name: "Microsoft Azure" },
  { name: "Mocha" },
  { name: "Mongoose" },
  { name: "MongoDB" },
  { name: "MySQL" },
  { name: "NLP" },
  { name: "Nagios" },
  { name: "NestJS" },
  { name: "Next.js" },
  { name: "Node.js" },
  { name: "Numpy" },
  { name: "Objective-C" },
  { name: "Object-Oriented Programming" },
  { name: "OAuth" },
  { name: "OpenID Connect" },
  { name: "Operating Systems" },
  { name: "OpenGL" },
  { name: "Oracle" },
  { name: "PHP" },
  { name: "Pandas" },
  { name: "Parallel Computing" },
  { name: "Performance Tuning" },
  { name: "Perl" },
  { name: "PostCSS" },
  { name: "PostgreSQL" },
  { name: "Postman" },
  { name: "Power BI" },
  { name: "PowerShell" },
  { name: "Puppet" },
  { name: "PyTorch" },
  { name: "Python" },
  { name: "Qt" },
  { name: "Quarkus" },
  { name: "React.js" },
  { name: "React Native" },
  { name: "Redis" },
  { name: "Redux" },
  { name: "REST" },
  { name: "RESTful API Design" },
  { name: "RESTful Design Principles" },
  { name: "R" },
  { name: "RabbitMQ" },
  { name: "Ruby" },
  { name: "Ruby on Rails" },
  { name: "Rust" },
  { name: "SAML" },
  { name: "Sass" },
  { name: "Scala" },
  { name: "Scikit-Learn" },
  { name: "Selenium" },
  { name: "Security Engineering" },
  { name: "Sequelize" },
  { name: "Serverless Architecture" },
  { name: "Shell Scripting" },
  { name: "Splunk" },
  { name: "Spring Boot" },
  { name: "Spring Framework" },
  { name: "SQL" },
  { name: "Swift" },
  { name: "Svelte" },
  { name: "Selenium" },
  { name: "Service-Oriented Architecture (SOA)" },
  { name: "Selenium" },
  { name: "Symfony" },
  { name: "Svelte" },
  { name: "Swagger" },
  { name: "System Design" },
  { name: "TDD" },
  { name: "TCP/IP" },
  { name: "Terraform" },
  { name: "Test Driven Development (TDD)" },
  { name: "TestLink" },
  { name: "TestNG" },
  { name: "TestRail" },
  { name: "TensorFlow" },
  { name: "TypeORM" },
  { name: "TypeScript" },
  { name: "UI/UX Design" },
  { name: "Unity" },
  { name: "Vagrant" },
  { name: "Version Control" },
  { name: "Vue.js" },
  { name: "Web Assembly" },
  { name: "Web Development" },
  { name: "Web Security" },
  { name: "Webpack" },
  { name: "Windows Server Administration" },
  { name: "WordPress" },
  { name: "XML" },
  { name: "Xamarin" },
  { name: "Yii" },
  { name: "ZeroMQ" },
  { name: "gRPC" },
  { name: "golang" },
  { name: "iOS Development" },
  { name: "jQuery" },
];

export default possibleSkills;

import React, { useState } from "react";
import Modal from "../../../components/Modal";
import { Button } from "react-bootstrap";
import { CareerCardProps } from "../interfaces";
import { Pencil } from "lucide-react";

const CareerCard: React.FC<CareerCardProps> = ({
  title,
  children,
  showAddButton = true,
  showEditAsModal = true,
  showEditButton = true,
  modalTitle = title,
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <div className="bg-white rounded-2xl border border-gray-200 overflow-hidden">
      <div className="px-4 py-4">
        <div className="flex flex-row justify-between items-center">
          <h4 className="text-lg font-semibold text-gray-900">{title}</h4>
          <div className="flex flex-row gap-x-2">
            {showAddButton && (
              <>
                <Button
                  variant="light"
                  className="flex items-center gap-2 text-gray-700 hover:bg-gray-50"
                  onClick={() => setShowAddModal(true)}
                >
                  <i className="bi bi-plus-lg"></i>
                  Add
                </Button>
                <Modal title={title} show={showAddModal} setShow={setShowAddModal}>
                  {showEditButton ? children[2] : children[1]}
                </Modal>
              </>
            )}
            {showEditButton && (
              <button
                onClick={() => setShowEditModal(true)}
                className="w-8 h-8 flex items-center justify-center text-gray-400 hover:text-gray-600 rounded-full"
              >
                <Pencil className="w-5 h-5" />
              </button>
            )}
            {showEditAsModal && (
              <Modal title={modalTitle} show={showEditModal} setShow={setShowEditModal}>
                {children[1]}
              </Modal>
            )}
          </div>
        </div>
      </div>
      <div className="px-4 pb-4">
        {children[0]}
        {!showEditAsModal && showEditModal && children[1]}
      </div>
    </div>
  );
};

export default CareerCard;
// import React, { useState } from "react";
// import Modal from "../../../components/Modal";
// import { Button } from "react-bootstrap";
// import { CareerCardProps } from "../interfaces";
// import { Pencil } from "lucide-react";

// const CareerCard: React.FC<CareerCardProps> = ({
//   title,
//   children,
//   showAddButton = true,
//   showEditAsModal = true,
//   showEditButton = true,
//   modalTitle = title,
// }) => {
//   const [showAddModal, setShowAddModal] = useState(false);
//   const [showEditModal, setShowEditModal] = useState(false);

//   return (
//     <div className="w-full p-4 rounded-lg bg-white flex flex-col gap-y-4">
//       <div className="flex flex-row justify-between items-center">
//         <h4 className="text-lg font-semibold">{title}</h4>
//         <div className="flex flex-row gap-x-2">
//           {showAddButton && (
//             <>
//               <Button
//                 variant="light"
//                 className="ml-auto"
//                 onClick={() => setShowAddModal(true)}
//               >
//                 <i className="mr-2 bi bi-plus-lg"></i>
//                 Add
//               </Button>
//               <Modal title={title} show={showAddModal} setShow={setShowAddModal}>
//                 {showEditButton ? children[2] : children[1]}
//               </Modal>
//             </>
//           )}
//           {showEditButton && (
//             <button
//               onClick={() => setShowEditModal(true)}
//               className="w-8 h-8 flex items-center justify-center text-gray-400 hover:text-gray-600 rounded-full"
//             >
//               <Pencil className="w-5 h-5" />
//             </button>
//           )}
//           {showEditAsModal && (
//             <Modal title={modalTitle} show={showEditModal} setShow={setShowEditModal}>
//               {children[1]}
//             </Modal>
//           )}
//         </div>
//       </div>
//       {children[0]}
//       {!showEditAsModal && showEditModal && children[1]}
//     </div>
//   );
// };

// export default CareerCard;

// components/TopNavigation.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { TopNavigationProps } from './interfaces';
import NavigationControl from './NavigationControl';
import LogoIcon from "../../assets/img/logos/white-logo-icon.png";
import SwapIcon from "../../assets/img/icons/swap-icon.png";

const TopNavigation: React.FC<TopNavigationProps> = ({ isUserLoggedIn = false }) => {
  return (
    <nav className="fixed top-0 left-0 right-0 z-50 w-full px-8 py-4 flex items-center justify-between bg-[#0E172A]/95 backdrop-blur-sm border-b border-white/5">
      {/* Logo and Navigation Section */}
      <div className="flex items-center flex-1">
        <Link to="/" className="flex items-center no-underline">
          <img 
            src={LogoIcon}
            alt="AlgoLink" 
            className="h-16 w-16"
          />
        </Link>
        
        <div className="flex-1 flex justify-center">
          <NavigationControl />
        </div>
      </div>

      {/* Authentication Buttons */}
      <div className="flex items-center space-x-4">
        <Link 
          to="/account/login" 
          className="no-underline px-6 py-2 border border-white/10 rounded-lg text-white hover:bg-white/5 transition-all duration-200"
        >
          Log In
        </Link>
        <Link 
          to="account/signup" 
          className="no-underline px-6 py-2 bg-[#2563EB] hover:bg-[#1D4ED8] text-white rounded-lg transition-all duration-200"
        >
          Sign Up
        </Link>
      </div>
    </nav>
  );
};

export default TopNavigation;

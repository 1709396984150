import { MoreVertical } from 'lucide-react';
import React, { useState } from "react";
import SegmentedControl from "../../components/SegmentedControl";
import { Dropdown } from "react-bootstrap";
import { DropdownToggle } from "../../components/DropdownToggle";

// Types for notification sources (pages)
type NotificationSource =
  | "Profile"
  | "Tests"
  | "Courses"
  | "Jobs"
  | "Messages";

// Define which notification types belong to each category
type LearningNotificationType = "test_result" | "course_completion";
type RecruitmentNotificationType =
  | "referrer_like"
  | "referrer_message"
  | "interview_scheduled"
  | "job_offer";
type GeneralNotificationType = "system_update" | "announcement" | "maintenance";

type NotificationType =
  | LearningNotificationType
  | RecruitmentNotificationType
  | GeneralNotificationType;

// Base notification properties; note that we removed the subtitle property.
type BaseNotification = {
  id: string;
  title: string;
  content: string;
  timestamp: string;
  read: boolean;
  source: NotificationSource;
};

// Category-specific notification types
type LearningNotification = BaseNotification & {
  type: LearningNotificationType;
  category: "learning";
  metadata?: {
    courseName?: string;
    testName?: string;
    score?: number;
  };
};

type RecruitmentNotification = BaseNotification & {
  type: RecruitmentNotificationType;
  category: "jobs";
  metadata?: {
    companyName?: string;
    referrerName?: string;
    interviewDate?: string;
  };
};

type GeneralNotification = BaseNotification & {
  type: GeneralNotificationType;
  category: "general";
  metadata?: {
    priority?: "low" | "medium" | "high";
    link?: string;
  };
};

type Notification =
  | LearningNotification
  | RecruitmentNotification
  | GeneralNotification;

// Mock notifications
const MOCK_NOTIFICATIONS: Notification[] = [
  {
    id: "1",
    type: "test_result",
    category: "learning",
    source: "Tests",
    title: "AlgoLink",
    content: "Your Data Structures & Algorithms assessment has been processed.",
    timestamp: "2 min ago",
    read: false,
    metadata: {
      testName: "Data Structures & Algorithms",
      score: 85,
    },
  },
  {
    id: "2",
    type: "course_completion",
    category: "learning",
    source: "Courses",
    title: "Course Path Completed",
    content:
      "Congratulations! You've completed the Machine Learning Fundamentals path.",
    timestamp: "1 hour ago",
    read: true,
    metadata: {
      courseName: "Machine Learning Fundamentals",
    },
  },
  {
    id: "3",
    type: "referrer_like",
    category: "jobs",
    source: "Jobs",
    title: "Apple",
    content: "Your application has been viewed by the hiring team.",
    timestamp: "1 hour ago",
    read: true,
    metadata: {
      companyName: "Apple",
    },
  },
];

// Remove Recruitment from the available sources for filtering
const AVAILABLE_SOURCES: NotificationSource[] = [
  "Profile",
  "Tests",
  "Courses",
  "Jobs",
  "Messages",
];

interface NotificationCardProps {
  notification: Notification;
  onToggleRead: (id: string) => void;
}

const NotificationCard: React.FC<NotificationCardProps> = ({ notification, onToggleRead }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div className={`px-6 py-4 ${!notification.read ? 'bg-blue-100' : 'bg-white'}`}>
      <div className="flex items-start justify-between gap-4">
        <div className="flex-grow min-w-0">
          <div className="flex items-start justify-between">
            <div>
              <h3 className="font-medium text-gray-900">
                {notification.title}
              </h3>
              {/* Always show the source here */}
              <p className="text-sm text-gray-500">{notification.source}</p>
            </div>
            <div className="relative">
              <button 
                className="p-1 hover:bg-gray-100 rounded"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDropdown(!showDropdown);
                }}
              >
                <MoreVertical className="h-4 w-4 text-gray-400" />
              </button>
              
              {showDropdown && (
                <>
                  <div 
                    className="fixed inset-0 z-10"
                    onClick={() => setShowDropdown(false)}
                  />
                  <div className="absolute right-0 mt-1 w-48 bg-white rounded-md shadow-lg border border-gray-200 z-20">
                    <button
                      className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-50"
                      onClick={(e) => {
                        e.stopPropagation();
                        onToggleRead(notification.id);
                        setShowDropdown(false);
                      }}
                    >
                      Mark as {notification.read ? 'unread' : 'read'}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          <p className="mt-1 text-sm text-gray-600">{notification.content}</p>
          <div className="mt-1 text-sm text-gray-400">
            {notification.timestamp}
          </div>
        </div>
      </div>
    </div>
  );
};

const NotificationsPage = () => {
  const [selectedTab, setSelectedTab] = useState<"all" | "unread">("all");
  const [selectedSource, setSelectedSource] = useState<NotificationSource | "all">("all");
  const [notifications, setNotifications] = useState(MOCK_NOTIFICATIONS);

  const toggleReadStatus = (id: string) => {
    setNotifications((current) =>
      current.map((notification) =>
        notification.id === id
          ? { ...notification, read: !notification.read }
          : notification
      )
    );
  };

  // Combine the All/Unread filter with the Source filter
  const filteredNotifications = notifications.filter((notification) => {
    const matchesTab =
      selectedTab === "all" || (selectedTab === "unread" && !notification.read);
    const matchesSource =
      selectedSource === "all" || notification.source === selectedSource;

    return matchesTab && matchesSource;
  });

  return (
    <div className="w-full h-full bg-[#F9F9F9]">
      {/* Top Controls */}
      <div className="border-b border-gray-200">
        <div className="px-6 py-4">
          <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
            {/* Segmented Control for All / Unread */}
            <SegmentedControl
              controlItems={["All", "Unread"]}
              handleValueSelected={(value) =>
                setSelectedTab(value.toLowerCase() as "all" | "unread")
              }
            />
            {/* Dropdown filter styled like the course categories dropdown */}
            <Dropdown>
              <Dropdown.Toggle 
                as={DropdownToggle} 
                message={`${selectedSource === "all" ? "All" : selectedSource}`}
              />
              <Dropdown.Menu className="mt-2 rounded-lg shadow-sm cursor-pointer !text-base !font-normal">
                <Dropdown.Item
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedSource("all");
                  }}
                  className="active:!bg-transparent focus:!bg-transparent active:!text-black focus:!text-black"
                >
                  All
                </Dropdown.Item>
                {AVAILABLE_SOURCES.map((source) => (
                  <Dropdown.Item
                    key={source}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedSource(source);
                    }}
                    className="active:!bg-transparent focus:!bg-transparent active:!text-black focus:!text-black"
                  >
                    {source}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>

      {/* Notification List */}
      <div className="divide-y divide-gray-200">
        {filteredNotifications.map((notification) => (
          <NotificationCard
            key={notification.id}
            notification={notification}
            onToggleRead={toggleReadStatus}
          />
        ))}

        {filteredNotifications.length === 0 && (
          <div className="px-6 py-8 text-center text-gray-500">
            No notifications to display
          </div>
        )}
      </div>

      {/* Footer */}
      {filteredNotifications.length > 0 && (
        <div className="px-6 py-4 border-t border-gray-200">
          <div className="text-sm text-gray-500">
            Showing {filteredNotifications.length} notifications
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationsPage;
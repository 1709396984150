/**
 * MergedCompanyJobsSection.tsx
 * Display jobs in categories (tabs), each rendered by our new styling approach.
 */

import React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Clock, MapPin, Award, FileText, DollarSign } from "lucide-react";

import { MergedCompanyJobsSectionProps, Job, JobCategory } from "./interfaces";
import JobCard from "./JobCard";

const MergedCompanyJobsSection: React.FC<MergedCompanyJobsSectionProps> = ({
  categories,
  jobs,
  onRateJob,
}) => {
  const [activeTab, setActiveTab] = useState<JobCategory>(categories[0]);
  const [expandedDescriptions, setExpandedDescriptions] = useState<Set<number>>(
    new Set()
  );

  const handleToggleExpand = (jobId: number) => {
    setExpandedDescriptions((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(jobId)) {
        newSet.delete(jobId);
      } else {
        newSet.add(jobId);
      }
      return newSet;
    });
  };

  /**
   * Determine a job's unified category (pipeline status + rating).
   */
  const getUnifiedCategory = (job: Job): JobCategory => {
    // pipeline-based
    switch (job.user_pipeline_status) {
      case "New Matches":
        return "New Matches";
      case "Messages":
        return "Messages";
      case "Referral":
        return "Referral";
      case "Accepted":
        return "Offer";
      case "Pending Invitations":
        return "Likes You";
      default:
        break;
    }
    // rating-based fallback
    switch (job.user_rating_of_job) {
      case 1:
        return "Not Interested";
      case 3:
        return "Interested";
      case 5:
        return "Very Interested";
      default:
        return "Recommended";
    }
  };

  // Group jobs by category
  const categorizedJobs: Record<JobCategory, Job[]> = {
    "New Matches": [],
    Recommended: [],
    "Likes You": [],
    Messages: [],
    Referral: [],
    "Not Interested": [],
    Interested: [],
    "Very Interested": [],
    Offer: [],
  };

  jobs.forEach((job) => {
    const cat = getUnifiedCategory(job);
    categorizedJobs[cat].push(job);
  });

  // For pipeline-based statuses, we lock out rating changes
  const pipelineCategories: JobCategory[] = [
    "New Matches",
    "Messages",
    "Referral",
    "Offer",
  ];
  const isPipelineStage = (category: JobCategory) =>
    pipelineCategories.includes(category);

  // Label & variant for the rating button
  const getRatingDetails = (job: Job) => {
    if (job.user_rating_of_job === 1) {
      return { label: "Not Interested", variant: "danger" };
    }
    if (job.user_rating_of_job === 3) {
      return { label: "Interested", variant: "primary" };
    }
    if (job.user_rating_of_job === 5) {
      return { label: "Very Interested", variant: "success" };
    }
    return { label: "Rate This Job", variant: "outline-secondary" };
  };

  return (
    <div className="flex flex-col">
      {/* Tabs Navigation */}
      <div className="border-b border-gray-200 flex overflow-x-auto">
        {categories.map((category) => {
          const count = categorizedJobs[category].length;
          const isActive = activeTab === category;
          return (
            <button
              key={category}
              onClick={() => setActiveTab(category)}
              className={`relative flex-1 text-center py-3 text-sm font-medium transition-colors ${
                isActive
                  ? "text-blue-600"
                  : "text-gray-500 hover:text-gray-700"
              }`}
            >
              <span className="flex items-center justify-center gap-2">
                {category}
                <span
                  className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs ${
                    isActive
                      ? "bg-blue-50 text-blue-600"
                      : "bg-gray-100 text-gray-600"
                  }`}
                >
                  {count}
                </span>
              </span>
              {isActive && (
                <div className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-600" />
              )}
            </button>
          );
        })}
      </div>

      {/* Jobs List */}
      <div className="divide-y divide-gray-100">
        {categorizedJobs[activeTab].length === 0 && (
          <div className="p-4 text-center text-sm text-gray-500">
            No jobs found in this category.
          </div>
        )}

        {categorizedJobs[activeTab].map((job) => {
          const { label, variant } = getRatingDetails(job);
          const locked = isPipelineStage(activeTab);

          return (
            <div
              key={job.id}
              className="bg-white px-4 py-4 transition-colors hover:bg-gray-50"
            >
              {/* Card Top: Title + Compensation Pills + Rating dropdown on the right */}
              <div className="flex justify-between items-start mb-3">
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">
                    {job.title}
                  </h3>
                  <div className="mt-2 flex gap-2 flex-wrap">
                    {/* Salary Pill */}
                    <span className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-blue-50 text-blue-600">
                      ${Math.round(job.compensationLowerBound / 1000)}k – $
                      {Math.round(job.compensationUpperBound / 1000)}k
                    </span>
                    {/* Visa Sponsorship Pill if applicable */}
                    {job.eligible_for_visa_sponsorship && (
                      <span className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-blue-50 text-blue-600">
                        Visa Sponsorship
                      </span>
                    )}
                  </div>
                </div>

                {/* Rating Dropdown or locked */}
                <DropdownButton
                  id={`rating-dropdown-${job.id}`}
                  title={label}
                  variant={variant}
                  size="sm"
                  className="shrink-0"
                  disabled={locked}
                >
                  <Dropdown.Item onClick={() => onRateJob(job.id, 1)}>
                    Not Interested
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => onRateJob(job.id, 3)}>
                    Interested
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => onRateJob(job.id, 5)}>
                    Very Interested
                  </Dropdown.Item>
                </DropdownButton>
              </div>

              {/* We can reuse the JobCard approach OR just show a 2x2 grid here */}
              {/* For demonstration, let's do a smaller snippet + "expanded" card as well */}
              {/* If you prefer the separate display in JobCard, you can replace the entire block with <JobCard /> usage. */}

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Timeline & Location */}
                <div className="flex flex-col gap-4">
                  {/* Timeline */}
                  <div>
                    <div className="flex items-center gap-1 mb-1">
                      <Clock className="w-4 h-4 text-blue-600" />
                      <h4 className="text-sm font-semibold text-gray-900">
                        Timeline
                      </h4>
                    </div>
                    <div className="pl-5 text-sm text-gray-700">
                      <p>Interview Start: {job.interviewingStartDate || "N/A"}</p>
                      <p>Job Start: {job.jobStartDate || "N/A"}</p>
                    </div>
                  </div>

                  {/* Location */}
                  <div>
                    <div className="flex items-center gap-1 mb-1">
                      <MapPin className="w-4 h-4 text-blue-600" />
                      <h4 className="text-sm font-semibold text-gray-900">
                        Location
                      </h4>
                    </div>
                    <div className="pl-5 text-sm text-gray-700">
                      {job.city || "Work from home"}
                    </div>
                  </div>
                </div>

                {/* Benefits & Description */}
                <div className="flex flex-col gap-4">
                  {/* Benefits */}
                  <div>
                    <div className="flex items-center gap-1 mb-1">
                      <Award className="w-4 h-4 text-blue-600" />
                      <h4 className="text-sm font-semibold text-gray-900">
                        Benefits
                      </h4>
                    </div>
                    <div className="pl-5 text-sm text-gray-700">
                      {job.benefits || "N/A"}
                    </div>
                  </div>

                  {/* Description */}
                  <div>
                    <div className="flex items-center gap-1 mb-1">
                      <FileText className="w-4 h-4 text-blue-600" />
                      <h4 className="text-sm font-semibold text-gray-900">
                        Description
                      </h4>
                    </div>
                    <div className="pl-5 text-sm text-gray-700 whitespace-pre-line">
                      {job.description || "No description provided."}
                    </div>
                  </div>
                </div>
              </div>

              {/* Footer row */}
              <div className="mt-4 flex flex-wrap items-center justify-between text-xs text-gray-500">
                <div>Posted {new Date(job.timestamp).toLocaleDateString()}</div>
                <div className="flex items-center gap-2">
                  <span className="inline-flex items-center px-2 py-0.5 rounded-full bg-gray-100 text-gray-600">
                    {job.num_users || 0} Applicants
                  </span>
                  <span className="inline-flex items-center px-2 py-0.5 rounded-full bg-gray-100 text-gray-600">
                    {job.status.charAt(0).toUpperCase() + job.status.slice(1)}
                  </span>
                  {job.user_pipeline_status && locked && (
                    <span className="inline-flex items-center px-2 py-0.5 rounded-full bg-blue-50 text-blue-600">
                      {job.user_pipeline_status}
                    </span>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MergedCompanyJobsSection;
import React, { useEffect, useState } from "react";
import axiosInterceptor from "../../utils/axiosInterceptor";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import TestCard from "./TestCard";
import { TestAttemptInfo, TestOption, CustomTooltipProps, TestType, AttemptType } from "./interfaces";
import { educationalLevels, colors, testResults } from "./constants";
import { getEducationalLevel, formatKeyToLabel, getResultContent } from "./helpers";

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white border border-gray-200 p-4 rounded shadow-lg">
        <p className="font-semibold mb-2">{`Month: ${label}`}</p>
        {payload.map((entry) => (
          <div key={entry.dataKey} className="flex items-center mb-1">
            <span
              className="w-2 h-2 rounded-full mr-2"
              style={{ backgroundColor: entry.color }}
            />
            <span className="text-sm">
              {`${entry.name}: ${getEducationalLevel(entry.value)}`}
            </span>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

const renderBlueOutlineBar = (value: number) => {
  return (
    <div className="h-8 rounded-full border-4 border-[#0059EC] overflow-hidden relative">
      <div className="w-full h-full bg-white" />
      <div
        className="absolute top-0 left-0 h-full bg-[#0059EC] transition-all duration-500 ease-in-out rounded-full"
        style={{ width: `${value}%` }}
      />
    </div>
  );
};

const TestCenter: React.FC = () => {
  const userID = localStorage.getItem("user_id");
  const [selectedTest, setSelectedTest] = useState<TestType>("background");
  const [selectedAttempt, setSelectedAttempt] = useState<AttemptType>("attempt1");
  const [testData, setTestData] = useState<TestAttemptInfo | null>(null);

  useEffect(() => {
    const fetchTestResults = async () => {
      const response = await axiosInterceptor.get<TestAttemptInfo>(
        `/api/get_test_results/${userID}/`,
        {
          params: {
            test_type: selectedTest,
            attempt_number: selectedAttempt.replace('attempt', '')
          }
        }
      );

      setTestData(response.data);
    };

    fetchTestResults();
  }, [selectedTest, selectedAttempt, userID]);

  const testOptions: TestOption[] = [
    {
      title: "Background",
      duration: "~30 mins",
      description:
        "Sharpen your responses to questions about your education, work experience, and personal projects in our Background interview simulation. This section helps you articulate your professional journey and highlight key achievements to make a strong first impression.",
      route: "/test-center/background",
    },
    {
      title: "Behavioral",
      duration: "~30 mins",
      description:
        "Prepare for common behavioral interview questions in our Behavioral interview simulation. Practice answering questions that assess your teamwork, leadership, and problem solving abilities, ensuring you can effectively demonstrate your soft skills and past experiences.",
      route: "/test-center/behavioral",
    },
    {
      title: "Technical",
      duration: "~60 mins",
      description:
        "Hone your technical interview skills in our Technical interview simulation. This section offers practice questions in math, finance, coding, and problem-solving to help you excel in technical assessments and demonstrate your expertise in your field.",
      route: "/test-center/technical-home",
    },
  ];

  const renderResults = () => {
    const duration = testData?.duration ?? 0;
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    if (selectedTest === "technical") {
      return (
        <div className="flex flex-row gap-8 px-4 py-4">
          {/* Left section: Score, Completion, Duration */}
          <div className="flex-1 space-y-8">
            {/* Score */}
            <div>
              <div className="flex justify-between items-center mb-3">
                <h3 className="text-gray-700 font-medium">Score</h3>
                <span className="text-lg font-semibold text-gray-900">
                  {testData?.score}%
                </span>
              </div>
              {renderBlueOutlineBar(testData?.score || 0)}
            </div>
            {/* Completion */}
            <div>
              <div className="flex justify-between items-center mb-3">
                <h3 className="text-gray-700 font-medium">Completion</h3>
                <span className="text-lg font-semibold text-gray-900">
                  {testData?.completion}
                </span>
              </div>
              {renderBlueOutlineBar(100)}
            </div>
            {/* Duration */}
            <div>
              <div className="flex justify-between items-center mb-3">
                <h3 className="text-gray-700 font-medium">Duration</h3>
                <span className="text-lg font-semibold text-gray-900">
                  {minutes}m {seconds}s
                </span>
              </div>
              {renderBlueOutlineBar(75)}
            </div>
          </div>
          {/* Right section: Chart */}
          <div className="flex-1">
            <div className="h-96">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={testResults.technical[selectedAttempt].progressData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                >
                  <XAxis
                    dataKey="month"
                    tick={{ fontSize: 12, fill: "#4B5563" }}
                    axisLine={{ stroke: "#E5E7EB" }}
                    tickLine={{ stroke: "#E5E7EB" }}
                  />
                  <YAxis
                    ticks={educationalLevels.map((level) => level.value)}
                    tickFormatter={(tick) => {
                      const level = educationalLevels.find((l) => l.value === tick);
                      return level ? level.label : "";
                    }}
                    domain={[0, 100]}
                    tick={{ fontSize: 12, fill: "#4B5563" }}
                    axisLine={{ stroke: "#E5E7EB" }}
                    tickLine={{ stroke: "#E5E7EB" }}
                  />
                  <Tooltip content={<CustomTooltip />} cursor={{ stroke: "#E5E7EB" }} />
                  {Object.keys(colors).map((key) => (
                    <Line
                      key={key}
                      type="monotone"
                      dataKey={key}
                      stroke={colors[key]}
                      strokeWidth={2}
                      dot={{ fill: colors[key], r: 3, strokeWidth: 2 }}
                      activeDot={{ r: 6, strokeWidth: 2 }}
                      name={formatKeyToLabel(key)}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      );
    } else {
      // For background or behavioral, show text results (Overview & Analysis)
      const content = testResults[selectedTest as "background" | "behavioral"][selectedAttempt];
      return (
        <div className="flex flex-row gap-6 px-4 py-4">
          <div className="flex-1">
            <div className="bg-white rounded-2xl border border-gray-200 overflow-hidden">
              <div className="px-4 py-4">
                <h3 className="text-lg font-semibold text-gray-900">Overview</h3>
                <p className="text-sm text-gray-600 mt-3 leading-relaxed">
                  {content.overview}
                </p>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="bg-white rounded-2xl border border-gray-200 overflow-hidden">
              <div className="px-4 py-4">
                <h3 className="text-lg font-semibold text-gray-900">Detailed Analysis</h3>
                <p className="text-sm text-gray-600 mt-3 leading-relaxed">
                  {content.analysis}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="min-h-screen bg-[#F9F9F9] p-4">
      <div className="max-w-7xl mx-auto">
        {/* Test Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
          {testOptions.map((test) => (
            <TestCard key={test.title} test={test} />
          ))}
        </div>

        {/* Results Section */}
        <div className="bg-white rounded-2xl border border-gray-200 overflow-hidden">
          <div className="px-4 py-4 border-b border-gray-200">
            <div className="flex flex-col md:flex-row md:justify-between md:items-center">
              <h2 className="text-lg font-semibold text-gray-900">Test Results</h2>
              <div className="flex items-center gap-2">
                <select
                  value={selectedTest}
                  onChange={(e) => setSelectedTest(e.target.value as TestType)}
                  className="border rounded px-3 py-1.5 bg-white text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="background">Background</option>
                  <option value="behavioral">Behavioral</option>
                  <option value="technical">Technical</option>
                </select>
                <select
                  value={selectedAttempt}
                  onChange={(e) => setSelectedAttempt(e.target.value as AttemptType)}
                  className="border rounded px-3 py-1.5 bg-white text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="attempt1">Attempt 1</option>
                  <option value="attempt2">Attempt 2</option>
                </select>
              </div>
            </div>
          </div>
          {renderResults()}
        </div>
      </div>
    </div>
  );
};

export default TestCenter;
// import React, { useEffect, useState } from "react";
// import axiosInterceptor from "../../utils/axiosInterceptor";
// import {
//   LineChart,
//   Line,
//   XAxis,
//   YAxis,
//   ResponsiveContainer,
//   Tooltip,
// } from "recharts";
// import TestCard from "./TestCard";
// import { TestAttemptInfo, TestOption, CustomTooltipProps, TestType, AttemptType } from "./interfaces";
// import { educationalLevels, colors, testResults } from "./constants";
// import { getEducationalLevel, formatKeyToLabel, getResultContent } from "./helpers";

// const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
//   if (active && payload && payload.length) {
//     return (
//       <div className="bg-white border border-gray-200 p-4 rounded shadow-lg">
//         <p className="font-semibold mb-2">{`Month: ${label}`}</p>
//         {payload.map((entry) => (
//           <div key={entry.dataKey} className="flex items-center mb-1">
//             <span
//               className="w-2 h-2 rounded-full mr-2"
//               style={{ backgroundColor: entry.color }}
//             />
//             <span className="text-sm">
//               {`${entry.name}: ${getEducationalLevel(entry.value)}`}
//             </span>
//           </div>
//         ))}
//       </div>
//     );
//   }
//   return null;
// };

// // ===================================
// // Render a custom progress bar
// // ===================================
// const renderBlueOutlineBar = (value: number) => {
//   return (
//     <div className="h-8 rounded-full border-4 border-[#0059EC] overflow-hidden relative">
//       {/* White background */}
//       <div className="w-full h-full bg-white" />
//       {/* Blue overlay representing filled portion */}
//       <div
//         className="absolute top-0 left-0 h-full bg-[#0059EC] transition-all duration-500 ease-in-out rounded-full"
//         style={{ width: `${value}%` }}
//       />
//     </div>
//   );
// };

// // ===================================
// // Main Component
// // ===================================
// const TestCenter: React.FC = () => {
//   const userID = localStorage.getItem("user_id");
//   // Which test? (background, behavioral, technical)
//   const [selectedTest, setSelectedTest] = useState<TestType>("background");
//   // Which attempt? (attempt1, attempt2)
//   const [selectedAttempt, setSelectedAttempt] = useState<AttemptType>("attempt1");
//   const [testData, setTestData] = useState<TestAttemptInfo | null>(null);

//   useEffect(() => {
//     const fetchTestResults = async () => {
//       const response = await axiosInterceptor.get<TestAttemptInfo>(
//         `/api/get_test_results/${userID}/`,
//         {
//           params: {
//             test_type: selectedTest,
//             attempt_number: selectedAttempt.replace('attempt', '')
//           }
//         }
//       );

//       setTestData(response.data);
//     };

//     fetchTestResults();
//   }, [selectedTest, selectedAttempt, userID]);

//   // ===================================
//   // Test Options (cards at the top)
//   // ===================================
//   const testOptions: TestOption[] = [
//     {
//       title: "Background",
//       duration: "~30 mins",
//       description:
//         "Sharpen your responses to questions about your education, work experience, and personal projects in our Background interview simulation. This section helps you articulate your professional journey and highlight key achievements to make a strong first impression.",
//       route: "/test-center/background",
//     },
//     {
//       title: "Behavioral",
//       duration: "~30 mins",
//       description:
//         "Prepare for common behavioral interview questions in our Behavioral interview simulation. Practice answering questions that assess your teamwork, leadership, and problem solving abilities, ensuring you can effectively demonstrate your soft skills and past experiences.",
//       route: "/test-center/behavioral",
//     },
//     {
//       title: "Technical",
//       duration: "~60 mins",
//       description:
//         "Hone your technical interview skills in our Technical interview simulation. This section offers practice questions in math, finance, coding, and problem-solving to help you excel in technical assessments and demonstrate your expertise in your field.",
//       route: "/test-center/technical-home",
//     },
//   ];

//   // ===================================
//   // Conditionally render the Results
//   // ===================================
//   const renderResults = () => {
//     const duration = testData?.duration ?? 0;
//     const minutes = Math.floor(duration / 60);
//     const seconds = duration % 60;
//     if (selectedTest === "technical") {
//       return (
//         <div className="flex flex-row gap-8 p-6">
//           {/* Left section: Score, Completion, Duration */}
//           <div className="flex-1 space-y-8">
//             {/* Score */}
//             <div>
//               <div className="flex justify-between items-center mb-3">
//                 <h3 className="text-gray-700 font-medium">Score</h3>
//                 <span className="text-lg font-semibold text-gray-900">
//                   {testData?.score}%
//                 </span>
//               </div>
//               {renderBlueOutlineBar(testData?.score || 0)}
//             </div>
//             {/* Completion */}
//             <div>
//               <div className="flex justify-between items-center mb-3">
//                 <h3 className="text-gray-700 font-medium">Completion</h3>
//                 <span className="text-lg font-semibold text-gray-900">
//                   {testData?.completion}
//                 </span>
//               </div>
//               {renderBlueOutlineBar(100)}
//             </div>
//             {/* Duration */}
//             <div>
//               <div className="flex justify-between items-center mb-3">
//                 <h3 className="text-gray-700 font-medium">Duration</h3>
//                 <span className="text-lg font-semibold text-gray-900">
//                   {minutes}m {seconds}s
//                 </span>
//               </div>
//               {renderBlueOutlineBar(75)}
//             </div>
//           </div>
//           {/* Right section: Chart */}
//           <div className="flex-1">
//             <div className="h-96">
//              <ResponsiveContainer width="100%" height="100%">
//                 <LineChart
//                   data={testResults.technical.attempt1.progressData}
//                   margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
//                 >
//                   <XAxis
//                     dataKey="month"
//                     tick={{ fontSize: 12, fill: "#4B5563" }}
//                     axisLine={{ stroke: "#E5E7EB" }}
//                     tickLine={{ stroke: "#E5E7EB" }}
//                   />
//                   <YAxis
//                     ticks={educationalLevels.map((level) => level.value)}
//                     tickFormatter={(tick) => {
//                       const level = educationalLevels.find((l) => l.value === tick);
//                       return level ? level.label : "";
//                     }}
//                     domain={[0, 100]}
//                     tick={{ fontSize: 12, fill: "#4B5563" }}
//                     axisLine={{ stroke: "#E5E7EB" }}
//                     tickLine={{ stroke: "#E5E7EB" }}
//                   />
//                   <Tooltip content={<CustomTooltip />} cursor={{ stroke: "#E5E7EB" }} />
//                   {Object.keys(colors).map((key) => (
//                     <Line
//                       key={key}
//                       type="monotone"
//                       dataKey={key}
//                       stroke={colors[key]}
//                       strokeWidth={2}
//                       dot={{ fill: colors[key], r: 3, strokeWidth: 2 }}
//                       activeDot={{ r: 6, strokeWidth: 2 }}
//                       name={formatKeyToLabel(key)}
//                     />
//                   ))}
//                 </LineChart>
//               </ResponsiveContainer>
//             </div>
//           </div>
//         </div>
//       );
//     } else {
//       // For background or behavioral, show text results (Overview & Analysis)
//       return (
//         <div className="flex flex-row gap-8 p-6">
//           <div className="flex-1">
//             <div className="rounded-lg border border-gray-100 p-6">
//               <h3 className="text-lg font-semibold mb-4 text-gray-900">Overview</h3>
//               <p className="text-gray-700 leading-relaxed">
//                 {getResultContent(selectedTest, selectedAttempt, "overview")}
//               </p>
//             </div>
//           </div>
//           <div className="flex-1">
//             <div className="rounded-lg border border-gray-100 p-6">
//               <h3 className="text-lg font-semibold mb-4 text-gray-900">Detailed Analysis</h3>
//               <p className="text-gray-700 leading-relaxed">
//                 {getResultContent(selectedTest, selectedAttempt, "analysis")}
//               </p>
//             </div>
//           </div>
//         </div>
//       );
//     }
//   };

//   return (
//     <div className="min-h-screen bg-[#F9F9F9] p-4">
//       <div className="max-w-7xl mx-auto">
//         {/* Test Cards */}
//         <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
//           {testOptions.map((test) => (
//             <TestCard key={test.title} test={test} />
//           ))}
//         </div>

//         {/* Results Section */}
//         <div className="bg-white rounded-lg shadow-sm">
//           <div className="px-6 py-4 border-b border-gray-100">
//             <div className="flex flex-col md:flex-row md:justify-between md:items-center">
//               <h2 className="text-lg font-bold text-gray-900">Test Results</h2>
//               {/* Container for the two selects, aligned to the right and adjacent */}
//               <div className="flex items-center gap-2">
//                 <select
//                   value={selectedTest}
//                   onChange={(e) => setSelectedTest(e.target.value as TestType)}
//                   className="border rounded px-3 py-1.5 bg-white text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
//                 >
//                   <option value="background">Background</option>
//                   <option value="behavioral">Behavioral</option>
//                   <option value="technical">Technical</option>
//                 </select>
//                 <select
//                   value={selectedAttempt}
//                   onChange={(e) => setSelectedAttempt(e.target.value as AttemptType)}
//                   className="border rounded px-3 py-1.5 bg-white text-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
//                 >
//                   <option value="attempt1">Attempt 1</option>
//                   <option value="attempt2">Attempt 2</option>
//                 </select>
//               </div>
//             </div>
//           </div>
//           {/* Results based on selection */}
//           {renderResults()}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TestCenter;
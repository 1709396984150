import { FC, useMemo } from "react";
import { Course, CourseCategoryCardProps } from "../../types/interfaces";
import ProgressIndicator from "../../components/ProgressIndicator";
import { DefaultButton, PrimaryButton } from "../../components/Button";
import ProgressChips from "./ProgressChips";

const CourseCategoryCard: FC<CourseCategoryCardProps> = ({
  categoryName,
  courses,
  coursesProgress,
  isLoaded,
  handleUpdateCourseProgress,
  handleNavigateToCourse,
}) => {
  const lastInProgressCourse: Course = useMemo<Course>(() => {
    let targetCourse = courses.find(
      (course) => coursesProgress[course.courseNumber] !== 1
    );
    if (targetCourse === undefined) {
      targetCourse = courses.at(-1);
      if (targetCourse === undefined)
        throw new Error(
          `Target course not available: category ${categoryName} has no courses`
        );
    }
    return targetCourse;
  }, [categoryName, courses, coursesProgress]);
  return (
    <div className="bg-background w-[520px] rounded-md flex flex-col overflow-clip border border-solid border-background-dimmer">
      <div className="flex flex-col">
        <div className="px-4 pt-4 pb-2 flex flex-col gap-2 border-solid border-background-dimmer shadow-sm">
          <h2 className="text-foreground font-semibold text-2xl">
            {categoryName}{" "}
          </h2>
          <ProgressChips
            courses={courses}
            coursesProgress={coursesProgress}
            isLoaded={isLoaded}
            minified={true}
          ></ProgressChips>
        </div>
        <div className="flex flex-col h-96 overflow-scroll p-8">
          {courses.map((course, courseIndex) => (
            <ProgressIndicator
              key={courseIndex}
              progress={
                isLoaded ? coursesProgress[course.courseNumber] ?? 0 : -1
              }
              showConnectionLine={courseIndex !== courses.length - 1}
              handleClick={() => {
                if (isLoaded) {
                  if (coursesProgress[course.courseNumber] === 1)
                    handleUpdateCourseProgress(course, 0);
                  else handleUpdateCourseProgress(course, 1);
                }
              }}
            >
              <p
                className="h-14 text-foreground-dimmer hover:underline hover:text-foreground underline-offset-1 cursor-pointer transition-colors"
                tabIndex={0}
                onClick={() => handleNavigateToCourse(course)}
              >
                {course.name}
              </p>
            </ProgressIndicator>
          ))}
        </div>
      </div>
      <div className="flex flex-row w-full justify-end items-center rounded-b-md py-3 p-4 border-t-2 border-solid border-background-dimmer">
        {isLoaded ? (
          <PrimaryButton
            message={`Continue Studying`}
            handleClick={() => {
              handleNavigateToCourse(lastInProgressCourse);
            }}
          ></PrimaryButton>
        ) : (
          <DefaultButton message="Progress Data Loading"></DefaultButton>
        )}
      </div>
    </div>
  );
};
export default CourseCategoryCard;

import { useState, useEffect, useCallback, useRef, UIEvent } from "react";
import Ably from "ably";
import axiosInterceptor from "../../utils/axiosInterceptor";
import { Search, Send } from "lucide-react";
import Avatar from "boring-avatars";
import { Button, Modal } from "react-bootstrap";
import { Channel, Message, TokenRequest } from "./interfaces";

export default function Chat() {
  // State management
  const [chatsPending, setChatsPending] = useState<boolean>(true);
  const [channels, setChannels] = useState<Channel[]>([]);
  const [channelsPending, setChannelsPending] = useState<boolean>(true);
  const [currentChannel, setCurrentChannel] =
    useState<Ably.RealtimeChannel | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [messageText, setMessageText] = useState<string>("");
  const [realtime, setRealtime] = useState<Ably.Realtime | null>(null);
  const [currentUserID, setCurrentUserID] = useState<string>(
    localStorage.getItem("user_id") || ""
  );
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(50);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);

  // Refs for scrolling
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesStartRef = useRef<HTMLDivElement>(null);

  // Modal state
  const [showNewChatModal, setShowNewChatModal] = useState<boolean>(false);
  const [availableChannels, setAvailableChannels] = useState<Channel[]>([]);

  // Initialize Ably Realtime
  useEffect(() => {
    const ably = new Ably.Realtime({
      authCallback: async (tokenParams, callback) => {
        try {
          const response = await axiosInterceptor.get(
            "/get_ably_token_request/"
          );
          const tokenRequest: TokenRequest = response.data;
          setCurrentUserID(tokenRequest.clientId);
          callback(null, tokenRequest);
          setChatsPending(false);
        } catch (err: any) {
          console.error("Error getting Ably token:", err);
          callback(err, null);
        }
      },
    });

    ably.connection.once("connected", () => {
      console.log("Connected to Ably");
      setRealtime(ably);
    });

    return () => {
      ably.connection.close();
    };
  }, []);

  // Fetch channels when user ID or Realtime changes
  useEffect(() => {
    if (currentUserID && realtime) {
      fetchChannels();
    }
  }, [currentUserID, realtime]);

  // Fetch channel history when current channel changes
  useEffect(() => {
    if (currentChannel) {
      setOffset(0);
      setLimit(50);
      setHasMore(true);
      fetchChannelHistory(currentChannel.name, 0, 50);
    }
  }, [currentChannel]);

  // Fetch user channels
  const fetchChannels = async () => {
    try {
      const response = await axiosInterceptor.get(
        `/get_user_channels/${currentUserID}`
      );
      const allChannels: Channel[] = response.data;

      // Separate active and available channels
      const activeChannels = allChannels.filter(
        (channel) => channel.last_message && channel.last_message.length > 0
      );
      const available = allChannels.filter((channel) => !channel.last_message);

      setChannels(activeChannels);
      setAvailableChannels(
        available.map((channel) => ({
          ...channel,
          last_message: "",
        }))
      );
      setChannelsPending(false);
    } catch (error) {
      console.error("Error fetching channels:", error);
    }
  };

  // Join a channel
  const joinChannel = useCallback(
    (channelId: string) => {
      console.log("Joining channel:", channelId);
      if (!realtime) {
        console.error("Realtime is not initialized");
        return;
      }

      // If already in the desired channel, do nothing
      if (currentChannel && currentChannel.name === channelId) {
        console.log("Already in this channel");
        return;
      }

      // Unsubscribe from the current channel if any
      if (currentChannel) {
        console.log("Unsubscribing from current channel");
        currentChannel.unsubscribe();
        realtime.channels.get(currentChannel.name)?.detach();
      }

      // Reset message-related states
      setMessages([]);
      setOffset(0);
      setLimit(50);
      setHasMore(true);

      // Subscribe to the new channel
      const channel = realtime.channels.get(channelId);
      channel.subscribe("message", (message) => {
        console.log("Received message:", message);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: message.id || Math.random().toString(36).substr(2, 9),
            sender_type: (message.data as any).sender_type,
            message: (message.data as any).message,
            timestamp: new Date(message.timestamp),
          },
        ]);
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      });
      setCurrentChannel(channel);
      console.log("Set current channel:", channel);
    },
    [realtime, currentChannel]
  );

  // Fetch channel history
  const fetchChannelHistory = async (
    channelId: string,
    fetchOffset: number,
    fetchLimit: number
  ) => {
    setIsLoading(true);
    try {
      const response = await axiosInterceptor.get(
        `/get_channel_history/${channelId}?offset=${fetchOffset}&limit=${fetchLimit}`
      );

      const seenDates = new Set<string>();
      const newMessages = response.data.map((message: Message) => {
        const date = new Date(message.timestamp).toDateString();
        if (seenDates.has(date)) {
          return { ...message, showDate: false };
        }
        seenDates.add(date);
        return {
          ...message,
          timestamp: new Date(message.timestamp),
          showDate: true,
        };
      });

      setMessages((prevMessages) =>
        fetchOffset === 0 ? newMessages : [...newMessages, ...prevMessages]
      );

      setHasMore(response.data.length === fetchLimit);
    } catch (error) {
      console.error("Error fetching channel history:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle scrolling to fetch more messages
  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const { scrollTop } = e.currentTarget;
    if (scrollTop === 0 && !isLoading && hasMore && currentChannel) {
      const newOffset = offset + limit;
      setOffset(newOffset);
      fetchChannelHistory(currentChannel.name, newOffset, limit);
    }
  };

  // Send a message
  const sendMessage = async () => {
    if (messageText.trim() === "") return;
    if (!currentChannel) {
      console.error("No current channel selected");
      return;
    }
    const message = {
      message: messageText,
      sender_type: "user", // Keep consumer's 'user' type
      timestamp: new Date().toISOString(),
    };
    try {
      await currentChannel.publish("message", message);
      setMessageText("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  // Start a new chat
  const startNewChat = async (channel: Channel) => {
    // Add this channel to the user’s active channel list
    setChannels((prevChannels) => [
      ...prevChannels,
      {
        id: channel.id,
        name: channel.name,
        last_message: "",
      },
    ]);
    setAvailableChannels((prevUsers) =>
      prevUsers.filter((user) => user.id !== channel.id)
    );
    joinChannel(channel.id);
    setShowNewChatModal(false);
  };

  // Loading state for the entire chat
  if (chatsPending) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-[95vh]">
      {/* Channels/Chats list */}
      <div className="w-1/3 bg-transparent border-r pr-6">
      <div className="flex items-center gap-2 px-4 py-2">
  {/* Wrapped in a 'relative' with a fixed max-width */}
  <div className="relative max-w-sm w-full">
    <input
      type="text"
      placeholder="Search messages"
      className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-full 
                 focus:outline-none focus:ring-1 focus:ring-gray-300"
    />
    <Search
      size={20}
      className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
    />
  </div>

  {/* New Chat Icon: always visible to the right */}
  <i
    className="bi bi-send-plus text-lg text-gray-600 cursor-pointer"
    onClick={() => setShowNewChatModal(true)}
  />
</div>


        <ul className="overflow-y-auto mt-4 min-h-20">
          {channelsPending ? (
            <div className="flex justify-center items-center">
              <div className="lds-ring lds-ring-smaller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (
            channels.map((channel) => (
              <li key={channel.id} className="mb-2">
                <button
                  className={`w-full text-left p-4 rounded bg-white ${
                    currentChannel && currentChannel.name === channel.id
                      ? "!bg-gray-100"
                      : ""
                  }`}
                  onClick={() => joinChannel(channel.id)}
                >
                  <div className="flex items-center flex-row gap-4">
                    <Avatar
                      name={channel.name}
                      variant="beam"
                      colors={["#99CBFF", "#EDEDED"]}
                      className="w-12 h-12 rounded-full mr-4"
                    />
                    <div>
                      <h3 className="font-semibold">
                        {channel?.name?.replace("Chat with ", "")}
                      </h3>
                      <p className="text-sm text-gray-600">
                        {channel.last_message &&
                        channel.last_message.length > 30
                          ? channel.last_message.slice(0, 30) + "..."
                          : channel.last_message?.slice(0, 30)}
                      </p>
                    </div>
                  </div>
                </button>
              </li>
            ))
          )}
        </ul>
      </div>

      {/* Chat messages */}
      <div className="w-2/3 flex flex-col bg-white">
        {currentChannel ? (
          <>
            <h1 className="text-xl font-normal p-4 border-b flex flex-row items-center justify-between">
              <div className="flex flex-row items-center gap-3">
                <Avatar
                  name={
                    channels.find((c) => c.id === currentChannel?.name)?.name ||
                    ""
                  }
                  variant="beam"
                  colors={["#99CBFF", "#EDEDED"]}
                  className="!h-8 !w-8 rounded-full"
                />
                {channels
                  .find((c) => c.id === currentChannel?.name)
                  ?.name.replace("Chat with ", "")}
              </div>
              <i className="bi bi-three-dots-vertical cursor-pointer"></i>
            </h1>

            <div className="flex-grow overflow-auto p-4" onScroll={handleScroll}>
              {isLoading && (
                <div className="flex justify-center items-center mb-4">
                  <div className="lds-ring lds-ring-smaller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              )}

              <div ref={messagesStartRef} />
              {messages.map((message) => (
                <div key={message.id}>
                  {message.showDate && (
                    <div className="w-full mx-auto text-center text-gray-500 text-xs mb-2">
                      {message.timestamp.toLocaleString()}
                    </div>
                  )}
                  <div
                    className={`mb-4 flex items-start gap-2 ${
                      message.sender_type === "user"
                        ? "justify-end"
                        : "justify-start"
                    }`}
                  >
                    {message.sender_type !== "user" && (
                      <Avatar
                        name={message.sender_type}
                        variant="beam"
                        colors={["#99CBFF", "#EDEDED"]}
                        className="!h-4 !w-4 rounded-full"
                      />
                    )}
                    <div
                      className={`max-w-[70%] bg-gray-300 rounded-full px-3 py-1 text-wrap`}
                    >
                      <p className="text-black">{message.message}</p>
                    </div>
                    {message.sender_type === "user" && (
                      <Avatar
                        name={message.sender_type}
                        variant="beam"
                        colors={["#99CBFF", "#EDEDED"]}
                        className="!h-4 !w-4 rounded-full"
                      />
                    )}
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>

            <div className="border-t p-4">
              <div className="flex items-center">
                <input
                  type="text"
                  value={messageText}
                  onChange={(e) => setMessageText(e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && sendMessage()}
                  placeholder="Write a message..."
                  className="flex-1 border rounded-full px-4 py-2 mr-2"
                />
                <button
                  onClick={sendMessage}
                  className="bg-blue-500 text-white rounded-full p-2 hover:bg-blue-600"
                >
                  <Send size={20} />
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="flex-grow flex-col flex items-center justify-center gap-y-3.5">
            <i
              className="bi bi-chat-left text-4xl text-gray-500 block"
              style={{
                WebkitTextStroke: "1.5px",
              }}
            ></i>
            <p className="text-gray-500">Select an existing chat or start a new one!</p>
            <Button
              variant="primary"
              className="rounded-full !p-2 text-sm"
              size="sm"
              onClick={() => setShowNewChatModal(true)}
            >
              <i className="bi bi-send-plus cursor-pointer mr-2 text-sm"></i>
              Start a new chat
            </Button>
          </div>
        )}
      </div>

      {/* New Chat Modal */}
      <Modal show={showNewChatModal} onHide={() => setShowNewChatModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Matches</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-6">
          <ul className="space-y-6 w-full">
            {availableChannels.map((channel) => (
              <li key={channel.id} className="flex items-center w-full space-x-3">
                <Avatar
                  name={channel.name}
                  variant="beam"
                  colors={["#99CBFF", "#EDEDED"]}
                  className="w-10 h-10 rounded-full"
                />
                <span>{channel?.name?.replace("Chat with ", "")}</span>
                <div className="flex-grow"></div>
                <div
                  className="flex items-center hover:bg-gray-100 rounded cursor-pointer size-10 justify-center text-center"
                  onClick={() => startNewChat(channel)}
                  title="Start a new chat"
                >
                  <i className="bi bi-send-plus cursor-pointer text-xl"></i>
                </div>
              </li>
            ))}
          </ul>
        </Modal.Body>
      </Modal>
    </div>
  );
}
import React, { useState, useEffect } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { python } from "@codemirror/lang-python";
import { cpp } from "@codemirror/lang-cpp";
import { tokyoNight } from "@uiw/codemirror-theme-tokyo-night";
import axiosInterceptor from "../../utils/axiosInterceptor";
import { Button } from "react-bootstrap";


interface TestCase {
    input: any;
    expected_output: string;
    component?: JSX.Element;
}


interface TestResult {
    result: string;
    input: string;
    output: string;
    expected_output: string;
}


interface CodingBoxProps {
    setParentTestCases: (testCases: TestCase[]) => void;
    parentCurrentTestCase: number;
    setParentCurrentTestCase: (index: number) => void;
    onCodeChange: (code: string) => void;
    code: string;
}


const languageOptions: Record<string, ReturnType<typeof javascript | typeof python | typeof cpp>> = {
    javascript: javascript(),
    python: python(),
    cpp: cpp(),
};


const languageStartingCode: Record<string, string> = {
    javascript: "console.log('hello, world');",
    python: "print('hello, world')",
    cpp: `#include <iostream>


using namespace std;


int main() {
   cout << "hello, world";
   return 0;
}`,
};


const CodingBox: React.FC<CodingBoxProps> = ({
    setParentTestCases,
    parentCurrentTestCase,
    setParentCurrentTestCase,
}) => {
    const [code, setCode] = useState<string>(languageStartingCode.python);
    const [language, setLanguage] = useState<string>("python");
    const [localTestCases, setLocalTestCases] = useState<TestCase[]>([]);
    const [testResults, setTestResults] = useState<JSX.Element[]>([]);
    const [consoleState, setConsoleState] = useState<boolean>(true);


    useEffect(() => {
        const fetchTestCases = async () => {
            const data = await testCaseData(); // returns TestResult[]
            if (data) {
                const newTestCases: TestCase[] = data.map(({ input, expected_output }: TestResult) => ({
                    input,
                    expected_output,
                    component: createTestCase(input, expected_output),
                }));
                setLocalTestCases(newTestCases);
                setParentTestCases(newTestCases);
                setParentCurrentTestCase(0);
            }
        };
        fetchTestCases();
    }, [setParentTestCases, setParentCurrentTestCase]);


    const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedLanguage = e.target.value;
        setLanguage(selectedLanguage);
        setCode(languageStartingCode[selectedLanguage]);
    };


    const createTestCase = (input: any, expected_output: any): JSX.Element => (
        <div className="mt-4">
            <p className="text-sm text-gray-600 mb-1">Input:</p>
            <div className="bg-[#D9D9D9] p-2 rounded">
                {JSON.stringify(input)}
            </div>
            <p className="text-sm text-gray-600 mb-1 mt-3">Expected Output:</p>
            <div className="bg-[#D9D9D9] p-2 rounded">
                {JSON.stringify(expected_output)}
            </div>
        </div>
    );


    const createTestResult = (
        result: string,
        input: any,
        output: any,
        expected_output: any
    ): JSX.Element => (
        <div className="mt-4">
            <p
                className={`text-lg font-bold ${result === "Pass" ? "text-green-600" : "text-red-600"
                    }`}
            >
                {result === "Pass" ? "Passed" : "Failed"}
            </p>
            <p className="text-sm text-gray-600 mb-1 mt-2">Input:</p>
            <div className="bg-[#D9D9D9] p-2 rounded">
                {JSON.stringify(input)}
            </div>
            <p className="text-sm text-gray-600 mb-1 mt-2">Output:</p>
            <div className="bg-[#D9D9D9] p-2 rounded">
                {JSON.stringify(output)}
            </div>
            <p className="text-sm text-gray-600 mb-1 mt-2">Expected Output:</p>
            <div className="bg-[#D9D9D9] p-2 rounded">
                {JSON.stringify(expected_output)}
            </div>
        </div>
    );


    const createTestCaseButtons = (): JSX.Element[] =>
        localTestCases.map((_, index) => (
            <button
                key={index}
                onClick={() => setParentCurrentTestCase(index)}
                className={`px-3 py-1 rounded mr-2 ${index === parentCurrentTestCase
                    ? "bg-gray-300"
                    : "bg-gray-200"
                    }`}
            >
                Case {index + 1}
            </button>
        ));


    const testCaseData = async (): Promise<TestResult[]> => {
        try {
            const { data } = await axiosInterceptor.post("/run_code/", {
                code,
                language,
            });
            return data.results; // Assume data.results is TestResult[]
        } catch (error) {
            console.error("Error running code:", error);
            return [];
        }
    };


    const handleSubmit = async () => {
        const data = await testCaseData(); // data: TestResult[]
        if (data) {
            // Convert TestResult[] to TestCase[]
            const newTestCases: TestCase[] = data.map(({ input, expected_output }: TestResult) => ({
                input,
                expected_output,
                component: createTestCase(input, expected_output),
            }));


            const newTestResults = data.map(
                ({ result, input, output, expected_output }: TestResult) =>
                    createTestResult(result, input, output, expected_output)
            );


            setLocalTestCases(newTestCases); // TestCase[]
            setParentTestCases(newTestCases); // TestCase[]
            setTestResults(newTestResults); // JSX.Element[]
            setParentCurrentTestCase(0);
            setConsoleState(false);
        }
    };


    return (
        <div className="border border-gray-300 rounded-lg overflow-hidden">
            <div className="bg-[#1a1b26] text-white p-3 flex justify-between items-center">
                <div className="flex items-center">
                    <span className="mr-2">Language:</span>
                    <select
                        value={language}
                        onChange={handleLanguageChange}
                        className="bg-transparent text-white border-none"
                    >
                        {Object.keys(languageOptions).map((lang) => (
                            <option
                                key={lang}
                                value={lang}
                                className="text-black"
                            >
                                {lang.charAt(0).toUpperCase() + lang.slice(1)}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="border-b border-gray-300">
                <CodeMirror
                    value={code}
                    height="260px"
                    theme={tokyoNight}
                    extensions={[languageOptions[language]]}
                    onChange={(value) => setCode(value)}
                />
            </div>
            <div>
                <div className="flex mb-3 bg-gray-200 p-2">
                    <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => setConsoleState(true)}
                        className={`${consoleState ? "bg-gray-300" : "bg-gray-200"
                            }`}
                    >
                        Testcase
                    </Button>
                    <Button
                        variant="ghost"
                        size="sm"
                        onClick={() => setConsoleState(false)}
                        className={`${!consoleState ? "bg-gray-300" : "bg-gray-200"
                            }`}
                    >
                        Result
                    </Button>
                </div>
                <div className="py-2 px-4">
                    <div className="mb-2 -mt-2">{createTestCaseButtons()}</div>
                    {consoleState
                        ? localTestCases[parentCurrentTestCase]?.component
                        : testResults[parentCurrentTestCase]}
                    <div className="flex justify-end mt-3 mb-2">
                        <Button
                            onClick={handleSubmit}
                            size="sm"
                            className="bg-[#1867FF] text-white px-6 rounded border-[#1867FF] active:bg-blue-600 active:border-blue-600 focus:bg-blue-600 focus:border-blue-600"
                        >
                            Run
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default CodingBox;



// Question mapping (text to number)
export const questionMapping: Record<string, number> = {
    "Am the life of the party.": 1,
    "Feel little concern for others.": 2,
    "Am always prepared.": 3,
    "Get stressed out easily.": 4,
    "Have a rich vocabulary.": 5,
    "Don't talk a lot.": 6,
    "Am interested in people.": 7,
    "Leave my belongings around.": 8,
    "Am relaxed most of the time.": 9,
    "Have difficulty understanding abstract ideas.": 10,
    "Feel comfortable around people.": 11,
    "Insult people.": 12,
    "Pay attention to details.": 13,
    "Worry about things.": 14,
    "Have a vivid imagination.": 15,
    "Keep in the background.": 16,
    "Sympathize with others' feelings.": 17,
    "Make a mess of things.": 18,
    "Seldom feel blue.": 19,
    "Am not interested in abstract ideas.": 20,
    "Start conversations.": 21,
    "Am not interested in other people's problems.": 22,
    "Get chores done right away.": 23,
    "Am easily disturbed.": 24,
    "Have excellent ideas.": 25,
    "Have little to say.": 26,
    "Have a soft heart.": 27,
    "Often forget to put things back in their proper place.": 28,
    "Get upset easily.": 29,
    "Do not have a good imagination.": 30,
    "Talk to a lot of different people at parties.": 31,
    "Am not really interested in others.": 32,
    "Like order.": 33,
    "Change my mood a lot.": 34,
    "Am quick to understand things.": 35,
    "Don't like to draw attention to myself.": 36,
    "Take time out for others.": 37,
    "Shirk my duties.": 38,
    "Have frequent mood swings.": 39,
    "Use difficult words.": 40,
    "Don't mind being the center of attention.": 41,
    "Feel others' emotions.": 42,
    "Follow a schedule.": 43,
    "Get irritated easily.": 44,
    "Spend time reflecting on things.": 45,
    "Am quiet around strangers.": 46,
    "Make people feel at ease.": 47,
    "Am exacting in my work.": 48,
    "Often feel blue.": 49,
    "Am full of ideas.": 50,
  };

import React, { useEffect, useState, ChangeEvent, FormEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axiosInterceptor from "../../utils/axiosInterceptor";

interface LoginFields {
    email: string;
    password: string;
}

interface LoginErrors {
    email: boolean;
    password: boolean;
}

const BLANK_LOGIN: LoginFields = {
    email: "",
    password: "",
};

function LogIn(): JSX.Element {
    const navigate = useNavigate();
    const [login, setLogin] = useState<LoginFields>(BLANK_LOGIN);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [errors, setErrors] = useState<LoginErrors>({
        email: false,
        password: false,
    });

    useEffect(() => {
        const isAuthenticated = !!localStorage.getItem("access_token");
        if (isAuthenticated) {
            navigate("/");
        }
    }, [navigate]);

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
        setLogin({ ...login, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setErrorMessage("");
        const newErrors: LoginErrors = {
            email: !login.email,
            password: !login.password,
        };
        setErrors(newErrors);

        if (newErrors.email || newErrors.password) {
            setErrorMessage("Please fill in all required fields.");
            return;
        }

        const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
        try {
            const response = await axiosInterceptor.post(`/login/`, login, {
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrfToken || "",
                },
            });
            console.log("response", response);
            const refreshToken = response.data["refresh"];
            const accessToken = response.data["access"];
            const userID = response.data["UserID"];
            const userName = response.data["firstname"] + " " + response.data["lastname"];
            const is_email_verified = response.data["is_email_verified"];
            const is_verified_by_algolink = response.data["is_verified_by_algolink"];
            const is_algolink_admin = response.data["is_algolink_admin"];

            localStorage.setItem("access_token", accessToken);
            localStorage.setItem("refresh_token", refreshToken);
            localStorage.setItem("user_id", userID);
            localStorage.setItem("is_email_verified", is_email_verified);
            localStorage.setItem("is_algolink_admin", is_algolink_admin);
            localStorage.setItem("is_verified_by_algolink", is_verified_by_algolink);
            localStorage.setItem("user_name", userName);

            navigate("../../");
            console.log("localStorage", localStorage);
            window.location.reload();
        } catch (error) {
            console.error("Error:", error);
            setErrorMessage("Incorrect username or password. Please try again.");
            setErrors({ email: true, password: true });
        }
    };

    return (
        <Form
            onSubmit={handleSubmit}
            className="max-w-2xl flex flex-col mx-auto w-[370px] h-full justify-center -mt-8"
        >
            <div className="p-4 flex flex-col gap-y-2">
                <h2 className="text-3xl font-medium text-left mb-3.5 text-white">
                    Welcome Back!
                </h2>
                <FloatingLabel label="Email">
                    <Form.Control
                        name="email"
                        size="sm"
                        type="email"
                        placeholder=" "
                        value={login.email}
                        onChange={handleChange}
                        isInvalid={errors.email}
                    />
                </FloatingLabel>
                <FloatingLabel label="Password">
                    <Form.Control
                        name="password"
                        type="password"
                        placeholder=" "
                        size="sm"
                        value={login.password}
                        onChange={handleChange}
                        isInvalid={errors.password}
                    />
                    <Link
                        className="text-sm text-[#0059EC] !font-normal float-right mt-2 no-underline hover:text-[#0059EC] hover:underline"
                        to={"../recover"}
                    >
                        Forgot password?
                    </Link>
                </FloatingLabel>
                {errorMessage && (
                    <small className="text-danger">{errorMessage}</small>
                )}
                <Button
                    className="bg-[#0059EC] text-white focus:bg-[#0059EC] hover:bg-[#0059EC] border-[#0059EC] hover:border-[#0059EC] font-medium text-sm px-4 py-2 rounded-lg shadow-sm mt-2.5"
                    type="submit"
                >
                    Login
                </Button>
                <p className="text-center text-sm !font-normal mt-1.5">
                    <span className="text-white">Don't have an account? </span>
                    <Link
                        className="text-sm text-[#0059EC] no-underline hover:text-[#0059EC] hover:underline"
                        to={"../signup"}
                    >
                        Sign up
                    </Link>
                </p>
            </div>
        </Form>
    );
}

export default LogIn;
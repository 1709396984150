import React, { useState } from "react";
import { Users, Building2, UserPlus, ArrowLeftRight } from "lucide-react";

import { LucideIcon } from "lucide-react";
import CandidatesPage from "./CandidatesPage";
import CompaniesPage from "./CompaniesPage";
import ReferrersPage from "./ReferrersPage";
import InteractionsPage from "./InteractionsPage";

// ========== MOCK DATA & TYPES ==========

// ========== MAIN INSIGHTS COMPONENT ==========

type PageKey = "candidates" | "companies" | "referrers" | "interactions";

type PageConfig = {
  component: React.FC;
  label: string;
  icon: LucideIcon;
};

type PagesConfig = {
  [K in PageKey]: PageConfig;
};

const Insights = () => {
  const [activePage, setActivePage] = useState<PageKey>("candidates");

  const pages: PagesConfig = {
    candidates: { component: CandidatesPage, label: "Candidates", icon: Users },
    companies: {
      component: CompaniesPage,
      label: "Companies",
      icon: Building2,
    },
    referrers: { component: ReferrersPage, label: "Referrers", icon: UserPlus },
    interactions: {
      component: InteractionsPage,
      label: "Interactions",
      icon: ArrowLeftRight,
    },
  };

  const ActivePageComponent = pages[activePage].component;

  return (
    <div className="min-h-screen bg-white">
      <main className="max-w-7xl mx-auto p-4">
        {/* Top-level Navigation Tabs */}
        <div className="bg-white rounded-xl shadow-sm p-1 mb-6">
          <div className="flex gap-1">
            {Object.entries(pages).map(([key, { label, icon: Icon }]) => (
              <button
                key={key}
                onClick={() => setActivePage(key as PageKey)}
                className={`
                  flex items-center gap-2 px-4 py-2 rounded-lg
                  text-sm font-medium
                  ${
                    activePage === key
                      ? "bg-gray-100 text-gray-900"
                      : "text-gray-600 hover:bg-gray-50"
                  }
                `}
              >
                <Icon size={20} />
                <span>{label}</span>
              </button>
            ))}
          </div>
        </div>

        {/* Render the active page */}
        <ActivePageComponent />
      </main>
    </div>
  );
};

export default Insights;

import React, { FC } from "react";
import AlgoLinkHalfGear from "../../../assets/img/home/algolink-half-gear.svg";

const SurveySuccess: FC = () => {
    return (
        <div className="mx-auto container max-w-2xl h-full flex flex-col justify-center gap-y-6 relative">
            <img
                src={AlgoLinkHalfGear}
                alt="AlgoLink Half Gear"
                className="absolute top-20 -left-20 h-48 z-0 rotate-[170deg]"
            />
            <h2 className="text-3xl font-semibold">
                Thank you for completing the Survey!
            </h2>
            <div className="flex flex-col gap-y-6">
                <p className="text-black text-base">
                    Your responses have been successfully submitted. We
                    appreciate you taking the time to share your thoughts and
                    help us better understand your preferences.
                </p>
                <p className="text-black text-base">
                    If you have any questions or would like to learn more about
                    how we use this information, feel free to reach out to our
                    support team.
                </p>
                <div className="flex flex-col gap-y-2 text-black">
                    <p className="font-semibold text-black text-base">
                        What’s Next:
                    </p>
                    <ol className="list-decimal pl-8">
                        <li className="list-disc">
                            <p className="text-black text-base">
                                <strong className="font-medium">
                                    Review Your Results:
                                </strong>{" "}
                                We’ll analyze your responses and provide
                                insights that help personalize your experience.
                            </p>
                        </li>
                        <li className="list-disc">
                            <p className="text-black text-base">
                                <strong className="font-medium">
                                    Stay Updated:
                                </strong>{" "}
                                Look out for emails or notifications about next
                                steps and opportunities.
                            </p>
                        </li>
                    </ol>
                </div>
                <p className="text-black text-base">
                    Thank you for your participation and engagement. We look
                    forward to continuing to enhance your experience with
                    AlgoLink!
                </p>
                <p className="text-black text-end text-base font-medium">
                    — The AlgoLink Team
                </p>
            </div>
            <img
                src={AlgoLinkHalfGear}
                alt="AlgoLink Half Gear"
                className="absolute bottom-14 -right-6 h-36 z-0 rotate-[-10deg]"
            />
        </div>
    );
};

export default SurveySuccess;

import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CameraRecorder from "./CameraRecorder";
import Webcam from "react-webcam";
import { Question } from "./types";

interface InterviewTemplateProps {
  type: string;
  questions: Question[];
}

interface CameraRecorderRef {
  stopAndDownload: () => void;
}

export default function InterviewTemplate({
  type,
  questions,
}: InterviewTemplateProps): JSX.Element {
  const navigate = useNavigate();
  const cameraRecorderRef = useRef<CameraRecorderRef | null>(null);
  const [isQuestionsBegun, setIsQuestionsBegun] = useState<boolean>(false);
  const [questionsAsked, setQuestionsAsked] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(-1);
  const [showExitModal, setShowExitModal] = useState<boolean>(false);
  const numQuestionsToAsk = questionsAsked.length;

  useEffect(() => {
    setQuestionsAsked(questions);
  }, [questions]);

  const startInterview = () => {
    setCurrentQuestionIndex(0);
    setIsQuestionsBegun(true);
  };

  const handleExitClick = () => {
    setShowExitModal(true);
  };

  const handleCloseExitModal = () => {
    setShowExitModal(false);
  };

  const handleNextQuestion = async () => {
    try {
      if (cameraRecorderRef.current) {
        await cameraRecorderRef.current.stopAndDownload();
      }

      if (currentQuestionIndex === questionsAsked.length - 1) {
        navigate("/test-center/home");
      } else {
        setCurrentQuestionIndex((prev) => prev + 1);
      }
    } catch (err) {
      console.error("Question transition error:", err);
      setError("Failed to proceed to next question");
    }
  };

  const exitPage = async () => {
    try {
      if (cameraRecorderRef.current) {
        await cameraRecorderRef.current.stopAndDownload();
      }
      navigate("/test-center/home");
    } catch (err) {
      console.error("Exit error:", err);
    }
  };

  const handleConfirmExit = async () => {
    handleCloseExitModal();
    await exitPage();
  };

  return (
    <div className="min-h-screen bg-[#F9F9F9] p-4">
      {/* Header */}
      <div className="mb-6">
        <h2 className="text-2xl font-semibold mt-2 mb-4">{type} Exam</h2>

        {/* Progress Bar */}
        <div className="flex items-center gap-4">
          <div className="h-2 w-48 bg-gray-200 rounded-full overflow-hidden">
            <div
              className="h-full bg-green-500 rounded-full transition-all duration-300"
              style={{
                width:
                  numQuestionsToAsk > 0
                    ? `${((currentQuestionIndex + 1) / numQuestionsToAsk) * 100
                    }%`
                    : "0%",
              }}
            />
          </div>
          <span className="text-sm text-gray-600">
            {isQuestionsBegun
              ? `${currentQuestionIndex + 1}/${questionsAsked.length} Questions`
              : "Test not started yet"}
          </span>
        </div>
      </div>
      <div className="max-w-5xl mx-auto">
        {/*  Actions Section */}
        <div className="flex justify-end items-center mb-6">
          {/* Action Buttons */}
          <div className="flex gap-4">
            {!isQuestionsBegun ? (
              <button
                onClick={startInterview}
                className="px-6 py-2 rounded-full bg-blue-600 text-white hover:bg-blue-700 transition-colors"
              >
                Start Interview
              </button>
            ) : (
              currentQuestionIndex < questionsAsked.length && (
                <button
                  onClick={handleNextQuestion}
                  className="px-6 py-2 rounded-full bg-blue-600 text-white hover:bg-blue-700 transition-colors"
                >
                  {currentQuestionIndex === questionsAsked.length - 1
                    ? "Submit Test"
                    : "Next Question"}
                </button>
              )
            )}
            {isQuestionsBegun && (
              <button
                onClick={handleExitClick}
                className="px-6 py-2 rounded-full border border-red-500 text-red-500 hover:bg-red-50 transition-colors"
              >
                Exit Interview
              </button>
            )}
          </div>
        </div>

        {/* Main Content */}
        <div className="bg-[#F9F9F9] rounded-xl p-8">
          {isQuestionsBegun ? (
            currentQuestionIndex < questionsAsked.length ? (
              <div className="flex flex-col items-center">
                {/* Camera Section */}
                <div className="w-full max-w-2xl mx-auto mb-8">
                  <CameraRecorder
                    isBackground={true}
                    ref={cameraRecorderRef}
                    ids={questionsAsked.map((question) => Number(question.id))}
                    type={type}
                  />
                </div>

                {/* Question Section */}
                <div className="w-full max-w-2xl mx-auto text-center">
                  <h3 className="text-xl font-semibold mb-4">
                    Question {currentQuestionIndex + 1}
                  </h3>
                  <p className="text-lg text-gray-800">
                    {questionsAsked[currentQuestionIndex].question}
                  </p>
                </div>
              </div>
            ) : (
              <div className="text-center py-12">
                <p className="text-xl font-medium">
                  You have completed all questions!
                </p>
              </div>
            )
          ) : (
            <div className="flex flex-col items-center">
              {/* Camera Preview */}
              <div className="w-full max-w-2xl mx-auto mb-8">
                <Webcam className="rounded-lg w-full" />
              </div>

              {/* Instructions */}
              <div className="w-full max-w-2xl mx-auto">
                <h3 className="text-xl font-semibold mb-6 text-center">
                  Instructions
                </h3>
                <div className="space-y-4 text-gray-600 text-center">
                  <p>
                    You will be given a set of {type.toLowerCase()} interview
                    questions. Please answer each question within the time limit
                    using the STAR response format. Submit each answer when you
                    are done with your response.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Exit Modal */}
      {showExitModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-xl p-6 max-w-md w-full mx-4">
            <h4 className="text-xl font-semibold mb-4">Are you sure?</h4>
            <p className="text-gray-600 mb-6">
              Exiting the test will end your interview and cause you to lose all
              your progress!
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={handleCloseExitModal}
                className="px-4 py-2 rounded-full border border-gray-300 hover:bg-gray-50 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmExit}
                className="px-4 py-2 rounded-full bg-blue-600 text-white hover:bg-blue-700 transition-colors"
              >
                I'm sure
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
function setError(arg0: string) {
  throw new Error("Function not implemented.");
}


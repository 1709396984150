import React from "react";
import { TranscriptProps, CreateTransProps } from "../interfaces";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { Upload } from "../../../components/Upload";
import { FileText } from "lucide-react";

const TranscriptComponent: React.FC<TranscriptProps> = ({ transcript }) => {
  return (
    <div className="flex flex-col gap-3">
      {transcript.map((trans, index) => (
        <div key={index} className="flex flex-row items-start gap-4">
          <div className="flex-shrink-0 w-12 h-12 rounded-xl bg-orange-50 flex items-center justify-center">
            <FileText className="w-6 h-6 text-orange-600" />
          </div>
          
          <div className="flex-grow">
            <a 
              href="https://google.com"
              target="_blank"
              rel="noopener noreferrer"
              className="group"
            >
              <h3 className="text-lg font-semibold text-gray-900 group-hover:text-blue-600">
                {trans.course}
              </h3>
              <p className="text-sm text-gray-500 mt-1">
                Click to view document
              </p>
            </a>
          </div>
        </div>
      ))}
      {transcript.length === 0 && (
        <div className="text-sm text-gray-500">
          No transcript uploaded yet
        </div>
      )}
    </div>
  );
};

const CreateTrans: React.FC<CreateTransProps> = ({ transcript, userId }) => {
  return (
    <Form>
      <Stack gap={2}>
        <Upload
          userId={userId}
          rootDirectory="transcripts"
          uploadEndpoint={`file_upload/`}
        />
      </Stack>
    </Form>
  );
};

export default TranscriptComponent;
export { CreateTrans };

import Avatar from 'boring-avatars';

// Referrers Data
const referrersOverview = {
    allReferrers: 90,
    referralsGiven: 100,
    messagesSent: 56,
  };
  
  const referrersTopCompanies = [
    { name: 'Meta', total: 40 },
    { name: 'Amazon', total: 30 },
    { name: 'Netflix', total: 20 },
  ];
  
  const topReferrers = [
    { id: 1, name: 'Name', company: 'Meta', referralsGiven: 10, messagesSent: 100 },
    { id: 2, name: 'Name', company: 'Amazon', referralsGiven: 10, messagesSent: 100 },
    { id: 3, name: 'Name', company: 'Netflix', referralsGiven: 10, messagesSent: 100 },
    { id: 4, name: 'Name', company: 'Meta', referralsGiven: 10, messagesSent: 100 },
    { id: 5, name: 'Name', company: 'Amazon', referralsGiven: 10, messagesSent: 100 },
  ];
  
/** Referrers Page */
const ReferrersPage = () => {
    return (
      <div className="space-y-6">
        {/* Row 1: Overview & Top Companies */}
        <div className="grid grid-cols-3 gap-6">
          
          {/* CARD: Overview */}
          <div className="col-span-2 bg-white rounded-xl shadow-sm p-4">
            <h2 className="text-lg font-semibold mb-4">Overview</h2>
            <div className="grid grid-cols-3 gap-8">
              <div>
                <p className="text-gray-500 text-sm mb-1">All Referrers</p>
                <p className="text-2xl font-semibold">{referrersOverview.allReferrers}</p>
              </div>
              <div>
                <p className="text-gray-500 text-sm mb-1">Referrals Given</p>
                <p className="text-2xl font-semibold">{referrersOverview.referralsGiven}</p>
              </div>
              <div>
                <p className="text-gray-500 text-sm mb-1">Messages Sent</p>
                <p className="text-2xl font-semibold">{referrersOverview.messagesSent}</p>
              </div>
            </div>
          </div>
  
          {/* CARD: Top Companies */}
          <div className="bg-white rounded-xl shadow-sm p-4">
            <h2 className="text-lg font-semibold mb-4">Top Companies</h2>
            <div className="space-y-3 text-sm">
              {referrersTopCompanies.map((company, index) => (
                <div
                  key={company.name}
                  className="flex justify-between items-center"
                >
                  <span>{index + 1}. {company.name}</span>
                  <span>{company.total}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
  
        {/* CARD: Top Referrers */}
        <div className="bg-white rounded-xl shadow-sm p-4">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold">Top Referrers</h2>
            <div className="flex gap-2">
              <select className="px-3 py-2 text-sm bg-white border border-gray-200 rounded-md">
                <option>Industry</option>
              </select>
              <select className="px-3 py-2 text-sm bg-white border border-gray-200 rounded-md">
                <option>Company</option>
              </select>
              <input
                type="text"
                placeholder="Search"
                className="px-3 py-2 text-sm bg-white border border-gray-200 rounded-md
                           focus:outline-none focus:border-blue-400 focus:ring focus:ring-blue-50"
              />
            </div>
          </div>
          <table className="w-full text-sm">
            <thead>
              <tr className="border-b border-gray-100 text-gray-500">
                <th className="py-2 text-left font-medium w-8">#</th>
                <th className="py-2 text-left font-medium">Referrer</th>
                <th className="py-2 text-center font-medium">Referrals Given</th>
                <th className="py-2 text-center font-medium">Messages Sent</th>
                <th className="py-2"></th>
              </tr>
            </thead>
            <tbody>
              {topReferrers.map((referrer, index) => (
                <tr
                  key={referrer.id}
                  className="border-b border-gray-50 hover:bg-gray-50 transition-colors"
                >
                  <td className="py-2">{index + 1}</td>
                  <td className="py-2">
                    <div className="flex items-center gap-2">
                      <Avatar
                        size={24}
                        name={referrer.name}
                        variant="beam"
                        colors={['#E3E3E3', '#C7C7C7', '#999999']}
                      />
                      {referrer.name}
                    </div>
                  </td>
                  <td className="py-2 text-center">{referrer.referralsGiven}</td>
                  <td className="py-2 text-center">{referrer.messagesSent}</td>
                  <td className="py-2 text-right">
                    <button className="text-blue-600 hover:underline text-xs">
                      View Profile
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

export default ReferrersPage;
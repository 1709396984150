import { Resources } from "./LectureMedia";
import { LectureContent } from "../../../types/interfaces";
import {
  extractLectureContentURL,
  extractLectureNameFromFilePath,
} from "../courseUtils";
import FileURLViewer from "../../../components/FileUrlViewer";

interface LectureProps {
  content: LectureContent;
}

export default function Lecture({ content }: LectureProps) {
  const url = extractLectureContentURL(content);
  const fileName = extractLectureNameFromFilePath(content.mainFilesList[0]);

  return (
    <div className="flex flex-col w-full gap-6">
      <h2 className="text-foreground font-normal text-2xl">{fileName}</h2>
      <div className="tab overflow-auto w-full aspect-video bg-foreground">
        <FileURLViewer url={url} fileType={content.type}></FileURLViewer>
      </div>
      <h2 className="text-foreground font-normal text-2xl">Resources</h2>
      <Resources content={content} mainDownloadURL={url} />
    </div>
  );
}

// src/routes/profile/career/ExternalCourses.tsx

import React, { useState } from "react";
import axiosInterceptor from "../../../utils/axiosInterceptor";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { Row } from "react-bootstrap";
import Modal from "../../../components/Modal";
import FormInput from "../../../components/FormInput";
import { Course, CreateCourseProps } from "../interfaces";


interface ExternalCourseProps {
  courseList: Course[];
}

export const ExternalCourse: React.FC<ExternalCourseProps> = ({ courseList }) => {
  const CourseItem: React.FC<{ item: Course }> = ({ item }) => {
    const { school, degree, course_name, department } = item;

    return (
      <div className="flex flex-row items-start gap-4 mb-6 last:mb-0">
        {/* School Logo/Initial */}
        <div className="flex-shrink-0 w-12 h-12 rounded-xl bg-red-50 flex items-center justify-center">
          {/* <span className="text-red-600 text-lg font-semibold">{school.charAt(0)}</span> */}
          <span className="text-red-600 text-lg font-semibold">C</span>

        </div>
        
        {/* Main Content */}
        <div className="flex-grow">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-lg font-semibold text-gray-900">{course_name}</h3>
              <p className="text-base font-medium text-gray-700">
                {department}
              </p>
              <div className="flex items-center gap-4 mt-1">
                <p className="text-sm text-gray-500">
                  Associated with {school} <span className="mx-1 text-gray-400">•</span> {degree}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {courseList.map((item, i) => (
        <CourseItem key={i} item={item} />
      ))}
    </div>
  );
};

export default ExternalCourse;
// interface ExternalCourseProps {
//   courseList: Course[];
// }

// export const ExternalCourse: React.FC<ExternalCourseProps> = ({ courseList }) => {
//   const CourseItem: React.FC<{ item: Course }> = ({ item }) => {
//     const { school, degree, course_name, department } = item;
//     return (
//       <div className="flex flex-row items-center justify-between mb-4">
//         <div className="flex flex-col gap-y-0.5">
//           <p className="font-semibold text-black text-lg">{course_name}</p>
//           <p className="text-black">{department}</p>
//           <p className="text-gray-700">
//             Associated with {school} ({degree})
//           </p>
//         </div>
//       </div>
//     );
//   };

//   return (
//     <div className="-mb-3">
//       {courseList.map((item, i) => (
//         <CourseItem key={i} item={item} />
//       ))}
//     </div>
//   );
// };

export const CreateCourse: React.FC<CreateCourseProps> = ({
  courseList,
  setCourse,
  userEds,
}) => {
  const defaultEdu = userEds[0] || { id: 0, school: "", degree: "" };
  const BLANK_DATA: Course = {
    school: defaultEdu.school,
    degree: defaultEdu.degree,
    course_name: "",
    department: "",
  };

  const [courses, setCourses] = useState<Course[]>(
    courseList.length ? courseList : [BLANK_DATA]
  );
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const updateCourseItem = (
    index: number,
    field: string,
    value: string
  ) => {
    const updatedList = [...courses];
    if (field === "school+degree") {
      const [school, degree] = value.split("|");
      updatedList[index] = { ...updatedList[index], school, degree };
    } else {
      updatedList[index] = { ...updatedList[index], [field]: value };
    }
    setCourses(updatedList);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    index: number
  ) => {
    updateCourseItem(index, e.target.name, e.target.value);
  };

  const handleRemove = (itemToRemove: Course) => {
    if (itemToRemove.id) {
      const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
      axiosInterceptor
        .delete(`/delete_course_entry/${itemToRemove.id}/`, {
          headers: { "X-CSRFToken": csrfToken || "" },
        })
        .catch(console.error);
    }
    setCourses(courses.filter((item) => item !== itemToRemove));
  };

  const addNewEntry = () => {
    setCourses([...courses, { ...BLANK_DATA }]);
  };

  const validateFields = () => {
    for (const course of courses) {
      if (!course.school || !course.degree || !course.course_name || !course.department) {
        handleShowModal("All fields are required.");
        return false;
      }
    }
    return true;
  };

  const handleShowModal = (message: string) => {
    setModalMessage(message);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleSubmit = async () => {
    if (!validateFields()) return;

    const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
    for (const course of courses) {
      if (course.id) {
        await axiosInterceptor.patch(`/update_course/${course.id}/`, course, {
          headers: { "X-CSRFToken": csrfToken || "" },
        });
      } else {
        const edu = userEds.find(
          (ed) => ed.school === course.school && ed.degree === course.degree
        );
        if (edu) course.education_id = edu.id;

        await axiosInterceptor.post(`/create_course/${edu?.id}/`, course, {
          headers: { "X-CSRFToken": csrfToken || "" },
        });
      }
    }

    setCourse(courses);
    window.location.reload();
  };

  return (
    <div>
      <Modal show={showModal} setShow={setShowModal} title="Warning">
        <p>{modalMessage}</p>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
      </Modal>
      {courses.map((item, index) => (
        <div key={index} className="mb-2">
          <Form>
            <Stack gap={2}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ flex: 3 }}>
                  <Form.Group controlId={`formDropdown${index}`}>
                    <Form.Control
                      as="select"
                      name="school+degree"
                      onChange={(e) => handleChange(e, index)}
                      value={`${item.school}|${item.degree}`}
                    >
                      {userEds.map((edu, i) => (
                        <option key={i} value={`${edu.school}|${edu.degree}`}>
                          {`${edu.school} (${edu.degree})`}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>
              <FormInput
                name="course_name"
                label="Name"
                type="text"
                value={item.course_name}
                handler={(e) => handleChange(e, index)}
                required
              />
              <FormInput
                name="department"
                label="Department"
                type="text"
                value={item.department}
                handler={(e) => handleChange(e, index)}
                required
              />
              <Container style={{ textAlign: "center" }}>
                <p className="d-inline-block float-right p-0">
                  <Button
                    onClick={() => handleRemove(item)}
                    style={{ padding: "16px", fontSize: "8px" }}
                  >
                    Remove
                  </Button>
                </p>
              </Container>
            </Stack>
          </Form>
        </div>
      ))}
      <Container className="d-flex justify-content-center align-items-center">
        <Row>
          <Button onClick={addNewEntry} style={{ marginBottom: "25px" }}>
            Add New
          </Button>
          <Button onClick={handleSubmit}>Done</Button>
        </Row>
      </Container>
    </div>
  );
};

// src/routes/profile/CareerProfile.tsx

import React, { useState, useEffect } from "react";
import CareerCard from "./career/CareerCard";
import { PDFViewer } from "./career/DocumentViewer";
import EducationComponent, { CreateEdu } from "./career/Education";
import ExperienceComponent, { CreateExp } from "./career/Experience";
import { ExternalCourse, CreateCourse } from "./career/ExternalCourses";
import { Skills, CreateSkill } from "./career/Skills";
import ResumeComponent, { CreateRes } from "./career/Resume";
import TranscriptComponent, { CreateTrans } from "./career/Transcript";
import ChecklistCard from "./ChecklistCard";
import SurveyLink from "./surveys/SurveyLink";
import PersonalityScoreCard from "./PersonalityScoreCard";
import axiosInterceptor from "../../utils/axiosInterceptor";
import {
  Skill,
  EducationItem,
  ExperienceInfo,
  Course,
  ResumeItem,
  TranscriptItem,
  PersonalityScores,
} from "./interfaces";
import { transformAnswers, validateAnswers } from "./helpers";


interface FullUserData {
  // personal info object
  u_data: {
    firstname?: string;
    lastname?: string;
    email?: string;
    phone_number?: string;
    website?: string;
    linkedin?: string;
    dob?: string;
    street_addr?: string;
    city_addr?: string;
    state_addr?: string;
    zip_code?: string;
    country_addr?: string;
    has_visa?: boolean | string;
    job_country?: string[];
    // ...
  };
  ed_data: EducationItem[];
  exp_data: ExperienceInfo[];
  course_data: Course[];
  skill_data: Skill[];
  resume: ResumeItem[];
  transcript: TranscriptItem[];
}

const BLANK_SKILLS: Skill[] = [];
const BLANK_EDUCATION: EducationItem[] = [];
const BLANK_COURSES: Course[] = [];
const BLANK_EXPERIENCE: ExperienceInfo[] = [];
const BLANK_RESUME: ResumeItem[] = [];
const BLANK_TRANSCRIPT: TranscriptItem[] = [];

const CareerProfile: React.FC = () => {
  const userId = localStorage.getItem("user_id") || "";

  // States for the user’s arrays
  const [education, setEducation] = useState<EducationItem[]>(BLANK_EDUCATION);
  const [experiences, setExperiences] = useState<ExperienceInfo[]>(BLANK_EXPERIENCE);
  const [courses, setCourses] = useState<Course[]>(BLANK_COURSES);
  const [skills, setSkills] = useState<Skill[]>(BLANK_SKILLS);
  const [resume, setResume] = useState<ResumeItem[]>(BLANK_RESUME);
  const [transcript, setTranscript] =
    useState<TranscriptItem[]>(BLANK_TRANSCRIPT);

  // For personality results (if you want to show them here)
  const [personalityScores, setPersonalityScores] = useState<PersonalityScores>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // 1) Fetch user data
        const [allDataResponse, surveyResponse] = await Promise.all([
          axiosInterceptor.get<FullUserData>(`/getuser/${userId}/`),
          axiosInterceptor.get(`/save_or_update_user_surveys/${userId}/`),
        ]);
        const userData = allDataResponse.data;

        // Populate arrays
        setEducation(userData.ed_data || []);
        setCourses(userData.course_data || []);
        setExperiences(userData.exp_data || []);
        setSkills(userData.skill_data || []);
        setResume(userData.resume || []);
        setTranscript(userData.transcript || []);

        // 2) If there's a personality_survey, parse & compute
        if (surveyResponse.data?.personality_survey) {
          try {
            const firstParse = JSON.parse(surveyResponse.data.personality_survey);
            const answersObj = JSON.parse(firstParse);
            const transformedAnswers = transformAnswers(answersObj);
            if (validateAnswers(transformedAnswers)) {
              const scoring = await axiosInterceptor.post("/personality-test/score/", {
                answers: transformedAnswers,
              });
              if (scoring.data?.success) {
                setPersonalityScores(scoring.data.scores);
              }
            }
          } catch (err) {
            console.error("Error parsing personality survey data:", err);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
          
    if (userId) {
      fetchData();
    }
  }, [userId]);
          
  // For associating courses w/ correct education ID
  const userEds = education.map(({ id, school, degree }) => ({ id, school, degree }));

  return (
    <div className="flex flex-row gap-4 w-full">
      {/* LEFT COLUMN, 2/3 width */}
      <div className="flex flex-col gap-y-3 w-2/3">
        {/* Education */}
        <CareerCard title="Education" showAddButton={false}>
          <EducationComponent eduList={education} />
          <CreateEdu eduList={education} setEducation={setEducation} />
        </CareerCard>

        {/* Experience */}
        <CareerCard title="Experience" showAddButton={false}>
          <ExperienceComponent expList={experiences} />
          <CreateExp expList={experiences} setExperiences={setExperiences} />
        </CareerCard>

        {/* External Courses */}
        <CareerCard title="External Courses" showAddButton={false}>
          <ExternalCourse courseList={courses} />
          <CreateCourse courseList={courses} setCourse={setCourses} userEds={userEds} />
        </CareerCard>

        {/* Skills */}
        <CareerCard title="Skills" showAddButton={false}>
          <Skills skills={skills} />
          <CreateSkill skills={skills} setSkills={setSkills} />
        </CareerCard>
      </div>

      {/* RIGHT COLUMN, 1/3 width */}
      <div className="flex flex-col gap-y-3 w-1/3">
        {/* Resume */}
        <CareerCard title="Resume/CV" showAddButton={false}>
          {/* Keep your PDF viewer + your old inline upload */}
          <PDFViewer userId={userId} documentType="Resume" />
          <div className="mt-2">
            {/* If resume is undefined, pass an empty array to avoid “map” errors */}
            <ResumeComponent resume={resume || []} />
            <CreateRes resume={resume || []} userId={userId} />
          </div>
        </CareerCard>

        {/* Transcript */}
        <CareerCard title="Transcript" showAddButton={false}>
          <PDFViewer userId={userId} documentType="Transcript" />
          <div className="mt-2">
            <TranscriptComponent transcript={transcript || []} />
            <CreateTrans transcript={transcript || []} userId={userId} />
          </div>
        </CareerCard>

        {/* Surveys + Personality */}
        <div className="bg-white rounded-lg p-4 shadow-card">
          <h4 className="text-lg font-semibold text-gray-900 mb-3">Surveys</h4>
          <div className="flex flex-col gap-2 mb-4">
            <SurveyLink text="Job Preferences" path="/job-preferences-survey" />
            <SurveyLink text="OCEAN Personality" path="/personality-survey" />
          </div>
          <h4 className="text-lg font-semibold text-gray-900 mb-2">
            Personality Profile
          </h4>
          <PersonalityScoreCard scores={personalityScores} />
        </div>

        {/* Profile Checklist */}
        <ChecklistCard userId={userId} />
      </div>
    </div>
  );
};

export default CareerProfile;
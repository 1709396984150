import React from 'react';
import RecruitmentBackground from "../../assets/img/landing/recruitment-background.png"

const RecruitmentEndSection = () => {
  return (
    <div className="w-full">
      {/* Reimagined Section with Full-width Gradient */}
      <div className="w-full min-h-[600px] relative bg-gradient-to-b from-gray-50 to-gray-100">
        {/* Background image container - spans full width */}
        <div className="absolute inset-0 w-full h-full">
          <img 
            src={RecruitmentBackground} 
            alt="Recruitment Background" 
            className="w-full h-full object-cover opacity-100"
          />
        </div>

        {/* Content - centered both horizontally and vertically */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="max-w-7xl mx-auto px-4 w-full">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-4xl md:text-5xl font-bold text-blue-800 mb-8">
              Build Your Career with<br />
              <span className="text-blue-600">Confidence </span> and <span>{" "}</span>
              <span className="text-blue-600">Clarity </span>
              </h2>
              <p className="text-black text-lg leading-relaxed max-w-2xl mx-auto">
              With AlgoLink, recruiting becomes more than just spamming applications—
              it's about building a career that provides growth and meaning.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruitmentEndSection;
import { useEffect, useState } from "react";
import ScreenSpinner from "../../components/ScreenSpinner";
import axiosInterceptor from "../../utils/axiosInterceptor";

// Interface for our Users
interface User {
  user_id: number;
  full_name: string;
  email: string;
  resume_link?: string;
  joined: string;
}

export default function AdminDashboard() {
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [userData, setUserData] = useState<User[]>([]);

  const loadUserData = () => {
    // Trigger whatever endpoint you need
    axiosInterceptor.get("load_all_data/");
  };

  const accept = async (user: User) => {
    try {
      await axiosInterceptor.patch(`/management/verify_user/${user.user_id}/`);
      setUserData((prevData) =>
        prevData.filter((existingUser) => existingUser.user_id !== user.user_id)
      );
    } catch (err) {
      console.error("Error accepting user:", err);
    }
  };

  const reject = async (user: User) => {
    try {
      await axiosInterceptor.delete(`/management/reject_user/${user.user_id}/`);
      setUserData((prevData) =>
        prevData.filter((existingUser) => existingUser.user_id !== user.user_id)
      );
    } catch (err) {
      console.error("Error rejecting user:", err);
    }
  };

  useEffect(() => {
    axiosInterceptor
      .get("/management/get_users_pending_verification/")
      .then((res) => {
        // Sort by full_name for consistency
        const sortedUsers: User[] = res.data.sort((a: User, b: User) =>
          a.full_name.localeCompare(b.full_name)
        );
        setUserData(sortedUsers);
        setFinishedLoading(true);
      })
      .catch((err) => {
        console.error("Error fetching user data:", err);
        setFinishedLoading(true);
      });
  }, []);

  if (!finishedLoading) {
    return <ScreenSpinner />;
  }

  return (
    <div className="p-6 max-w-7xl mx-auto">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Pending User Verifications</h1>
        <div className="text-sm text-gray-500">
          {userData.length} pending requests
        </div>
      </div>

      {/* "Load Data Into Vectors" Button */}
      <div className="mb-4">
        <button
          onClick={loadUserData}
          className="px-4 py-2 text-sm text-white bg-blue-600 hover:bg-blue-700 rounded-md transition-colors"
        >
          Load Data Into Vectors
        </button>
      </div>

      {/* Table Container */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white rounded-lg shadow-lg">
          <thead className="bg-gray-50">
            <tr>
              <th className="py-3 px-4 text-center text-base font-semibold text-gray-900">Index</th>
              <th className="py-3 px-4 text-left text-base font-semibold text-gray-900">Name</th>
              <th className="py-3 px-4 text-left text-base font-semibold text-gray-900">Email</th>
              <th className="py-3 px-4 text-left text-base font-semibold text-gray-900">Resume</th>
              <th className="py-3 px-4 text-left text-base font-semibold text-gray-900">Joined</th>
              <th className="py-3 px-4 text-right text-base font-semibold text-gray-900">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {userData.map((user, index) => (
              <tr key={user.user_id} className="hover:bg-gray-50">
                <td className="py-3 px-4 text-center text-base text-gray-500">
                  {index + 1}
                </td>
                <td className="py-3 px-4 text-base font-medium text-gray-900">
                  {user.full_name}
                </td>
                <td className="py-3 px-4 text-base text-gray-500">
                  {user.email}
                </td>
                <td className="py-3 px-4 text-base text-gray-500">
                  {user.resume_link ? (
                    <a
                      href={user.resume_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline"
                    >
                      View Resume
                    </a>
                  ) : (
                    <span className="text-gray-400">No Resume</span>
                  )}
                </td>
                <td className="py-3 px-4 text-base text-gray-500">
                  {new Date(user.joined).toLocaleDateString()}
                </td>
                <td className="py-3 px-4 text-right">
                  <div className="flex justify-end gap-2">
                    <button
                      onClick={() => {
                        if (
                          window.confirm(`Are you sure you want to approve ${user.full_name}?`)
                        ) {
                          accept(user);
                        }
                      }}
                      className="px-3 py-1 text-base text-white bg-green-600 hover:bg-green-700 rounded-md transition-colors"
                    >
                      Accept
                    </button>
                    <button
                      onClick={() => {
                        if (
                          window.confirm(`Are you sure you want to reject ${user.full_name}?`)
                        ) {
                          reject(user);
                        }
                      }}
                      className="px-3 py-1 text-base text-white bg-red-600 hover:bg-red-700 rounded-md transition-colors"
                    >
                      Reject
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* If no pending requests */}
      {userData.length === 0 && (
        <div className="text-center py-8 text-gray-500">
          No pending verification requests
        </div>
      )}
    </div>
  );
}
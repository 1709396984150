// // src/routes/profile/career/Education.tsx

import React, { useState, ChangeEvent, FormEvent } from "react";
import axiosInterceptor from "../../../utils/axiosInterceptor";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Form, Modal } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import FormInput from "../../../components/FormInput";
import { DateInput } from "../../../components/DateInput";
import { Row } from "react-bootstrap";
import { EducationItem } from "../interfaces";

interface EducationProps {
  eduList: EducationItem[];
}

export default function Education({ eduList }: EducationProps): JSX.Element {
  function EduItem({ item }: { item: EducationItem }): JSX.Element {
    const { school, degree, aos, minor, gpa, start_date, end_date } = item;

    const startYear = new Date(start_date).getFullYear();
    const endYear = end_date ? new Date(end_date).getFullYear() : "Present";
    const dateRange = `${startYear} – ${endYear}`;

    return (
      <div className="flex flex-row items-start gap-4 mb-6 last:mb-0">
        {/* School Logo/Initial */}
        <div className="flex-shrink-0 w-12 h-12 rounded-xl bg-red-50 flex items-center justify-center">
          <span className="text-red-600 text-lg font-semibold">{school.charAt(0)}</span>
        </div>
        
        {/* Main Content */}
        <div className="flex-grow">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-lg font-semibold text-gray-900">{school}</h3>
              <p className="text-base font-medium text-gray-700">
                {degree} - {aos}
                {minor && `, Minor in ${minor}`}
              </p>
              <div className="flex items-center gap-4 mt-1">
                <p className="text-sm text-gray-500">{dateRange}</p>
                {gpa && (
                  <>
                    <span className="text-gray-300">•</span>
                    <p className="text-sm text-gray-500">GPA: {gpa}</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const sortedEduList = [...eduList].sort((a, b) => 
    new Date(b.start_date).getTime() - new Date(a.start_date).getTime()
  );

  return (
    <div>
      {sortedEduList.map((item) => (
        <EduItem key={item.id} item={item} />
      ))}
    </div>
  );
}
// CreateEdu Component Props Interface
interface CreateEduProps {
  eduList: EducationItem[];
  setEducation: React.Dispatch<React.SetStateAction<EducationItem[]>>;
}

// CreateEdu Component
export function CreateEdu({ eduList, setEducation }: CreateEduProps): JSX.Element {
  const BLANK_DATA: EducationItem = {
    id: -1, // Temporary ID for new entries; ensure backend handles this appropriately
    school: "",
    degree: "BS",
    aos: "",
    start_date: "",
    end_date: null,
    // 'minor' and 'gpa' are optional and can be omitted or set to empty strings
  };

  const options = [
    'B.S.', 'B.A.', 'M.S.', 'M.A.', 'M.B.A.', 'M.Eng.', 'Ph.D.',
  ];

  // Initialize with existing eduList or a single blank entry
  const initialEduList = eduList && eduList.length > 0 ? eduList : [BLANK_DATA];

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');

  // Update a specific field in an education item
  function updateEducationItem(index: number, field: keyof EducationItem, value: string | null) {
    setEducation(prev => {
      const updatedList = [...prev];
      updatedList[index] = {
        ...updatedList[index],
        [field]: value,
      };
      return updatedList;
    });
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, index: number) => {
    const { name, value } = e.target;
    updateEducationItem(index, name as keyof EducationItem, value);
  };

  function handleRemove(itemToRemove: EducationItem) {
    if (itemToRemove.id !== -1) { // Only attempt to delete if it's an existing entry
      try {
        const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1] || '';
        axiosInterceptor.delete(`/delete_edu_entry/${itemToRemove.id}/`, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          }
        });
      } catch (error) {
        console.log('Error:', error);
      }
    }
    setEducation(prev => prev.filter(item => item.id !== itemToRemove.id));
  }

  function addNewEntry() {
    setEducation(prev => [...prev, { ...BLANK_DATA, id: -1 }]); // Add a new blank entry with temporary ID
  }

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (message: string) => {
    setModalMessage(message);
    setShowModal(true);
  };

  function validateFields(): boolean {
    for (const exp of eduList) {
      if (!exp.school.trim()) {
        handleShowModal("The 'school' field is required.");
        return false;
      }
      if (!exp.degree.trim()) {
        handleShowModal("The 'degree' field is required.");
        return false;
      }
      if (!exp.aos.trim()) {
        handleShowModal("The 'Area of Study' field is required.");
        return false;
      }
      if (!exp.start_date.trim()) {
        handleShowModal("The 'Start Date' field is required.");
        return false;
      }
      // Add more validations if necessary
    }
    return true;
  }

  const handleSubmit = async (event?: FormEvent) => {
    if (event) event.preventDefault();

    if (!validateFields()) {
      return;
    }

    try {
      const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1] || '';
      const user_id = localStorage.getItem('user_id');
      if (!user_id) {
        console.log("No user_id found in localStorage.");
        handleShowModal("User not authenticated.");
        return;
      }

      const promises = eduList.map(async (edu) => {
        console.log('Submitting:', edu);
        if (edu.id !== -1) { // Existing entry
          await axiosInterceptor.patch(`/update_edu/${edu.id}/`, edu, {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrfToken,
            }
          });
        } else { // New entry
          edu = { ...edu, user_id: user_id };
          const response = await axiosInterceptor.post(`/create_edu/${user_id}/`, edu, {
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrfToken,
            }
          });
          const newId = response.data.id;
          setEducation(prev => prev.map(item => item.id === -1 ? { ...item, id: newId } : item));
        }
      });

      await Promise.all(promises);
      window.location.reload();
    } catch (error) {
      console.log('Error:', error);
      handleShowModal("An error occurred while submitting the form.");
    }
  };

  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {eduList.map((item, index) => (
        <div key={item.id}>
          <Form onSubmit={handleSubmit}>
            <Stack gap={2}>
              <FormInput
                name="school"
                label="School"
                type="text"
                value={item.school}
                handler={(e) => handleChange(e, index)}
                required
              />
              <Form.Label style={{ marginBottom: '0%' }}>Degree:</Form.Label>
              <Form.Group controlId={`formDropdown-${item.id}`}>
                <Form.Control
                  as="select"
                  name="degree"
                  value={item.degree}
                  onChange={(e) => handleChange(e, index)}
                >
                  {options.map((degreeOpt, idx) => (
                    <option key={idx} value={degreeOpt}>{degreeOpt}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <FormInput
                name="aos"
                label="Area of Study"
                type="text"
                value={item.aos}
                handler={(e) => handleChange(e, index)}
                required
              />
              <FormInput
                name="minor"
                label="Minor"
                type="text"
                value={item.minor || ""}
                handler={(e) => handleChange(e, index)}
              />
              <FormInput
                name="gpa"
                label="GPA"
                type="text"
                value={item.gpa || ""}
                handler={(e) => handleChange(e, index)}
              />
              <DateInput
                sd={item.start_date}
                ed={item.end_date ?? undefined} // Ensure ed is string or undefined
                handler={(e) => handleChange(e, index)}
              />
              <Container style={{ textAlign: "center" }}>
                <p className="d-inline-block float-right p-0">
                  <Button
                    onClick={() => handleRemove(item)}
                    style={{ padding: '16px', fontSize: '8px' }}
                  >
                    Remove
                  </Button>
                </p>
              </Container>
              <h2> </h2>
            </Stack>
          </Form>
        </div>
      ))}
      <Container className="d-flex justify-content-center align-items-center">
        <Row className="text-center">
          <Button onClick={addNewEntry} style={{ marginBottom: '25px' }}>Add New</Button>
          <Button onClick={handleSubmit}>Done</Button>
        </Row>
      </Container>
    </div>
  );
}

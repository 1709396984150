/**
 * interfaces.ts
 * Central file for all type definitions, interfaces, and constants.
 */

//
// Company interface
//
export interface Company {
    id: number;
    company_name: string;
    domain: string;
    industry: string;
    location: string;
    company_size: string;
    company_description: string;
    contact_email: string;
  }
  
  //
  // Job interface
  //
  export interface Job {
    id: number;
    title: string;
    city: string;
    timestamp: string;
    compensationLowerBound: number;
    compensationUpperBound: number;
    description: string;
    jobStartDate: string;
    // Custom field for interviews:
    interviewingStartDate?: string;
    eligible_for_visa_sponsorship: boolean;
    status: string; // e.g. "active" or "archived"
    num_users?: number; // how many applicants, etc.
  
    benefits?: string;
    rounds?: {
      id: number;
      name: string;
    }[];
  
    // user-specific data
    user_pipeline_status?: string;  // e.g., "New Matches", "Messages", etc.
    user_rating_of_job?: number;    // e.g., 1, 3, 5
  
    // If referencing child structures, e.g.:
    company_details?: {
      company_name: string;
      industry: string;
      company_description: string;
      company_size: string;
    };
  }
  
  //
  // Unified categories for job tabs
  //
  export type JobCategory =
    | "New Matches"
    | "Recommended"
    | "Likes You"
    | "Messages"
    | "Referral"
    | "Not Interested"
    | "Interested"
    | "Very Interested"
    | "Offer";
  
  //
  // The default order of job categories
  //
  export const CATEGORY_ORDER: JobCategory[] = [
    "Likes You",
    "Recommended",
    "Not Interested",
    "Interested",
    "Very Interested",
    "New Matches",
    "Messages",
    "Referral",
    "Offer",
  ];
  
  //
  // SearchParams interface
  //
  export interface SearchParams {
    companyName: string;
    industry: string;
    location: string;
  }
  
  //
  // MergedCompanyJobsSection Props
  //
  export interface MergedCompanyJobsSectionProps {
    categories: JobCategory[];
    jobs: Job[];
    onRateJob: (jobId: number, rating: number) => Promise<void>;
  }
  
  //
  // JobCard Props (purely display-oriented)
  //
  export interface JobCardProps {
    job: Job;
    isDescriptionExpanded: boolean;
    onToggleExpand: (jobId: number) => void;
  }
  
import { FC } from "react";
import {
  DefaultChip,
  NegativeChip,
  PrimaryChip,
  PositiveChip,
} from "../../components/Chip";
import { CourseStatusChipProps } from "../../types/interfaces";

const CourseStatusChip: FC<CourseStatusChipProps> = ({
  courseStatusChipProps,
}) => {
  if (courseStatusChipProps) {
    if (courseStatusChipProps.courseProgress >= 1)
      return <PrimaryChip message="Mastered"></PrimaryChip>;
    else if (courseStatusChipProps.needsMorePrerequisites)
      return <NegativeChip message="Needs More Prerequisites"></NegativeChip>;
    else if (
      courseStatusChipProps.courseProgress > 0 &&
      courseStatusChipProps.courseProgress < 1
    )
      return <PositiveChip message="In Progress"></PositiveChip>;
    else if (courseStatusChipProps.isRecommended)
      return <PositiveChip message="Recommended"></PositiveChip>;
    else return <DefaultChip message="Not Started"></DefaultChip>;
  } else
    return <DefaultChip message="Loading Course Data&hellip;"></DefaultChip>;
};

export default CourseStatusChip;

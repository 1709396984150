import { FC } from "react";
import { PdfIframe, Video } from "../routes/courses/lectures/LectureMedia";
import { FileURLViewerProps } from "../types/interfaces";

/**
 * FileURLViewer component
 * @component
 * @param {Object} props - The props for the FileURLViewer component
 * @param {string} props.url - The URL of the file to be displayed
 * @param {LectureContentType} props.fileType - The type of the file (e.g., "pdf", "video")
 * @returns {ReactElement} A component that displays the appropriate viewer based on the file type
 */
const FileURLViewer: FC<FileURLViewerProps> = ({ url, fileType }) => {
  return (
    <div className="mx-auto my-auto items-center justify-center w-full h-full">
      <div className="tab overflow-auto w-full h-full">
        {fileType === "pdf" ? (
          <PdfIframe pdfUrl={url} />
        ) : fileType === "video" ? (
          <Video videoUrl={url} />
        ) : (
          <div>Unsupported content type.</div>
        )}
      </div>
    </div>
  );
};

export default FileURLViewer;

import React from 'react';
import HeroSection from './HeroSection';
import TestimonialsSection from './TestimonialsSection';
import FAQSection from './FAQSection';
import ProcessSteps from './ProcessSteps';
import RecruitmentSection from './RecruitmentSection';
import RecruitmentEndSection from './RecruitmentEndSection';
import Footer from './Footer'


const Home: React.FC = () => {
  return (
    <div className="bg-white">
      <div id="home">
        <HeroSection />
      </div>
      
      <div id="who-we-are">
        <RecruitmentSection />
      </div>
      
      <div id="getting-started">
        <ProcessSteps />
      </div>
            
      <div id="testimonials">
        <TestimonialsSection />
      </div>


      <div id="faqs">
        <RecruitmentEndSection />
      </div>
      
      <div id="faqs">
        <FAQSection />
      </div>

      <div id="faqs">
        <Footer />
        </div>
    </div>
  );
};

export default Home;
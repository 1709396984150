import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInterceptor from "../../utils/axiosInterceptor";

interface RequestPayload {
    num_samples: number;
    subjects: string[];
}

const TechnicalHome: React.FC = () => {
    const navigate = useNavigate();
    const [selectedSubjects, setSelectedSubjects] = useState<string[]>([]);
    const [selectedDifficulty, setSelectedDifficulty] = useState<string>("");
    const [showAlert, setShowAlert] = useState<boolean>(false);

    const subjects = [
        { id: "mathcompetition", label: "Math Competition" },
        { id: "logicreasoning", label: "Logic/Reasoning" },
        { id: "fundamentalfinance", label: "Fundamental Finance" },
        { id: "specializedfinance", label: "Specialized Finance" },
        { id: "codingsyntaxproficiency", label: "Coding Syntax Proficiency" },
        { id: "algorithmsdatastructures", label: "Algorithms/Data Structures" },
        { id: "backend", label: "Backend" },
        { id: "gametheory", label: "Game Theory" },
        { id: "marketmaking", label: "Market Making" },
        { id: "statistics", label: "Statistics" },
        { id: "probability", label: "Probability" },
        { id: "fundamentalmath", label: "Fundamental Math" },
        { id: "basicmachinelearning", label: "Basic Machine Learning" },
        { id: "complexmachinelearning", label: "Complex Machine Learning" }
    ];

    const difficulties = [
        { id: "easy", label: "Easy" },
        { id: "medium", label: "Medium" },
        { id: "hard", label: "Hard" }
    ];

    const toggleSubject = (subjectId: string) => {
        setSelectedSubjects(prev =>
            prev.includes(subjectId)
                ? prev.filter(id => id !== subjectId)
                : [...prev, subjectId]
        );
    };

    const handleStartInterview = async () => {
        if (!selectedSubjects.length || !selectedDifficulty) {
            setShowAlert(true);
            return;
        }

        setShowAlert(false);
        const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
        const request: RequestPayload = {
            num_samples: 5,
            subjects: selectedSubjects,
        };

        try {
            const response = await axiosInterceptor.post("/sample_questions/", request, {
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrfToken || "",
                },
            });
            navigate("/test-center/technical", { state: response.data });
        } catch (error) {
            console.error("Error during interview initiation:", error);
            navigate("/test-center/technical");
        }
    };

    return (
        <div className="min-h-screen bg-[#F9F9F9]">
            <div className="max-w-7xl mx-auto px-8 pt-8 pb-16">
                <div className="space-y-6">
                    <h2 className="text-xl font-semibold">Technical Exam</h2>

                    <div className="bg-[#F9F9F9] rounded-lg space-y-8">
                        {/* Instructions */}
                        <div className="space-y-2">
                            <h3 className="font-medium">Instructions</h3>
                            <p className="text-gray-600">
                                Choose a difficulty and select subjects before you start the interview.
                                This is to tailor your questions to your interests and skill level.
                            </p>
                        </div>

                        {/* Subjects Selection */}
                        <div className="space-y-3">
                            <label className="block text-sm font-medium">
                                Choose subjects (select all that apply):
                            </label>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                                {subjects.map(subject => (
                                    <button
                                        key={subject.id}
                                        onClick={() => toggleSubject(subject.id)}
                                        className={`w-full text-left p-3 rounded-lg border transition-colors ${
                                            selectedSubjects.includes(subject.id)
                                                ? "bg-blue-50 border-blue-500"
                                                : "bg-white border-gray-200 hover:bg-gray-50"
                                        }`}
                                    >
                                        {subject.label}
                                    </button>
                                ))}
                            </div>
                        </div>

                        {/* Difficulty Selection */}
                        <div className="space-y-3">
                            <label className="block text-sm font-medium">
                                Choose a difficulty:
                            </label>
                            <div className="space-y-2">
                                {difficulties.map(difficulty => (
                                    <button
                                        key={difficulty.id}
                                        onClick={() => setSelectedDifficulty(difficulty.id)}
                                        className={`w-full text-left p-3 rounded-lg border transition-colors ${
                                            selectedDifficulty === difficulty.id
                                                ? "bg-blue-50 border-blue-500"
                                                : "bg-white border-gray-200 hover:bg-gray-50"
                                        }`}
                                    >
                                        {difficulty.label}
                                    </button>
                                ))}
                            </div>
                        </div>

                        {/* Start Button */}
                        <div className="flex justify-end">
                            <button
                                onClick={handleStartInterview}
                                className="px-6 py-2 rounded-full bg-blue-600 text-white hover:bg-blue-700 transition-colors"
                            >
                                Start Interview
                            </button>
                        </div>

                        {/* Alert */}
                        {showAlert && (
                            <div className="p-4 bg-red-50 border border-red-200 text-red-700 rounded-lg">
                                Please choose at least one subject and a difficulty level before starting the interview.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TechnicalHome;
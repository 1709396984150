
// Interactions Data
const interactionsOverview = {
    candidatesRatedJobs: 25,
    referrersRatedCandidates: 30,
    messagesExchanged: 310,
    referralsFinalized: 15,
    offersGiven: 5,
  };
  
  const interviewInsights = {
    interviewsGiven: 25,
    avgTimeUntilOffer: '13 Days',
    avgSuccessRate: '25%',
  };
  
  const recentHires = [
    { university: 'Cornell University', total: 280 },
    { university: 'Brown University', total: 72 },
    { university: 'UPenn', total: 32 },
    { university: 'Stanford University', total: 80 },
    { university: 'Harvard University', total: 94 },
  ];
  
  const topRatedJobs = [
    { company: 'Meta', role: 'Data Science Analyst', location: 'California', salary: '$100,000' },
    { company: 'Meta', role: 'Data Science Analyst', location: 'California', salary: '$100,000' },
    { company: 'Meta', role: 'Data Science Analyst', location: 'California', salary: '$100,000' },
  ];
  
  // ========== PAGES ==========
  
  
  
  
  
  /** Interactions Page */
  const InteractionsPage = () => {
    return (
      <div className="space-y-6">
        {/* Row 1: Overview & Recent Hires */}
        <div className="grid grid-cols-3 gap-6">
          
          {/* CARD: Overview */}
          <div className="col-span-2 bg-white rounded-xl shadow-sm p-4">
            <div className="mb-4">
              <h2 className="text-lg font-semibold">Overview</h2>
              <p className="text-sm text-gray-500">March - August 2024</p>
            </div>
            <div className="space-y-2 text-sm">
              <div className="grid grid-cols-2">
                <span>Candidates Rated Jobs:</span>
                <span>{interactionsOverview.candidatesRatedJobs}</span>
              </div>
              <div className="grid grid-cols-2">
                <span>Referrers Rated Candidates:</span>
                <span>{interactionsOverview.referrersRatedCandidates}</span>
              </div>
              <div className="grid grid-cols-2">
                <span>Messages Exchanged:</span>
                <span>{interactionsOverview.messagesExchanged}</span>
              </div>
              <div className="grid grid-cols-2">
                <span>Referrals Finalized:</span>
                <span>{interactionsOverview.referralsFinalized}</span>
              </div>
              <div className="grid grid-cols-2">
                <span>Offers Given:</span>
                <span>{interactionsOverview.offersGiven}</span>
              </div>
            </div>
          </div>
  
          {/* CARD: Recent Hires */}
          <div className="bg-white rounded-xl shadow-sm p-4">
            <div className="mb-4">
              <h2 className="text-lg font-semibold">Recent Hires</h2>
              <p className="text-sm text-gray-500">March - August 2024</p>
            </div>
            <div className="space-y-3 text-sm">
              {recentHires.map((hire) => (
                <div
                  key={hire.university}
                  className="flex justify-between items-center"
                >
                  <span>{hire.university}</span>
                  <span>{hire.total}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
  
        {/* Row 2: Interview Insights & Top Rated Jobs */}
        <div className="grid grid-cols-3 gap-6">
          {/* CARD: Interview Insights */}
          <div className="bg-white rounded-xl shadow-sm p-4">
            <h2 className="text-lg font-semibold mb-4">Interview Insights</h2>
            <div className="space-y-2 text-sm">
              <div className="grid grid-cols-2">
                <span>Interviews Given:</span>
                <span>{interviewInsights.interviewsGiven}</span>
              </div>
              <div className="grid grid-cols-2">
                <span>Average Time until Offer:</span>
                <span>{interviewInsights.avgTimeUntilOffer}</span>
              </div>
              <div className="grid grid-cols-2">
                <span>Average Success Rate:</span>
                <span>{interviewInsights.avgSuccessRate}</span>
              </div>
            </div>
          </div>
  
          {/* CARD: Top Rated Jobs */}
          <div className="col-span-2 bg-white rounded-xl shadow-sm p-4">
            <h2 className="text-lg font-semibold mb-4">Top Rated Jobs</h2>
            <table className="w-full text-sm">
              <thead>
                <tr className="border-b border-gray-100 text-gray-500">
                  <th className="py-2 text-left font-medium w-8">#</th>
                  <th className="py-2 text-left font-medium">Role</th>
                  <th className="py-2 text-left font-medium">Location</th>
                  <th className="py-2 text-left font-medium">Salary</th>
                  <th className="py-2"></th>
                </tr>
              </thead>
              <tbody>
                {topRatedJobs.map((job, index) => (
                  <tr
                    key={index}
                    className="border-b border-gray-50 hover:bg-gray-50 transition-colors"
                  >
                    <td className="py-2">{index + 1}</td>
                    <td className="py-2">
                      <div className="flex items-center gap-2">
                        <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center">
                          <span className="text-blue-600 text-xs">M</span>
                        </div>
                        {job.role}
                      </div>
                    </td>
                    <td className="py-2">{job.location}</td>
                    <td className="py-2">{job.salary}</td>
                    <td className="py-2 text-right">
                      <button className="px-3 py-1 bg-blue-900 text-white text-xs rounded hover:bg-blue-800">
                        View Role
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

export default InteractionsPage;
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface TestOption {
  title: string;
  duration: string;
  description: string;
  route: string;
}

const TestCard: React.FC<{ test: TestOption }> = ({ test }) => {
  const navigate = useNavigate();
  
  return (
    <div className="bg-white rounded-xl shadow-sm flex flex-col h-full border border-gray-200 overflow-hidden">
      {/* Header section */}
      <div className="p-6 border-b border-gray-100">
        <div className="flex justify-between items-center">
          <h3 className="font-semibold text-lg text-gray-900">{test.title}</h3>
          <span className="text-gray-500 text-sm">{test.duration}</span>
        </div>
      </div>
      
      {/* Description section */}
      <div className="p-6 flex-grow">
        <p className="text-gray-600 text-sm leading-relaxed">
          {test.description}
        </p>
      </div>
      
      {/* Action section */}
      <div className="p-6 border-t border-gray-100 bg-gray-50">
        <div className="flex justify-end">
          <button
            onClick={() => navigate(test.route)}
            className="bg-blue-600 text-white rounded-lg py-2 px-8 hover:bg-blue-700 transition-colors text-sm font-medium"
          >
            Start
          </button>
        </div>
      </div>
    </div>
  );
};

export default TestCard;
import React, { useEffect, useState, useRef } from 'react';

export default function InfiniteMovingCards({
    items,
    direction = "left",
    speed = "fast",
    pauseOnHover = true,
    className,
}: {
    items: {
        quote: string;
        name: string;
        title: string;
        imageSrc?: string;
    }[];
    direction?: "left" | "right";
    speed?: "fast" | "normal" | "slow";
    pauseOnHover?: boolean;
    className?: string;
}) {
    const containerRef = useRef<HTMLDivElement>(null);
    const scrollerRef = useRef<HTMLUListElement>(null);
    const [start, setStart] = useState(false);

    useEffect(() => {
        addAnimation();
    }, []);

    function addAnimation() {
        if (containerRef.current && scrollerRef.current) {
            const scrollerContent = Array.from(scrollerRef.current.children);

            scrollerContent.forEach((item) => {
                const duplicatedItem = item.cloneNode(true);
                if (scrollerRef.current) {
                    scrollerRef.current.appendChild(duplicatedItem);
                }
            });

            getDirection();
            getSpeed();
            setStart(true);
        }
    }

    const getDirection = () => {
        if (containerRef.current) {
            containerRef.current.style.setProperty(
                "--animation-direction",
                direction === "left" ? "forwards" : "reverse"
            );
        }
    };

    const getSpeed = () => {
        if (containerRef.current) {
            const speedMap = {
                fast: "20s",
                normal: "40s",
                slow: "80s"
            };
            containerRef.current.style.setProperty(
                "--animation-duration",
                speedMap[speed]
            );
        }
    };

    return (
        <div
            ref={containerRef}
            className={`scroller relative z-20 max-w-7xl overflow-hidden [mask-image:linear-gradient(to_right,transparent,white_20%,white_80%,transparent)] ${className}`}
        >
            <ul
                ref={scrollerRef}
                className={`flex min-w-full shrink-0 gap-6 py-4 w-max flex-nowrap
                    ${start ? "animate-scroll" : ""} 
                    ${pauseOnHover ? "hover:[animation-play-state:paused]" : ""}`}
            >
                {items.map((item, idx) => (
                    <li
                        key={idx}
                        className="w-[350px] max-w-full flex-shrink-0"
                    >
                        <div className="bg-gradient-to-br from-gray-100 to-gray-300 rounded-xl p-8 h-full">
                            <div className="flex flex-col h-full">
                                <p className="text-gray-900 text-lg mb-8 flex-grow">"{item.quote}"</p>
                                <div className="flex items-center gap-3">
                                    {item.imageSrc ? (
                                        <img 
                                            src={item.imageSrc} 
                                            alt={item.name} 
                                            className="w-8 h-8 rounded-full object-cover" 
                                        />
                                    ) : (
                                        <div className="w-8 h-8 rounded-full bg-blue-200" />
                                    )}
                                    <div>
                                        <p className="font-medium text-gray-900">{item.name}</p>
                                        <p className="text-sm text-gray-600">{item.title}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}
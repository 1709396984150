import { DefaultButton } from "../../../components/Button";
import { LectureContent } from "../../../types/interfaces";

interface PdfIframeProps {
  pdfUrl: string;
}

export function PdfIframe({ pdfUrl }: PdfIframeProps): JSX.Element {
  return (
    <div className="flex-column items-center w-full h-full m-0">
      <iframe title="PDF Viewer" className="w-full h-full m-0 p-0" src={pdfUrl}>
        This browser does not support PDFs. Please download the PDF to view it:{" "}
        <a href={pdfUrl}>Download PDF</a>.
      </iframe>
    </div>
  );
}

interface VideoProps {
  videoUrl: string;
}

export function Video({ videoUrl }: VideoProps): JSX.Element {
  return (
    <video controls className="h-full w-full" src={videoUrl}>
      Sorry, your browser doesn't support embedded videos.
    </video>
  );
}

interface ResourcesProps {
  content: LectureContent;
  mainDownloadURL: string;
}

export function Resources({
  content,
  mainDownloadURL,
}: ResourcesProps): JSX.Element {
  const resourcesTab = content.tabs.find((tab) => tab.name === "Resources");
  const resourcesDescription = resourcesTab?.description || [];

  const titleCase = (input: string): string => {
    return input
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const mainFileName = mainDownloadURL.split("/").pop() ?? "";
  const displayName = titleCase(
    mainFileName.replace(/_/g, " ").split(".")[0] || ""
  )
    .replace("Problems", "Problem")
    .replace("Notes", "Note")
    .replace("Videos", "Video");

  return (
    <div className="flex flex-col gap-2">
      <DefaultButton
        message={`Download ${displayName}`}
        handleClick={() => {
          window.open(mainDownloadURL, "_blank")?.focus();
        }}
      ></DefaultButton>
      {resourcesDescription.map((url, index) => (
        <DefaultButton
          key={index}
          handleClick={() => {
            window.open(url, "_blank")?.focus();
          }}
          message={`Download Support File ${index + 1}`}
        ></DefaultButton>
      ))}
    </div>
  );
}

import { FC, useState } from "react";
import {
  SegmentedControlItem,
  SegmentedControlProps,
} from "../types/interfaces";

/**
 * SegmentedControl component
 * @component
 * @param {Object} props The props for the SegmentedControl component
 * @param {string} [props.defaultValue] The default selected value
 * @param {SegmentedControlItem[]} props.controlItems Array of items to be displayed in the control
 * @param {function} props.handleValueSelected Callback function when a value is selected
 * @returns {ReactElement} An elements that renders each value as a button and highlights selected values
 */
const SegmentedControl: FC<SegmentedControlProps> = ({
  defaultValue,
  controlItems,
  handleValueSelected,
}) => {
  const [selectedValue, setSelectedValue] = useState<SegmentedControlItem>(
    () => {
      if (defaultValue) {
        return (
          controlItems.find((item) => {
            if (typeof item === "object") {
              return item.value === defaultValue;
            } else return item === defaultValue;
          }) ?? defaultValue
        );
      } else return controlItems[0];
    }
  );

  return (
    <div className="flex flex-row items-center gap-1 bg-background-dimmer border border-background-dimmest rounded-md h-min p-[0.125rem]">
      {controlItems.map((item, index) => {
        const value = typeof item === "string" ? item : item.value;
        return (
          <button
            key={index}
            onClick={() => {
              handleValueSelected(value);
              setSelectedValue(value);
            }}
            className={`
                  flex flex-row h-7 w-52 items-center justify-center gap-2 rounded-md
                  text-sm font-medium p-2 transition-colors 
                  ${
                    value === selectedValue
                      ? "text-foreground bg-background shadow-sm"
                      : "text-foreground-dimmer bg-background-dimmer hover:bg-background-dimmest"
                  }
                `}
          >
            {typeof item === "object" ? item.icon : <></>}
            <span>{value}</span>
          </button>
        );
      })}
    </div>
  );
};

export default SegmentedControl;

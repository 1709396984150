
import {
    Building2,
  } from 'lucide-react';
// Companies Data
const companiesOverview = {
    allCompanies: 90,
    activeJobPostings: 56,
    newJobPostings: 56,
  };
  
  const topIndustries = [
    { name: 'Technology', total: 40 },
    { name: 'Business', total: 30 },
    { name: 'Job Search & Career', total: 20 },
  ];
  
  const topCompanies = [
    { name: 'Algoinvest', jobListings: 100, recruiters: 6, referrers: 5 },
    { name: 'Algoinvest', jobListings: 100, recruiters: 6, referrers: 5 },
    { name: 'Algoinvest', jobListings: 100, recruiters: 6, referrers: 5 },
    { name: 'Algoinvest', jobListings: 100, recruiters: 6, referrers: 5 },
    { name: 'Algoinvest', jobListings: 100, recruiters: 6, referrers: 5 },
    { name: 'Algoinvest', jobListings: 100, recruiters: 6, referrers: 5 },
  ];
  

/** Companies Page */
const CompaniesPage = () => {
    return (
      <div className="space-y-6">
        {/* Row 1: Overview & Top Industries */}
        <div className="grid grid-cols-3 gap-6">
          
          {/* CARD: Overview */}
          <div className="col-span-2 bg-white rounded-xl shadow-sm p-4">
            <h2 className="text-lg font-semibold mb-4">Overview</h2>
            <div className="grid grid-cols-3 gap-8">
              <div>
                <p className="text-gray-500 text-sm mb-1">All Companies</p>
                <p className="text-2xl font-semibold">{companiesOverview.allCompanies}</p>
              </div>
              <div>
                <p className="text-gray-500 text-sm mb-1">Active Job Postings</p>
                <p className="text-2xl font-semibold">{companiesOverview.activeJobPostings}</p>
              </div>
              <div>
                <p className="text-gray-500 text-sm mb-1">New Job Postings</p>
                <p className="text-2xl font-semibold">{companiesOverview.newJobPostings}</p>
              </div>
            </div>
          </div>
  
          {/* CARD: Top Industries */}
          <div className="bg-white rounded-xl shadow-sm p-4">
            <h2 className="text-lg font-semibold mb-4">Top Industries</h2>
            <div className="space-y-3">
              {topIndustries.map((industry, index) => (
                <div
                  key={industry.name}
                  className="flex justify-between items-center text-sm"
                >
                  <span>{index + 1}. {industry.name}</span>
                  <span>{industry.total}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
  
        {/* CARD: Top Companies */}
        <div className="bg-white rounded-xl shadow-sm p-4">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold">Top Companies</h2>
            <div className="flex gap-2">
              <select className="px-3 py-2 text-sm bg-white border border-gray-200 rounded-md">
                <option>Industry</option>
              </select>
              <select className="px-3 py-2 text-sm bg-white border border-gray-200 rounded-md">
                <option>Company Size</option>
              </select>
              <input
                type="text"
                placeholder="Search"
                className="px-3 py-2 text-sm bg-white border border-gray-200 rounded-md
                           focus:outline-none focus:border-blue-400 focus:ring focus:ring-blue-50"
              />
            </div>
          </div>
          <table className="w-full text-sm">
            <thead>
              <tr className="border-b border-gray-100 text-gray-500">
                <th className="py-2 text-left font-medium w-8">#</th>
                <th className="py-2 text-left font-medium">Company</th>
                <th className="py-2 text-center font-medium">Job Listings</th>
                <th className="py-2 text-center font-medium">Recruiters</th>
                <th className="py-2 text-center font-medium">Referrers</th>
                <th className="py-2"></th>
              </tr>
            </thead>
            <tbody>
              {topCompanies.map((company, index) => (
                <tr
                  key={index}
                  className="border-b border-gray-50 hover:bg-gray-50 transition-colors"
                >
                  <td className="py-2">{index + 1}</td>
                  <td className="py-2">
                    <div className="flex items-center gap-2">
                      <div className="w-8 h-8 bg-gray-100 rounded-full flex items-center justify-center">
                        <Building2 size={16} className="text-gray-500" />
                      </div>
                      {company.name}
                    </div>
                  </td>
                  <td className="py-2 text-center">{company.jobListings}</td>
                  <td className="py-2 text-center">{company.recruiters}</td>
                  <td className="py-2 text-center">{company.referrers}</td>
                  <td className="py-2 text-right">
                    <button className="text-blue-600 text-xs hover:underline">
                      View More
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

export default CompaniesPage;
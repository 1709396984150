import React from "react";
import { ResumeProps, CreateResProps } from "../interfaces";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import { Upload } from "../../../components/Upload";
import { File } from "lucide-react";

const ResumeComponent: React.FC<ResumeProps> = ({ resume }) => {
  return (
    <div className="flex flex-col gap-3">
      {resume.map((res, index) => (
        <div key={index} className="flex flex-row items-start gap-4">
          {/* File Icon */}
          <div className="flex-shrink-0 w-12 h-12 rounded-xl bg-green-50 flex items-center justify-center">
            <File className="w-6 h-6 text-green-600" />
          </div>
          
          {/* File Info */}
          <div className="flex-grow">
            <a 
              href="https://google.com"
              target="_blank"
              rel="noopener noreferrer"
              className="group"
            >
              <h3 className="text-lg font-semibold text-gray-900 group-hover:text-blue-600">
                {res.title}
              </h3>
              <p className="text-sm text-gray-500 mt-1">
                Click to view document
              </p>
            </a>
          </div>
        </div>
      ))}
      {resume.length === 0 && (
        <div className="text-lg text-gray-500">
          No resume uploaded yet
        </div>
      )}
    </div>
  );
};

const CreateRes: React.FC<CreateResProps> = ({ resume, userId }) => {
  return (
    <Form>
      <Stack gap={2}>
        <Upload
          userId={userId}
          rootDirectory="resumes"
          uploadEndpoint={`file_upload/`}
        />
      </Stack>
    </Form>
  );
};

export default ResumeComponent;
export { CreateRes };


// components/NavigationControl.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { NavItem } from './interfaces';
import SwapIcon from "../../assets/icons/swap-icon.png";

const NavigationControl: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string>('who-we-are');

  const navItems: NavItem[] = [
    { id: 'who-we-are', label: 'Who We Are' },
    { id: 'getting-started', label: 'Getting Started' },
    { id: 'testimonials', label: 'Testimonials' },
    { id: 'faqs', label: 'FAQs' }
  ];

  const scrollToSection = useCallback((sectionId: string): void => {
    setActiveSection(sectionId);
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    const handleScroll = (): void => {
      const sections = navItems.map(item => document.getElementById(item.id));
      const scrollPosition = window.scrollY + 100;

      sections.forEach((section) => {
        if (section) {
          const sectionTop = section.offsetTop;
          const sectionHeight = section.clientHeight;
          
          if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
            setActiveSection(section.id);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [navItems]);

  return (
    <div className="flex items-center space-x-6">
      {navItems.map(({ id, label }) => (
        <button
          key={id}
          onClick={() => scrollToSection(id)}
          className={`
            text-base font-medium transition-all duration-200
            ${activeSection === id 
              ? 'text-white bg-white/5 px-4 py-2 rounded-lg' 
              : 'text-[#94A3B8] hover:text-white'
            }
          `}
        >
          {label}
        </button>
      ))}
      
      <Link 
        to="https://enterprise.algolink.net" 
        className="no-underline flex items-center space-x-2 px-4 py-2 text-[#94A3B8] hover:text-white transition-all duration-200"
      >
        <img src={SwapIcon} alt="Swap" className="w-10 h-10" />
        <span>Give Referrals</span>
      </Link>
    </div>
  );
};

export default NavigationControl;
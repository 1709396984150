import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Nav } from "react-bootstrap";
import {
  Person,
  ClipboardCheck,
  Compass,
  Search,
  ChatDots,
  Bell,
  Gear,
  PieChartFill,
  BoxArrowRight,
} from "react-bootstrap-icons";
import axiosInterceptor from "../../utils/axiosInterceptor";
import ArrowImage from "../../assets/img/gallery/arrow-collapse.png";

// Logo images (ensure these paths are correct)
import Logo from "../../assets/img/home/logo-alt.png";
import LogoAlt from "../../assets/img/home/logo.png";
import LogoIcon from "../../assets/img/home/logo-icon.png";

interface HeaderProps {
  isUserLoggedIn: boolean;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
}

interface Page {
  icon: React.ReactNode;
  text: string;
  link: string;
  adminOnly?: boolean;
  action?: () => void;
}

const Header: React.FC<HeaderProps> = ({
  isUserLoggedIn,
  isSidebarOpen,
  toggleSidebar,
}) => {
  const location = useLocation();
  const [isToggleVisible, setIsToggleVisible] = useState(false);

  async function logoutUser() {
    try {
      await axiosInterceptor.post(`/logout/`, {
        refresh_token: localStorage.getItem("refresh_token"),
      });
    } catch (error: unknown) {
      console.error(error);
    }
    localStorage.clear();
    window.location.reload();
  }

  const loggedOutBtns = (
    <Nav className="flex flex-row -space-x-2">
      <Nav.Link as={Link} to="/account/login">
        <button className="rounded-full px-4 py-1.5 border border-special-blue text-special-blue font-light text-sm">
          Log In
        </button>
      </Nav.Link>
      <Nav.Link as={Link} to="/account/signup">
        <button className="rounded-full px-4 py-1.5 bg-special-blue text-white font-light text-sm">
          Sign Up
        </button>
      </Nav.Link>
    </Nav>
  );

  const loggedInPages: Page[] = [
    { icon: <Person />, text: "Profile", link: "/profile" },
    { icon: <ClipboardCheck />, text: "Tests", link: "/test-center/home" },
    { icon: <Compass />, text: "Courses", link: "/courses" },
    { icon: <Search />, text: "Jobs", link: "/jobs" },
    { icon: <ChatDots />, text: "Messages", link: "/chat" },
    // { icon: <Bell />, text: "Notifications", link: "/notifications" },
    {
      icon: <Gear />,
      text: "Management",
      link: "/management",
      adminOnly: true,
    },
    // {
    //   icon: <PieChartFill />,
    //   text: "Insights",
    //   link: "/insights",
    //   adminOnly: true,
    // },
  ];

  const accountPages: Page[] = [
    { icon: <BoxArrowRight />, text: "Logout", link: "#", action: logoutUser },
  ];

  return (
    <>
      {isUserLoggedIn ? (
        <Nav
          id="sidebar"
          className={`
            flex flex-col z-20 transition-all duration-250 ease-out 
            fixed left-0 top-[0px] bg-[#002359] 
            ${isSidebarOpen ? "w-72 py-2 px-3" : "w-16 py-2 px-2"}
            h-[calc(100vh)]
          `}
          onMouseEnter={() => setIsToggleVisible(true)}
          onMouseLeave={() => setIsToggleVisible(false)}
        >
          <div className="flex items-center justify-between w-full">
            {isSidebarOpen ? (
              <Nav.Link as={Link} to="/" className="!font-medium">
                <div className="flex items-center">
                  <img
                    src={Logo}
                    alt="AlgoLink Full Logo"
                    className="w-36 align-top d-inline-block"
                  />
                </div>
              </Nav.Link>
            ) : (
              <div className="w-8 h-8 mx-auto">
                <img
                  src={LogoIcon}
                  alt="AlgoLink Icon"
                  className="w-full h-full object-contain"
                />
              </div>
            )}
            {/* Collapse Arrow Button (always hidden except on hover) */}
            <button
              onClick={toggleSidebar}
              className={`
                absolute top-1/2 -right-7 transform -translate-y-1/2 
                text-white transition-opacity duration-200
                ${isToggleVisible ? "opacity-100" : "opacity-0"}
              `}
            >
              <div
                className="h-14 w-14 bg-[#002359] flex items-center justify-center"
                style={{
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  borderTopRightRadius: "9999px",
                  borderBottomRightRadius: "9999px",
                }}
              >
                <img
                  src={ArrowImage}
                  alt="Collapse Arrow"
                  className={`
                    w-6 h-6 transform transition-transform duration-250
                    ${isSidebarOpen ? "rotate-0" : "rotate-180"}
                  `}
                />
              </div>
            </button>
          </div>

          <Nav
            className={`flex flex-col mt-4 w-full ${
              !isSidebarOpen ? "items-center" : ""
            }`}
          >
            {loggedInPages
              .filter(
                (tab) =>
                  !tab.adminOnly ||
                  localStorage.getItem("is_algolink_admin") === "true"
              )
              .map((page, index) => {
                const isActive = location.pathname.includes(page.link);
                return (
                  <Nav.Link
                    key={index}
                    as={Link}
                    to={page.link}
                    className={`
                      flex items-center w-full !py-2 !my-1 px-3 rounded-lg
                      ${
                        isActive
                          ? "bg-[#0059EC]"
                          : "bg-[#002359] hover:bg-[#0059EC]/50"
                      }
                      ${!isSidebarOpen ? "justify-center" : ""}
                    `}
                    onClick={page.action}
                  >
                    <span
                      className={`flex flex-row ${
                        isSidebarOpen ? "gap-x-3" : ""
                      } items-center`}
                    >
                      <span
                        className={`${
                          isActive ? "text-[#FFFFFF]" : "text-[#A6C8FF]"
                        } text-xl`}
                      >
                        {page.icon}
                      </span>
                      {isSidebarOpen && (
                        <span className="text-base font-semibold text-white">
                          {page.text}
                        </span>
                      )}
                    </span>
                  </Nav.Link>
                );
              })}
          </Nav>

          <div className="flex-grow" />

          <Nav
            className={`flex flex-col mb-2 w-full ${
              !isSidebarOpen ? "items-center" : ""
            }`}
          >
            {accountPages.map((page, index) => (
              <Nav.Link
                key={index}
                as={Link}
                to={page.link}
                className={`
                  flex items-center text-white !py-2 !my-1 px-3 w-full rounded-lg
                  ${
                    !isSidebarOpen ? "justify-center" : ""
                  } bg-[#002359] hover:bg-[#0059EC]/50
                `}
                onClick={page.action}
              >
                <span
                  className={`flex flex-row ${
                    isSidebarOpen ? "gap-x-3" : ""
                  } items-center`}
                >
                  <span className="text-[#A6C8FF] text-xl">{page.icon}</span>
                  {isSidebarOpen && (
                    <span className="text-base font-semibold text-white">
                      {page.text}
                    </span>
                  )}
                </span>
              </Nav.Link>
            ))}
          </Nav>
        </Nav>
      ) : (
        <Nav
          id="navbar"
          className="flex flex-col gap-y-2 lg:gap-y-0 lg:flex-row justify-center lg:justify-between items-center py-0 z-0 bg-white"
        >
        </Nav>
      )}
    </>
  );
};

export default Header;
import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CameraRecorder, { CameraRecorderHandle } from "./CameraRecorder";
import CodingBox from "./CodingBox";
import Timer from "./Timer";
import Webcam from "react-webcam";
import axiosInterceptor from "../../utils/axiosInterceptor";


interface Question {
    id: string;
    title: string;
    question: string;
    solution: string;
    hint: string;
    answer: string;
    difficulty: number;
    type: "coding" | "other"
}


interface AssessmentState {
    questionMachine: any;
    answers: Record<string, string>;
    abilityVector: number[] | null;
}


interface TestCase {
    input: number[];
}


const Technical = () => {
    const userId = localStorage.getItem("user_id");
    const location = useLocation();
    const navigate = useNavigate();
    const cameraRecorderRef = useRef<CameraRecorderHandle>(null);
    const [showExitModal, setShowExitModal] = useState<boolean>(false);
    const [assessmentState, setAssessmentState] = useState<AssessmentState>({
        questionMachine: null,
        answers: {},
        abilityVector: null,
    });
    const [currentQuestion, setCurrentQuestion] = useState<Question | null>(null);
    const [totalQuestions] = useState<number>(5);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [userAnswer, setUserAnswer] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>("");
    const [assessmentStarted, setAssessmentStarted] = useState<boolean>(false);
    const [testCases, setTestCases] = useState<TestCase[]>([]);
    const [currentTestCase, setCurrentTestCase] = useState<number>(0);
    const [code, setCode] = useState<string>("");
    const [subject, setSubject] = useState<string>("Quant");
    const [startTime, setStartTime] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);

    useEffect(() => {
        const initializeAssessment = async () => {
            try {
                // Start assessment
                const subjectFromLocation = location.state?.subject || "Quant";
                setSubject(subjectFromLocation);
                await axiosInterceptor.post("/start_assessment/", {
                    user_id: userId,
                    subject: subjectFromLocation
                });
                setStartTime(Date.now());
                setAssessmentStarted(true);
                await fetchQuestion();
            } catch (err) {
                setError(err instanceof Error ? err.message : "Failed to initialize assessment");
            } finally {
                setLoading(false);
            }
        };


        initializeAssessment();
    }, []);


    const fetchQuestion = async () => {
        try {
            const response = await axiosInterceptor.post("/retrieve_question/", { user_id: userId });

            const questionKey = Object.keys(response.data.questions)[0];
            const backendQuestion = response.data.questions[questionKey];


            setCurrentQuestion({
                id: questionKey,
                title: backendQuestion.title,
                question: backendQuestion.question,
                solution: backendQuestion.solution,
                hint: backendQuestion.hint,
                answer: backendQuestion.answer,
                difficulty: backendQuestion.difficulty,
                type: subject === "leetcode" ? "coding" : "other",
            });
        } catch (err) {
            setError("Failed to load question");
        }
    };


    const exitPage = async () => {
        try {
            if (cameraRecorderRef.current) {
                await cameraRecorderRef.current.stopAndDownload();
            }
            await endAssessment();
            navigate("/test-center/home");
        } catch (err) {
            console.error("Exit error:", err);
            setError("Failed to exit properly");
        }
    };

    const handleExitClick = () => setShowExitModal(true);
    const handleCloseExitModal = () => setShowExitModal(false);
    const handleConfirmExit = async () => {
        handleCloseExitModal();
        await exitPage();
    };


    const handleAnswerSubmission = async () => {
        if (!currentQuestion) return;

        try {
            const answerToSubmit = currentQuestion.type === "coding" ? code : userAnswer;

            // Submit answer first
            await axiosInterceptor.post("/answer_question/", {
                user_id: userId,
                question_id: currentQuestion.id,
                answer: answerToSubmit
            });
            // Stop recording
            if (cameraRecorderRef.current) {
                await cameraRecorderRef.current.stopAndDownload();
            }
            // Handle question progression
            if (currentQuestionIndex < totalQuestions - 1) {
                await fetchQuestion();
                setCurrentQuestionIndex(prev => prev + 1);
                setCode("");
                setUserAnswer("");
            } else {
                await endAssessment();
                navigate("/test-center/home");
            }
        } catch (err) {
            setError("Failed to process answer");
            console.error("Submission error:", err);
        }
    };

    const endAssessment = async () => {
        const durationSeconds = Math.floor((Date.now() - startTime) / 1000);
        setDuration(durationSeconds);
        try {
            await axiosInterceptor.post("/end_assessment/", { user_id: userId, duration: durationSeconds });
        } catch (err) {
            console.error("Assessment end error:", err);
        }
    };


    const handleTimeUp = () => exitPage();


    const renderQuestion = () => {
        if (!currentQuestion) return null;


        if (currentQuestion.type === "coding") {
            return (
                <div className="space-y-4">
                    <p>{currentQuestion.question}</p>
                    {/* Example Cases
                   <div className="space-y-4">
                       {currentQuestion.example1 && (
                           <div>
                               <div className="mb-2">Example 1</div>
                               <div className="bg-gray-50 rounded-lg p-4 font-mono text-sm">
                                   {currentQuestion.example1}
                               </div>
                           </div>
                       )}
                       {currentQuestion.example2 && (
                           <div>
                               <div className="mb-2">Example 2</div>
                               <div className="bg-gray-50 rounded-lg p-4 font-mono text-sm">
                                   {currentQuestion.example2}
                               </div>
                           </div>
                       )}
                   </div> */}
                </div>
            );
        } else if (currentQuestion.type === "other") {
            return (
                <div className="space-y-4">
                    <p>{currentQuestion.question}</p>
                    {/* {currentQuestion.optionalImages?.map((image, index) => (
                       <div key={index} className="mt-4">
                           {image}
                       </div>
                   ))} */}
                </div>
            );
        }
    };


    return (
        <div className="min-h-screen bg-[#F9F9F9]">
            <div className="max-w-7xl mx-auto px-8 pt-8 pb-16">
                {/* Title Section with Integrated Progress */}
                <div className="mb-8">
                    <div className="flex items-center justify-between mb-4">
                        <div>
                            <h1 className="text-2xl font-semibold">Test Center</h1>
                            <p className="text-gray-600 text-sm">Practice for your interviews!</p>
                        </div>


                        <div
                            className="relative !rounded-full"
                            style={{ width: "150px", height: "150px" }}
                        >
                            <CameraRecorder
                                isTechnical={true}
                                rounded
                                moreClasses="!w-full !h-full"
                                ref={cameraRecorderRef}
                                ids={[currentQuestionIndex + 1]}
                                type="Technical"
                            />
                        </div>
                        <div>
                            <div className="flex gap-4">
                                <button
                                    onClick={handleAnswerSubmission}
                                    className="px-6 py-2 rounded-full bg-blue-600 text-white hover:bg-blue-700 transition-colors"
                                >
                                    {currentQuestionIndex === totalQuestions - 1
                                        ? "Submit Test"
                                        : "Next Question"}
                                </button>
                                <button
                                    onClick={handleExitClick}
                                    className="px-6 py-2 rounded-full border border-red-500 text-red-500 hover:bg-red-50 transition-colors"
                                >
                                    Exit Interview
                                </button>
                            </div>
                        </div>
                    </div>


                    <div className="flex items-center gap-3">
                        <div className="h-2 w-48 bg-gray-200 rounded-full overflow-hidden">
                            <div
                                className="h-full bg-green-500 transition-all duration-300"
                                style={{
                                    width: `${((currentQuestionIndex + 1) / totalQuestions) * 100}%`
                                }}
                            />
                        </div>
                        <span className="text-sm text-gray-600">
                            {currentQuestionIndex + 1}/{totalQuestions} Questions
                        </span>
                    </div>
                </div>


                {/* Technical Exam Section */}
                <div className="space-y-6">
                    <h2 className="text-xl font-semibold">Question {currentQuestionIndex + 1}</h2>


                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
                        {/* Question Content */}
                        <div>{renderQuestion()}</div>


                        {/* Answer Section */}
                        <div>
                            {currentQuestion?.type === "coding" ? (
                                <CodingBox
                                    setParentTestCases={setTestCases}
                                    parentCurrentTestCase={currentTestCase}
                                    setParentCurrentTestCase={setCurrentTestCase}
                                    code={code}
                                    onCodeChange={setCode}
                                />
                            ) : (
                                <div className="space-y-6">
                                    <textarea
                                        className="w-full h-24 rounded-lg border border-gray-200 p-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        placeholder="Type your answer here..."
                                        value={userAnswer}
                                        onChange={(e) => setUserAnswer(e.target.value)}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>


            {/* Exit Modal */}
            {showExitModal && (
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
                        <h3 className="text-xl font-medium mb-2">Are you sure?</h3>
                        <p className="text-gray-600 mb-6">
                            Exiting the test will end your interview and cause you to lose all your progress!
                        </p>
                        <div className="flex justify-end gap-4">
                            <button
                                onClick={handleCloseExitModal}
                                className="px-4 py-2 text-gray-600 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleConfirmExit}
                                className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors"
                            >
                                I'm sure
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};


export default Technical;

// import React, { useRef, useState, useEffect } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import CameraRecorder, { CameraRecorderHandle } from "./CameraRecorder";
// import CodingBox from "./CodingBox";
// import Timer from "./Timer";
// import Webcam from "react-webcam";

// interface QuestionPart {
//     label: string;
//     text: string;
//     inputType: "text" | "multipleChoice";
//     options?: string[];
//     answer: string;
// }

// interface Question {
//     type: "other" | "coding";
//     question: string;
//     optionalImages?: JSX.Element[];
//     parts?: QuestionPart[];
//     example1?: string;
//     example2?: string;
// }

// interface TestCase {
//     input: number[];
// }

// interface InterviewData {
//     Questions: Question[];
//     Leetcodes: number[];
// }

// const Technical = () => {
//     const location = useLocation();
//     const navigate = useNavigate();
//     const cameraRecorderRef = useRef<CameraRecorderHandle>(null);
//     const [showExitModal, setShowExitModal] = useState<boolean>(false);

//     const dummyQuestions: Question[] = [
//         {
//             type: "other",
//             question:
//                 "We will examine your knowledge of data structures and algorithms. Please answer the following questions:",
//             optionalImages: [
//                 <img
//                     src="https://placehold.co/600x400"
//                     alt="placeholder"
//                     className="w-full"
//                     key="placeholder"
//                 />,
//             ],
//             parts: [
//                 {
//                     label: "a",
//                     text: "Define a stack and its main operations:",
//                     inputType: "text",
//                     answer: "",
//                 },
//                 {
//                     label: "b",
//                     text: "Which of the following is NOT a characteristic of a queue?",
//                     inputType: "multipleChoice",
//                     options: [
//                         "First-In-First-Out (FIFO)",
//                         "Last-In-First-Out (LIFO)",
//                         "Enqueue operation",
//                         "Dequeue operation",
//                     ],
//                     answer: "",
//                 },
//                 {
//                     label: "c",
//                     text: "Which data structure would be most suitable for implementing an 'undo' functionality?",
//                     inputType: "multipleChoice",
//                     options: ["Array", "Queue", "Stack", "Linked List"],
//                     answer: "",
//                 },
//                 {
//                     label: "d",
//                     text: "Provide an example use case for a queue:",
//                     inputType: "text",
//                     answer: "",
//                 },
//             ],
//         },
//         {
//             type: "coding",
//             question: "Write a function to reverse a string in place.",
//             example1: "Input: 'hello'\nOutput: 'olleh'",
//             example2: "Input: 'OpenAI'\nOutput: 'IAnepO'",
//         },
//         {
//             type: "coding",
//             question:
//                 "Implement a function to find the maximum subarray sum in an array of integers.",
//             example1:
//                 "Input: [-2, 1, -3, 4, -1, 2, 1, -5, 4]\nOutput: 6 (subarray [4, -1, 2, 1])",
//             example2:
//                 "Input: [1, 2, 3, -2, 5]\nOutput: 9 (subarray [1, 2, 3, -2, 5])",
//         },
//     ];

//     const dummyTestCases: TestCase[] = [
//         { input: [1, 2, 3] },
//         { input: [4, 5, 6] },
//     ];

//     const [questions, setQuestions] = useState<Question[]>(dummyQuestions);
//     const [leetcodes, setLeetcodes] = useState<number[]>([0, 1, 1]);
//     const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
//     const [testCases, setTestCases] = useState<TestCase[]>(dummyTestCases);
//     const [currentTestCase, setCurrentTestCase] = useState<number>(0);
//     const [answers, setAnswers] = useState<Record<number, Record<string, string>>>({});

//     useEffect(() => {
//         const interviewData = location.state as InterviewData | null;
//         if (interviewData) {
//             setQuestions(interviewData.Questions);
//             setLeetcodes(interviewData.Leetcodes);
//         }
//     }, [location.state]);

//     const exitPage = () => {
//         if (cameraRecorderRef.current) {
//             cameraRecorderRef.current.stopAndDownload();
//         } else {
//             console.log("cameraRecorderRef is null");
//         }
//         setTimeout(() => navigate("/test-center/home"), 1000);
//     };

//     const handleExitClick = () => setShowExitModal(true);
//     const handleCloseExitModal = () => setShowExitModal(false);
//     const handleConfirmExit = () => {
//         handleCloseExitModal();
//         exitPage();
//     };

//     const handleNextQuestion = () => {
//         if (currentQuestionIndex === questions.length - 1) {
//             // If it's the last question, submit the test
//             setTimeout(() => {
//                 setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
//             }, 1000);
//             setTimeout(exitPage, 2000);
//         } else {
//             if (cameraRecorderRef.current) {
//                 // Stop and download the recording
//                 cameraRecorderRef.current.stopAndDownload();
//             } else {
//                 console.log("cameraRecorderRef is null");
//             }
//             setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
//         }
//     };

//     const handleTimeUp = () => exitPage();

//     const handleAnswerChange = (
//         questionIndex: number,
//         partLabel: string,
//         value: string
//     ) => {
//         setAnswers((prevAnswers) => ({
//             ...prevAnswers,
//             [questionIndex]: {
//                 ...prevAnswers[questionIndex],
//                 [partLabel]: value,
//             },
//         }));
//     };

//     const renderQuestion = () => {
//         const currentQuestion = questions[currentQuestionIndex];
//         if (!currentQuestion) return null;

//         if (currentQuestion.type === "coding") {
//             return (
//                 <div className="space-y-4">
//                     <p>{currentQuestion.question}</p>

//                     {/* Example Cases */}
//                     <div className="space-y-4">
//                         {currentQuestion.example1 && (
//                             <div>
//                                 <div className="mb-2">Example 1</div>
//                                 <div className="bg-gray-50 rounded-lg p-4 font-mono text-sm">
//                                     {currentQuestion.example1}
//                                 </div>
//                             </div>
//                         )}
//                         {currentQuestion.example2 && (
//                             <div>
//                                 <div className="mb-2">Example 2</div>
//                                 <div className="bg-gray-50 rounded-lg p-4 font-mono text-sm">
//                                     {currentQuestion.example2}
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             );
//         } else if (currentQuestion.type === "other") {
//             return (
//                 <div className="space-y-4">
//                     <p>{currentQuestion.question}</p>
//                     {currentQuestion.optionalImages?.map((image, index) => (
//                         <div key={index} className="mt-4">
//                             {image}
//                         </div>
//                     ))}
//                 </div>
//             );
//         }
//     };

//     return (
//         <div className="min-h-screen bg-white">
//             <div className="max-w-7xl mx-auto px-8 pt-8 pb-16">
//                 {/* Title Section with Integrated Progress */}
//                 <div className="mb-8">
//                     <div className="flex items-center justify-between mb-4">
//                         <div>
//                             <h1 className="text-2xl font-semibold">Test Center</h1>
//                             <p className="text-gray-600 text-sm">Practice for your interviews!</p>
//                         </div>

//                         <div
//                             className="relative !rounded-full"
//                             style={{ width: "150px", height: "150px" }}
//                         >
//                             <CameraRecorder
//                                 isTechnical={true}
//                                 rounded
//                                 moreClasses="!w-full !h-full"
//                                 ref={cameraRecorderRef}
//                                 ids={Array.from(
//                                     { length: questions.length },
//                                     (_, index) => index + 1
//                                 )}
//                                 type="Technical"
//                             />
//                         </div>
//                         <div>
//                             <div className="flex gap-4">
//                                 <button
//                                     onClick={handleNextQuestion}
//                                     className="px-6 py-2 rounded-full bg-blue-600 text-white hover:bg-blue-700 transition-colors"
//                                 >
//                                     {currentQuestionIndex === questions.length - 1
//                                         ? "Submit Test"
//                                         : "Next Question"}
//                                 </button>
//                                 <button
//                                     onClick={handleExitClick}
//                                     className="px-6 py-2 rounded-full border border-red-500 text-red-500 hover:bg-red-50 transition-colors"
//                                 >
//                                     Exit Interview
//                                 </button>
//                             </div>
//                         </div>
//                     </div>

//                     <div className="flex items-center gap-3">
//                         <div className="h-2 w-48 bg-gray-200 rounded-full overflow-hidden">
//                             <div
//                                 className="h-full bg-green-500 transition-all duration-300"
//                                 style={{
//                                     width: `${((currentQuestionIndex + 1) / questions.length) * 100}%`
//                                 }}
//                             />
//                         </div>
//                         <span className="text-sm text-gray-600">
//                             {currentQuestionIndex + 1}/{questions.length} Questions
//                         </span>
//                     </div>
//                 </div>

//                 {/* Technical Exam Section */}
//                 <div className="space-y-6">
//                     <h2 className="text-xl font-semibold">Question {currentQuestionIndex + 1}</h2>

//                     <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
//                         {/* Question Content */}
//                         <div>{renderQuestion()}</div>

//                         {/* Answer Section */}
//                         <div>
//                             {questions[currentQuestionIndex]?.type === "coding" ? (
//                                 <CodingBox
//                                     setParentTestCases={setTestCases}
//                                     parentCurrentTestCase={currentTestCase}
//                                     setParentCurrentTestCase={setCurrentTestCase}
//                                 />
//                             ) : (
//                                 <div className="space-y-6">
//                                     {questions[currentQuestionIndex]?.parts?.map((part, index) => (
//                                         <div key={index} className="space-y-2">
//                                             <label className="block text-sm">
//                                                 {part.label}. {part.text}
//                                             </label>
//                                             {part.inputType === "text" ? (
//                                                 <textarea
//                                                     className="w-full h-24 rounded-lg border border-gray-200 p-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
//                                                     placeholder="Type your answer here..."
//                                                     value={answers[currentQuestionIndex]?.[part.label] || ""}
//                                                     onChange={(e) =>
//                                                         handleAnswerChange(
//                                                             currentQuestionIndex,
//                                                             part.label,
//                                                             e.target.value
//                                                         )
//                                                     }
//                                                 />
//                                             ) : (
//                                                 <div className="space-y-2">
//                                                     {part.options?.map((option, optionIndex) => (
//                                                         <button
//                                                             key={optionIndex}
//                                                             className={`w-full text-left p-3 rounded-lg border transition-colors ${answers[currentQuestionIndex]?.[part.label] === option
//                                                                 ? "bg-blue-50 border-blue-500"
//                                                                 : "bg-white border-gray-200 hover:bg-gray-50"
//                                                                 }`}
//                                                             onClick={() =>
//                                                                 handleAnswerChange(
//                                                                     currentQuestionIndex,
//                                                                     part.label,
//                                                                     option
//                                                                 )
//                                                             }
//                                                         >
//                                                             {option}
//                                                         </button>
//                                                     ))}
//                                                 </div>
//                                             )}
//                                         </div>
//                                     ))}
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             {/* Exit Modal */}
//             {showExitModal && (
//                 <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
//                     <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
//                         <h3 className="text-xl font-medium mb-2">Are you sure?</h3>
//                         <p className="text-gray-600 mb-6">
//                             Exiting the test will end your interview and cause you to lose all your progress!
//                         </p>
//                         <div className="flex justify-end gap-4">
//                             <button
//                                 onClick={handleCloseExitModal}
//                                 className="px-4 py-2 text-gray-600 bg-gray-100 rounded-md hover:bg-gray-200 transition-colors"
//                             >
//                                 Cancel
//                             </button>
//                             <button
//                                 onClick={handleConfirmExit}
//                                 className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors"
//                             >
//                                 I'm sure
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default Technical;
import React, { useEffect, useState, useRef } from 'react';
import RecruitmentBackground from '../../assets/img/landing/process-background.png';
import InfiniteMovingCards from './InfiniteMovingCards';

interface TestimonialItem {
    quote: string;
    name: string;
    title: string;
    imageSrc?: string;
}

const TestimonialsSection: React.FC = () => {
    const testimonials: TestimonialItem[] = [
        {
            quote: "The Profile feature really gets to know me. It understands my background, personality, and job preferences to give me personalized recommendations.",
            name: "Emily Johnson",
            title: "Software Developer @ Meta",
        },
        {
            quote: "The Test Center is a game-changer. It let me prove my skills in STEM, design, and finance at my own pace, helping me shine in the areas I’m strongest in.",
            name: "Michael Smith",
            title: "Data Analyst @ Amazon",
        },
        {
            quote: "The Courses sync perfectly with the Test Center, showing me clear steps to improve my skills and progress toward my goals. It’s incredibly motivating!",
            name: "Sophia Lee",
            title: "Product Designer @ Airbnb",
        },
        {
            quote: "The Jobs page makes job hunting so easy! It’s like a dating app but for careers. Sorting through jobs and matching with recruiters has never been this fun.",
            name: "Liam Brown",
            title: "Quantitative Trader @ Two Sigma",
        },
        {
            quote: "Messages is such a friendly feature! It helped me turn matches into real referrals and interviews with recruiters and managers, all while keeping communication stress-free.",
            name: "Olivia Davis",
            title: "Machine Learning Engineer @ OpenAI",
        },
    ];

    return (
        <div className="relative w-full h-screen max-h-[600px]">
            {/* Background Image */}
            <div 
                className="absolute inset-0 bg-cover bg-center"
                style={{
                    backgroundImage: `url(${RecruitmentBackground})`
                }}
            />
            
            {/* Content */}
            <div className="relative h-full flex flex-col justify-center items-center">
                <div className="w-full max-w-7xl mx-auto px-4">
                    <h2 className="text-4xl font-bold text-center mb-12 text-white">
                        Here's what our candidates say
                    </h2>
                    <InfiniteMovingCards
                        items={testimonials}
                        direction="right"
                        speed="normal"
                        pauseOnHover={true}
                        className=""
                    />
                </div>
            </div>
        </div>
    );
};

export default TestimonialsSection;